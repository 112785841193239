import { ButtonProps, cn } from '@nextui-org/react';
import Image_01 from 'assets/img/1.png';
import Image_02 from 'assets/img/2.png';
import Image_03 from 'assets/img/3.png';
import { useViewportHeight } from 'hooks/use-viewport-height';
import { Section } from 'layout/index';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Link } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';
import { URL } from 'utils/constants';

interface BackButtonProps extends ButtonProps {
  customPrevUrl: string;
}

const BackButton = ({ customPrevUrl, className, ...rest }: BackButtonProps) => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();

  const onGoBack = useCallback(() => {
    return customPrevUrl ? navigate(customPrevUrl) : navigate(-1);
  }, [customPrevUrl]);

  if (isMobile) {
    return (
      <Link color='secondary' onClick={onGoBack}>
        <div className='flex items-center gap-[5px] font-semibold'>
          <IoIosArrowBack size='25' className='rtl:rotate-180' />
          <p>{t('back')}</p>
        </div>
      </Link>
    );
  }

  return (
    <Button
      onClick={onGoBack}
      color='default'
      variant='flat'
      aria-label='go-back'
      isIconOnly
      className={cn('', className)}
      size='lg'
      {...rest}>
      <HiOutlineArrowLeft size='24' className='rtl:rotate-180' />
    </Button>
  );
};

interface AuthPageLayoutProps {
  sections?: ReactNode;
  showMobileHeader?: boolean;
  customPrevUrl?: string;
}

export const AuthPageLayout = ({ sections, showMobileHeader, customPrevUrl }: AuthPageLayoutProps) => {
  const { pathname } = useLocation();
  const { isTablet, isMobile } = useBreakpoint();
  const viewportHeight = useViewportHeight();

  const bgImage = () => {
    if (pathname.includes(URL.REGISTER)) return Image_02;
    if (pathname.includes(URL.VERIFY_CODE)) return Image_03;
    if (pathname.includes(URL.REQUEST_RECEIVED)) return Image_03;
    return Image_01;
  };

  if (isMobile) {
    return (
      <main>
        <section style={{ minHeight: viewportHeight }}>
          {!!showMobileHeader && (
            <div className='border-b-1 px-sm pt-md pb-sm'>
              <BackButton customPrevUrl={customPrevUrl ?? ''} />
            </div>
          )}

          <div className='my-md mx-sm'>
            <Section sections={sections} name='content' />
          </div>
        </section>
      </main>
    );
  }

  if (isTablet) {
    return (
      <main>
        <section style={{ minHeight: viewportHeight }}>
          <div className='flex h-screen'>
            <div
              className='flex justify-center items-center flex-1 bg-cover bg-center bg-no-repeat py-xl'
              style={{ backgroundImage: 'url(' + bgImage() + ')' }}>
              <div className='bg-white p-lg rounded-md w-[500px] relative'>
                <BackButton customPrevUrl={customPrevUrl ?? ''} className='absolute bg-white top-[-180px] start-0' />

                <Section sections={sections} name='content' />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

  return (
    <main>
      <section style={{ minHeight: viewportHeight }}>
        <div className='flex h-screen'>
          <div className='flex justify-center items-center w-[35%] p-2xl'>
            <BackButton customPrevUrl={customPrevUrl ?? ''} className='absolute bg-transparent top-2xl start-xl' />

            <Section sections={sections} name='content' />
          </div>

          <div
            className='flex justify-center items-center w-[65%] bg-cover bg-center bg-no-repeat'
            style={{ backgroundImage: 'url(' + bgImage() + ')' }}
          />
        </div>
      </section>
    </main>
  );
};
