import { Checkbox, CheckboxGroup, UseCheckboxGroupReturn, VStack } from '@elkaso-app/web-design';
import { useDisableRegionApi } from 'apis/settings/use-disable-region-api';
import { useTranslation } from 'react-i18next';

function getDifference(arr1: any[], arr2: any[]) {
  return arr1.filter((item) => !arr2.includes(item));
}

interface RegionsListProps {
  regions: { id: string; name: string }[];
  regionsDataIds: string[];
  getCheckboxProps: UseCheckboxGroupReturn['getCheckboxProps'];
  value: string[];
}

export const RegionsList = ({ regions, regionsDataIds, value, getCheckboxProps }: RegionsListProps) => {
  const { t } = useTranslation();
  const { mutate: disableRegion } = useDisableRegionApi();

  // Disable region incase unchecked
  const onChange = (value: string[]) => {
    const result = getDifference(regionsDataIds, value);
    result.map((i) => disableRegion({ regionId: i }));
  };

  return (
    <div className='p-md min-w-[175px] border-1'>
      <div className='flex flex-col align-start gap-1 mb-md'>
        <h2 className='text-secondary-500 text-md font-bold'>{t('regions')}</h2>
        <p className='text-sm text-default-500'>{t('please-select-regions')}</p>
      </div>

      {!regions?.length && <p>{t('no-regions-found')}</p>}

      {!!regions?.length && (
        <CheckboxGroup colorScheme='blue' value={value} onChange={onChange}>
          <VStack spacing='sm' align='start'>
            {regions?.map((item) => (
              <Checkbox key={item?.id} value={item?.id} {...getCheckboxProps({ value: item?.id?.toString() })}>
                {item?.name}
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      )}
    </div>
  );
};
