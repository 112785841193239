import http, { createAuthorizationHeader } from 'apis/config/http-service';
import _toString from 'lodash/toString';
import { useEffect, useState } from 'react';
import { LocalStorageKeys } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';
import { useAuth } from './use-auth';
import { usePageParams } from './use-page-params';

interface UseIsGuestReturn {
  isLoading: boolean;
}

/**
 * [1] If there's no logged in user:-
 *     1. Save guest access token to local storage.
 *     2. Redirect guest to the order details page by order ID.
 *     3. Add is guest flag in local storage.
 *
 * [2] If there's logged in user:-
 *     [a]   1. Check if there's logged in user.
 *           2. Compare between both logged in & guest users IDs by fetching "/me" API.
 *           3. If both users are the same remove the guest token and keep the logged in user.
 *           4. Redirect him to the order details page by redirected order ID.
 *
 *     [b]   1. If users are not the same replace the current access token with guest token.
 *           2. Redirect him to the order details page by order ID.
 *           3. Add is guest flag in local storage.
 */

export const useGuestCheck = (): UseIsGuestReturn => {
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn } = useAuth();
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();

  useEffect(() => {
    // [1] Check if there's access token in query param
    const hasToken = new Promise<string>((resolve, reject) => {
      const accessToken = params?.['external_access_token'];

      if (accessToken) resolve(_toString(accessToken));

      if (!accessToken) {
        reject({ type: 'info', message: 'Guest: No guest token have been found.' });
      }
    });

    hasToken
      .then(async (accessToken) => {
        // [2] Check if there's no logged in user
        if (!isLoggedIn) {
          // [a] Store guest access token in localStorage
          localStorage.setItem(LocalStorageKeys.access_token, accessToken);

          // [b] Set is guest token flag to true
          localStorage.setItem(LocalStorageKeys.is_guest_token, 'true');

          // [c] Remove any stored refresh token
          localStorage.removeItem(LocalStorageKeys.refresh_token);
        }

        // [3] Check if there's logged in user
        if (isLoggedIn) {
          // [a] Compare between both logged in & guest users IDs by fetching "/me" API
          const { data: loggedInUserData } = await http.get('ums/v1/users/myself');

          const { data: guestUserData } = await http.get('ums/v1/users/myself', {
            headers: {
              ['Authorization']: createAuthorizationHeader(accessToken || ''),
            },
          });

          const isSameUser = loggedInUserData?.data?.id === guestUserData?.data?.id;

          // [b] If users are not the same replace the current access token with guest token and set is guest token flag to true
          if (!isSameUser) {
            localStorage.removeItem(LocalStorageKeys.refresh_token);
            localStorage.removeItem(LocalStorageKeys.is_support_login);

            localStorage.setItem(LocalStorageKeys.access_token, accessToken);
            localStorage.setItem(LocalStorageKeys.is_guest_token, 'true');
          }
        }

        // [4] Remove guest access token from query param
        delete params?.['external_access_token'];
        setPageParams(params);

        // [5] Store order URL in localStorage
        localStorage.setItem(LocalStorageKeys.guest_order_url, location.pathname);
      })
      .catch((reason) => {
        if (reason?.type === 'info' && process.env.NODE_ENV === 'development') {
          console.log(reason?.message);
        }
      })
      .finally(async () => {
        setIsLoading(false);
        gaLogEvent('screen_guest_view');
      });
  }, []);

  return { isLoading };
};
