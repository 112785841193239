import { formatPrice } from '@elkaso-app/web-design';
import { CheckboxProps, cn } from '@nextui-org/react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useLongPress } from 'hooks/use-on-long-press';
import { orderStatusChipColor, orderStatusMap } from 'pages/orders/utils/order-status';
import { MouseEvent, TouchEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Chip } from 'theme/components';
import { URL } from 'utils/constants';

interface OrdersListItemProps extends CheckboxProps {
  id: string;
  code: string;
  total: number;
  currencyCode: string;
  brandName: string;
  status: string;
  createdAt: string;
  enableSelection: boolean;
  selectedOrders?: string[];
  setSelectedOrders?: (ids: string[]) => void;
}

export const OrdersListItem = ({
  id,
  code,
  total,
  currencyCode,
  brandName,
  createdAt,
  status,
  enableSelection,
  selectedOrders,
  setSelectedOrders,
  ...rest
}: OrdersListItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLongPress = () => {
    if (enableSelection && !selectedOrders?.length) {
      setSelectedOrders?.([id]);
    }
  };

  const handleShortPress = (e: MouseEvent | TouchEvent) => {
    if (!selectedOrders?.length) {
      e.preventDefault();
      navigate(`${URL.ORDERS_DETAILS}/${id}`);
    }
  };

  const longPressEvents = useLongPress(handleShortPress, handleLongPress);

  return (
    <Checkbox
      aria-label={code}
      color='secondary'
      classNames={{
        base: cn('inline-flex max-w-full w-full bg-content1 m-0 gap-xs p-sm', 'items-center justify-start'),
        label: 'w-full',
        wrapper: cn(!selectedOrders?.length ? 'hidden' : 'flex'),
      }}
      value={id}
      {...rest}
      {...longPressEvents}>
      <motion.div layout className='flex flex-col gap-1' transition={{ duration: 0.2 }}>
        <div className='flex items-center justify-between'>
          <p className='font-bold text-secondary-500'>{code}</p>
          <div>{formatPrice(total, currencyCode)}</div>
        </div>
        <div className='flex items-center gap-xs text-sm text-default-500'>
          <div className='max-w-[180px] truncate'>{brandName}</div>
          <div className='w-[6px] h-[6px] bg-default-300 rounded-full' />
          <div>{dayjs(createdAt).format('HH:mm A')}</div>
        </div>

        {/*Show status badge only for order history tab*/}
        {status !== 'approved' && status !== 'restaurant_placed' && (
          <div>
            <Chip color={orderStatusChipColor[status].color} variant='dot'>
              {t(orderStatusMap[status].name)}
            </Chip>
          </div>
        )}
      </motion.div>
    </Checkbox>
  );
};
