import { NextUIProvider } from '@nextui-org/react';
import { ConfigProvider, ThemeConfig } from 'antd';
import { FeatureFlagsProvider } from 'feature-flags';
import { useNavigate } from 'react-router-dom';

// React query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Redux
import { Provider } from 'react-redux';
import store from 'redux/store';

// CASL
import { ability } from 'configs/ability';
import { AbilityContext } from 'configs/ability/can';

// Kaso Theme
import { KasoThemeProvider } from '@elkaso-app/web-design';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Antd Theme
const config: ThemeConfig = {
  token: {
    colorPrimary: '#160C45',
    fontFamily: 'Poppins',
    motionDurationFast: '0.1s',
    motionDurationMid: '0.1s',
    motionDurationSlow: '0.1s',
  },
  components: {
    Spin: {
      colorBgMask: 'white',
    },
    Button: {
      primaryShadow: 'none',
    },
    Pagination: {
      itemActiveBg: '#160C45',
      colorPrimary: 'white',
      colorPrimaryHover: 'white',
    },
    Menu: {
      iconSize: 24,
      fontSize: 16,
      itemActiveBg: '#edf2f7',
      itemColor: '#52525B',
      itemHoverBg: '#edf2f7',
      itemHoverColor: '#52525B',
      itemHeight: 48,
      itemSelectedBg: '#edf2f7',
      itemSelectedColor: '#160C45',
      itemMarginBlock: 8,
      activeBarBorderWidth: 0,
      itemMarginInline: 0,
    },
    Alert: {
      withDescriptionPadding: '14px 16px',
      withDescriptionIconSize: 19.2,
      lineHeight: 1.2,
    },
    Collapse: {
      headerPadding: '10px 0px',
      contentPadding: 0,
    },
    Form: {
      itemMarginBottom: 10,
    },
    Table: {
      rowSelectedBg: '#fff',
      rowSelectedHoverBg: '#fff',
      rowHoverBg: '#fff',
    },
  },
};

export function Providers({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureFlagsProvider>
        <Provider store={store}>
          <AbilityContext.Provider value={ability}>
            <KasoThemeProvider>
              <ConfigProvider theme={config}>
                <NextUIProvider navigate={navigate}>{children}</NextUIProvider>
              </ConfigProvider>
            </KasoThemeProvider>
          </AbilityContext.Provider>
        </Provider>
      </FeatureFlagsProvider>

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
