import { NavBar } from 'antd-mobile';
import { useGetRecommendedProductsApi } from 'apis/marketing/use-get-recommended-products-api';
import { FullPageSpinner } from 'components/full-page-spinner';
import { Layout, Section } from 'layout';
import { RecommendationsPreview } from 'pages/marketing/components/recommendations-preview';
import { RecommendedList } from 'pages/marketing/components/recommended-list';
import { useTranslation } from 'react-i18next';

export const MobileRecommendationsScreen = () => {
  const { t } = useTranslation();

  const { data: recommendedProductsData, isLoading: isLoadingRecommendedProducts } = useGetRecommendedProductsApi();

  const navBarStyle = {
    '--height': '60px',
    '--border-bottom': '1px #cbd5e0 solid',
  };

  if (isLoadingRecommendedProducts) {
    return <FullPageSpinner />;
  }

  return (
    <Layout type='mobile_page_layout'>
      <Section name='header'>
        <NavBar
          back={null}
          left={<p className='text-secondary-500 font-semibold'>{t('marketing')}</p>}
          style={navBarStyle}
        />
      </Section>

      <Section name='content'>
        <div className='p-md flex flex-col items-center gap-md'>
          <p className='text-secondary-500 font-semibold text-lg'>{t('how_they_will_show')}</p>
          <RecommendationsPreview items={recommendedProductsData ?? []} />
          <RecommendedList items={recommendedProductsData ?? []} />
        </div>
      </Section>
    </Layout>
  );
};
