import { useCustomToast } from '@elkaso-app/web-design';
import { useSignInCheckApi } from 'apis/auth/use-sign-in-check-api';
import { useNavigate } from 'react-router-dom';
import { URL } from 'utils/constants';
import { LoginType } from '../index';
import { useSendOtp } from './use-send-otp';

export const useSignInCheck = () => {
  const navigate = useNavigate();
  const toast = useCustomToast();

  const { isLoading: isLoadingSignInCheck, mutateAsync: signInCheckApi } = useSignInCheckApi();
  const { isLoading: isLoadingSendOtp, onSendOtp } = useSendOtp();

  const onSignInCheck = async (loginType: LoginType, loginValue: string) => {
    const variables = {
      body: {
        [loginType]: loginValue,
      },
    };

    const { entityType, next } = await signInCheckApi(variables);

    if (entityType === 'restaurant') {
      return toast({
        description: 'This user is not a supplier user.',
        status: 'error',
      });
    }

    if (next === 'login') {
      return onSendOtp(loginType, loginValue);
    }

    if (next === 'register') {
      return window.open('https://www.kaso.ai/#email-form', '_blank', 'noreferrer');
    }

    if (next === 'pending') {
      return navigate(URL.REQUEST_RECEIVED);
    }

    if (next === 'unverified') {
      return toast({
        description: 'Unverified user! Please contact customer support.',
        status: 'error',
      });
    }
  };

  return {
    onSignInCheck,
    isLoading: isLoadingSignInCheck || isLoadingSendOtp,
  };
};
