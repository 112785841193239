import { Box, BrandLogo, Container, Flex, HStack, Link } from '@elkaso-app/web-design';
import { FacebookIcon } from 'icons/facebook-icon';
import { InstagramIcon } from 'icons/instagram-icon';
import { LinkedinIcon } from 'icons/linkedin-icon';
import { WebIcon } from 'icons/web-icon';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';

export const OrderPageLayout = ({ sections }: LayoutProps) => {
  const social = {
    facebook: process.env.REACT_APP_FACEBOOK_URL,
    linkedIn: process.env.REACT_APP_LINKEDIN_URL,
    instagram: process.env.REACT_APP_INSTAGRAM_URL,
    website: process.env.REACT_APP_WEBSITE_URL,
  };

  return (
    <Box as='main'>
      <Box
        as='section'
        minH={'100vh'}
        pb='2xl'
        bg='linear-gradient(to bottom, #EB4055 0%, #EB4055 210px, white 210px, white 100%)'>
        <HStack spacing='sm' px={['sm', 'lg']} py={['xs', 'md']} justify='flex-end'>
          <Link href={social.facebook} isExternal>
            <FacebookIcon boxSize={['15px', '20px']} />
          </Link>
          <Link href={social.linkedIn} isExternal>
            <LinkedinIcon boxSize={['15px', '20px']} />
          </Link>
          <Link href={social.instagram} isExternal>
            <InstagramIcon boxSize={['15px', '20px']} />
          </Link>
          <Link href={social.website} isExternal>
            <WebIcon boxSize={['15px', '20px']} />
          </Link>
        </HStack>

        <Flex pt={['xs', 'md']} pb={['md', 'xl']} justify='center'>
          <BrandLogo fill='white' width={['95px', '200px']} height={['23px', '39px']} />
        </Flex>

        <Container
          maxW={['full', 'container.lg']}
          bgColor='white'
          borderRadius={['xl']}
          shadow='2xl'
          minH='300px'
          p='0px'
          mx='auto'>
          {/* Start Content */}
          <Section sections={sections} name='content' />
          {/* End Content */}
        </Container>
      </Box>
    </Box>
  );
};
