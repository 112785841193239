import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentUser } from 'hooks/use-current-user';
import _toString from 'lodash/toString';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ClonedOrderDetailsItem,
  setOnItemPriceChange,
  setOnItemQtyChange,
} from 'redux/orders/order-details-page-reducer';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from 'theme/components';
import * as yup from 'yup';
import { useAppDispatch } from '../../../../redux/hooks';
import { InfoIcon } from '../../icons/info-icon-v2';

type FormValues = {
  price: number;
  quantity: number;
};

const defaultValues: FormValues = {
  price: 0,
  quantity: 0,
};

interface ModifyItemModalProps {
  item: ClonedOrderDetailsItem;
  isOpen: boolean;
  onClose: () => void;
}

export const ModifyItemModal = ({ item, isOpen, onClose }: ModifyItemModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useCurrentUser();

  const isUAEUser = useMemo(() => {
    return user?.country?.code === 'AE';
  }, [user]);

  const schema = yup.object().shape({
    price: yup
      .number()
      .nullable(true)
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
      .min(0, t('price_non_negative') as string)
      .required(t('price_is_required') as string),
    quantity: yup
      .number()
      .nullable(true)
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
      .min(0, t('qty_non_negative') as string)
      .required(t('qty_is_required') as string),
  });

  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    reset({ price: item?.price, quantity: item?.quantity });
  }, [item, isOpen]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    dispatch(setOnItemQtyChange({ itemId: item?.id, newQty: data?.quantity }));
    dispatch(setOnItemPriceChange({ itemId: item?.id, newPrice: data?.price }));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className='flex flex-col gap-1'>
          <div>
            <h2 className='text-secondary-500 text-lg'>{t('modify_item')}</h2>
            <p className='text-default-500 text-sm font-normal'>{t('modify_item_sub_text')}</p>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className='flex flex-col gap-md'>
            <div>
              <p className='font-semibold'>{item?.name}</p>
              <p className='text-sm mt-1'>{item?.code}</p>
            </div>

            <Divider />

            <form
              id='modifyOrderDetailsItemForm'
              className='flex flex-col gap-sm'
              onSubmit={handleSubmit(onSubmit)}
              noValidate>
              <div className='flex gap-xs items-start justify-stretch'>
                <Input
                  label={t('requested_quantity')}
                  isReadOnly
                  labelPlacement='outside'
                  value={_toString(item?.original?.quantity)}
                  variant='faded'
                />

                <Controller
                  control={control}
                  name='quantity'
                  render={({ field: { name, value, onChange, onBlur }, fieldState: { invalid, error } }) => (
                    <Input
                      type='number'
                      variant='bordered'
                      labelPlacement='outside'
                      label={t('available_quantity')}
                      name={name}
                      value={_toString(value)}
                      onChange={onChange}
                      onBlur={onBlur}
                      isInvalid={invalid}
                      errorMessage={error?.message}
                      placeholder={t('enter_new_quantity') as string}
                    />
                  )}
                />
              </div>

              {isUAEUser && (
                <div className='flex gap-xs items-start justify-stretch'>
                  <Input
                    label={t('price')}
                    isReadOnly
                    labelPlacement='outside'
                    value={_toString(item?.original?.price)}
                    variant='faded'
                  />

                  <Controller
                    control={control}
                    name='price'
                    render={({ field: { name, value, onChange, onBlur }, fieldState: { invalid, error } }) => (
                      <Input
                        type='number'
                        variant='bordered'
                        label={t('new_price')}
                        labelPlacement='outside'
                        name={name}
                        value={_toString(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        isInvalid={invalid}
                        errorMessage={error?.message}
                        placeholder={t('enter_new_price') as string}
                      />
                    )}
                  />
                </div>
              )}
            </form>

            <div className='flex gap-md items-center'>
              <InfoIcon />
              <p className='flex-1 text-sm'>{t('modify_item_info_text')}</p>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} size='lg' variant='light' color='secondary' fullWidth>
            {t('cancel')}
          </Button>

          <Button type='submit' form='modifyOrderDetailsItemForm' size='lg' variant='solid' color='success' fullWidth>
            {t('save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
