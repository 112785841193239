import { Spin } from 'antd';
import { Logo } from './icons/logo';
import { SpinnerIcon } from './icons/spinner-icon';

const Indicator = () => {
  return (
    <div className='flex flex-col items-center gap-lg'>
      <Logo />
      <SpinnerIcon />
    </div>
  );
};

export const FullPageSpinner = () => {
  return <Spin indicator={<Indicator />} fullscreen />;
};
