import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isAllObjectsAreTheSame } from 'utils/is-all-objects-are-same';
import { RegionData } from '.';
import { RegionSettingsBox } from './region-settings-box';

const initialValues: Omit<RegionData, 'id' | 'name'> = {
  minimumOrderValue: 0,
  cutOffTime: '',
  deliverySla: 1,
  weekdays: [1, 2, 3, 4, 5, 6],
};

interface RegionSettingsApplyAllProps {
  selectedRegions: RegionData[];
}

export const RegionSettingsApplyAll = ({ selectedRegions }: RegionSettingsApplyAllProps) => {
  const { t } = useTranslation();

  const isApplyToAll = useMemo(() => {
    const _selectedRegions = selectedRegions?.map((i) => {
      return {
        minimumOrderValue: i.minimumOrderValue,
        cutOffTime: i.cutOffTime,
        deliverySla: i.deliverySla,
        weekdays: i.weekdays,
      };
    });

    return isAllObjectsAreTheSame(_selectedRegions);
  }, [selectedRegions]);

  const applyToAllData = useMemo(() => {
    if (!isApplyToAll || !selectedRegions?.length) return initialValues;

    return {
      minimumOrderValue: selectedRegions[0]?.minimumOrderValue,
      cutOffTime: selectedRegions[0]?.cutOffTime,
      deliverySla: selectedRegions[0]?.deliverySla,
      weekdays: selectedRegions[0]?.weekdays,
    };
  }, [selectedRegions, isApplyToAll]);

  return (
    <RegionSettingsBox
      regionIds={selectedRegions?.map((r) => r.id)}
      title={t('apply-all')}
      {...applyToAllData}
      isDisabled={!selectedRegions?.length}
      isApplyAll
    />
  );
};
