import { combineReducers } from '@reduxjs/toolkit';

import auth from 'redux/auth/reducer';
import general from 'redux/general/reducer';
import orderDetailsPage from 'redux/orders/order-details-page-reducer';
import ordersListPage from 'redux/orders/orders-list-page-reducer';
import sidebar from 'redux/sidebar/reducer';
import table from 'redux/table/reducer';

export default combineReducers({
  auth,
  ordersListPage,
  orderDetailsPage,
  sidebar,
  table,
  general,
});
