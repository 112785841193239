import { cn } from '@nextui-org/react';
import { useAuth } from 'hooks/use-auth';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setHiddenUnlockFullAccessAction } from 'redux/general/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Button } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';
import { URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';

export const UnlockFullAccess = ({ className, ...rest }: HTMLProps<HTMLDivElement>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isHidden } = useAppSelector((state) => state.general.unlockFullAccess);
  const { isMobile } = useBreakpoint();
  const { isGuest } = useAuth();
  const navigate = useNavigate();

  const handleDismiss = () => {
    dispatch(setHiddenUnlockFullAccessAction(true));
  };

  const onLogin = () => {
    navigate(URL.LOGIN);
    gaLogEvent('action_click_login_ext');
  };

  if (!isGuest || isHidden) return null;
  return (
    <div
      className={cn(
        'mx-sm mt-sm sm:mx-0 sm:mt-0 p-sm sm:p-md',
        'drop-shadow-md bg-secondary-500 text-white rounded-md flex flex-col sm:flex-row sm:items-center items-start justify-between gap-xs',
        className
      )}
      {...rest}>
      <div>
        <p className='font-bold'>{t('unlock_full_access')}</p>
        <p className='text-sm'>{t('unlock_full_access_body')}</p>
      </div>

      <div className='flex gap-sm'>
        <Button variant='solid' className='bg-white' size={isMobile ? 'sm' : 'md'} onClick={onLogin}>
          {t('login')}
        </Button>

        <Button variant='light' className='text-white' size={isMobile ? 'sm' : 'md'} onClick={handleDismiss}>
          {t('dismiss')}
        </Button>
      </div>
    </div>
  );
};
