import { useTranslation } from 'react-i18next';
import { Chip } from '../../../theme/components';
import { orderStatusChipColor, orderStatusMap } from '../utils/order-status';

export interface IStatusBadgeCell {
  status: string;
}

export const StatusBadgeCell = ({ status }: IStatusBadgeCell) => {
  const { t } = useTranslation();

  return (
    <Chip color={orderStatusChipColor[status].color} variant='dot'>
      {t(orderStatusMap[status].name)}
    </Chip>
  );
};
