import { cn } from '@nextui-org/react';
import { Button, Modal } from 'antd';
import { useApproveOrdersApi } from 'apis/orders/use-approve-orders-api';
import { useDisclosure } from 'hooks/use-disclosure';
import { t } from 'i18next';
import { useBreakpoint } from 'theme/hooks';
import { gaLogEvent } from 'utils/ga-log-event';

interface AcceptOrdersButtonProps {
  selectedOrders: number[];
  callback?: (approvedOrders: number[]) => void;
}

export const AcceptOrdersButton = ({ selectedOrders, callback }: AcceptOrdersButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, mutate: approveOrdersApi } = useApproveOrdersApi();
  const { isMobile } = useBreakpoint();

  const onConfirm = () => {
    const variables = {
      body: {
        ids: selectedOrders,
      },
    };

    approveOrdersApi(variables, {
      onSuccess: () => {
        gaLogEvent('ACCEPT_ORDER', {
          ids: variables?.body?.ids,
          total_approved_orders: variables?.body?.ids?.length,
        });
        onClose();
        callback?.(selectedOrders);
      },
    });
  };

  return (
    <>
      {isMobile && (
        <Button type='primary' className='bg-opacity-30 text-white' onClick={onOpen}>
          {t('bulk_approve')}
        </Button>
      )}

      {!isMobile && (
        <Button
          type='primary'
          size='large'
          className={cn(!selectedOrders.length ? 'hidden' : 'block', 'px-xl', 'bg-success-500 hover:!bg-success-500')}
          onClick={onOpen}>
          {t('accept-orders')}
        </Button>
      )}

      <Modal
        open={isOpen}
        onCancel={onClose}
        destroyOnClose
        title={
          <div className='mb-md'>
            <p className='text-secondary-500'>{t('accept-orders')}</p>
          </div>
        }
        footer={
          <div className='flex gap-2 justify-end'>
            <Button type='default' onClick={onClose}>
              {t('cancel')}
            </Button>

            <Button
              type='primary'
              className='bg-success-500 hover:!bg-success-500'
              onClick={onConfirm}
              loading={isLoading}>
              {t('accept')}
            </Button>
          </div>
        }>
        <p className='text-secondary-500'>{t('are-you-sure-you-want-to-bulk-accept-the-selected-orders')}</p>
      </Modal>
    </>
  );
};
