import { LoadingOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import { theme } from 'antd';
import { useMemo } from 'react';
import { usePaginationParams } from './use-pagination-params';

interface UsePageTableSettingsReturn {
  scroll: TableProps['scroll'];
  loading: TableProps['loading'];
  pagination: TableProps['pagination'];
}

interface UsePageTableSettingsProps {
  /**
   * data: all the returned data
   */
  data: any;
  /**
   * total: is the total count for the whole data set
   */
  total: number;
  /**
   * isLoading: is the table loading state
   */
  isLoading: boolean;
  /**
   * isFetching: is the table fetching state
   */
  isFetching?: boolean;
}

export const usePageTableSettings = ({
  data,
  total,
  isLoading,
}: UsePageTableSettingsProps): UsePageTableSettingsReturn => {
  const { current, pageSize, onPaginationChange } = usePaginationParams();
  const { token } = theme.useToken();

  // Pagination
  const pagination = useMemo<TableProps['pagination']>(() => {
    return {
      total,
      current,
      pageSize,
      showSizeChanger: true,
      pageSizeOptions: [25, 50, 100],
      position: ['bottomCenter'],
      size: 'default',
      onChange: onPaginationChange,
    };
  }, [data]);

  // Loading
  const loading = useMemo<TableProps['loading']>(() => {
    return {
      spinning: isLoading,
      indicator: <LoadingOutlined style={{ color: token.colorPrimary }} spin />,
      size: 'large',
      className: '!max-h-full',
    };
  }, [isLoading]);

  // Scroll
  const pageBodyHeight = document.getElementById('sp_page_body')?.clientHeight;
  const tableHeaderHight = document
    .getElementById('sp_page_body')
    ?.getElementsByClassName('ant-table-thead')?.[0]?.clientHeight;
  const tablePaginationHeight = 65;

  const scroll = useMemo<TableProps['scroll']>(() => {
    if (pageBodyHeight && tableHeaderHight) {
      return {
        y: pageBodyHeight - tableHeaderHight - tablePaginationHeight,
      };
    }

    return {
      y: undefined,
    };
  }, [pageBodyHeight, tableHeaderHight]);

  return {
    pagination,
    loading,
    scroll,
  };
};
