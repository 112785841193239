import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useAuth } from 'hooks/use-auth';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/suppliers/restaurants';
export const getRestaurantEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = [
  typeof endpoint,
  {
    restaurantId: TParam;
    language: TParam;
  }
];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetRestaurantApi(options?: Options): ReturnType {
  const { isLoggedIn } = useAuth();
  const { getPageParams } = usePageParams();
  const { restaurantId, restaurantLang } = getPageParams();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {
          id: restaurantId,
          language: restaurantLang,
          page: 1,
          limit: 100,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        restaurantId,
        language: restaurantLang,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
