import { message } from 'antd';
import internalHttp from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useAuth } from 'hooks/use-auth';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/users/myself';
export const getMeEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [typeof endpoint];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useMeApi(options?: Options): ReturnType {
  const { isLoggedIn } = useAuth();

  const queryFn = async () => {
    try {
      const { data } = await internalHttp.get(endpoint);
      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
