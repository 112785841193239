import { Link } from 'react-router-dom';
import { Button } from 'theme/components';
import { URL } from 'utils/constants';

interface OrderCodeCellProps {
  orderId: number;
  orderCode: string;
}

export const OrderCodeCell = ({ orderId, orderCode }: OrderCodeCellProps) => {
  return (
    <Button className='p-0 h-auto bg-transparent'>
      <Link to={`${URL.ORDERS_DETAILS}/${orderId}`}>
        <p className='font-bold text-[#5BC0DE]'>{orderCode}</p>
      </Link>
    </Button>
  );
};
