import { Analytics, getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { LocalStorageKeys } from 'utils/constants';

const firebaseConfig = {
  apiKey: 'AIzaSyCbgIaSoj869uoDoxHmUpxCseAnKF9zps4',
  authDomain: 'supplier-dashboard-b8643.firebaseapp.com',
  projectId: 'supplier-dashboard-b8643',
  storageBucket: 'supplier-dashboard-b8643.appspot.com',
  messagingSenderId: '682550286838',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

let analytics: Analytics;
const app = initializeApp(firebaseConfig);

const isSupportLogin = localStorage.getItem(LocalStorageKeys.is_support_login);
const isProduction = process.env.REACT_APP_ENV_VAR === 'production';

(async () => {
  if ((await isSupported()) && !isSupportLogin && isProduction) {
    analytics = getAnalytics(app);
  } else {
    console.log('Firebase Analytics is not supported in this environment.');
  }
})();

export { analytics };
