import { cn } from '@nextui-org/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnScrollPaginationChange } from 'hooks/use-on-scroll-pagination-change';
import { CloseIcon } from 'pages/orders/components/mobile-orders-list/icons/close-icon';
import { OrdersListItem } from 'pages/orders/components/mobile-orders-list/orders-list-item';
import { OrdersEmptyState } from 'pages/orders/components/order-empty-state';
import { useSelectedOrders } from 'pages/orders/hooks/use-selected-orders';
import { Order } from 'pages/orders/types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Card, CheckboxGroup, Progress, Spinner } from 'theme/components';
import { useAppDispatch } from '../../../../redux/hooks';
import { removeOrdersByIdsAction } from '../../../../redux/orders/orders-list-page-reducer';
import { AcceptOrdersButton } from '../accept-orders-button';

interface MobileOrdersListProps {
  orders: Order[];
  ordersType: 'new' | 'confirmed' | 'history';
  hasNext: boolean;
  isLoading: boolean;
  isFetching: boolean;
  enableSelection?: boolean;
}

export const MobileOrdersList = ({
  isLoading,
  isFetching,
  orders,
  ordersType,
  hasNext,
  enableSelection = false,
}: MobileOrdersListProps) => {
  const { t } = useTranslation();
  const { nextPage } = useOnScrollPaginationChange();
  const dispatch = useAppDispatch();
  const { selected, resetSelectedOrders, setSelectedOrders } = useSelectedOrders();

  // 👇️ reset all selected orders before you unmount the component 👇️
  useEffect(() => {
    return () => {
      resetSelectedOrders?.();
    };
  }, []);

  const onDeselectAll = () => {
    resetSelectedOrders?.();
  };

  const onBulkApproveSuccess = (approvedOrdersIds: number[]) => {
    dispatch(removeOrdersByIdsAction({ ordersType, ids: approvedOrdersIds }));
    resetSelectedOrders?.();
  };

  const isEmpty = useMemo(() => {
    return !orders?.length;
  }, [orders]);

  if (isLoading && isEmpty) {
    return (
      <div className='grid place-content-center'>
        <Spinner color='secondary' size='lg' />
      </div>
    );
  }

  if (!isFetching && !isLoading && isEmpty) {
    return (
      <div className='grid place-content-center'>
        <OrdersEmptyState />
      </div>
    );
  }

  return (
    <div className='relative'>
      {isFetching && !isLoading && (
        <Progress
          size='sm'
          color='secondary'
          isIndeterminate
          aria-label='Loading...'
          className='max-w-md sticky top-0 left-0 right-0 z-10'
        />
      )}

      <InfiniteScroll
        dataLength={orders.length}
        next={nextPage}
        hasMore={hasNext || isLoading}
        scrollableTarget='scrollableSection'
        loader={
          <div className='flex justify-center pt-xs pb-md'>
            <Spinner color='secondary' size='sm' />
          </div>
        }
        endMessage={
          <p className='flex justify-center pt-xs pb-md'>
            <b>{t('end_content_message')}</b>
          </p>
        }>
        <CheckboxGroup
          value={selected}
          onChange={(val) => setSelectedOrders?.(val as string[])}
          classNames={{ base: 'w-full', wrapper: 'divide-y' }}>
          {orders.map((o) => (
            <OrdersListItem
              key={o?.id}
              id={o?.id.toString()}
              code={o?.code}
              total={o?.total}
              currencyCode={o?.country?.currency}
              brandName={o?.restaurant?.name}
              createdAt={o?.createdAt}
              status={o?.status}
              enableSelection={enableSelection}
              selectedOrders={selected}
              setSelectedOrders={setSelectedOrders}
            />
          ))}
        </CheckboxGroup>
      </InfiniteScroll>

      <div className={cn('sticky bottom-0 z-10')}>
        <AnimatePresence>
          {!!selected?.length && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Card className={cn('flex flex-row', 'bg-secondary-500 justify-between p-xs m-xs')}>
                <Button
                  startContent={<CloseIcon />}
                  color='default'
                  className='bg-opacity-30 text-white'
                  onClick={onDeselectAll}>
                  {selected?.length}
                </Button>

                <AcceptOrdersButton selectedOrders={selected.map((o) => Number(o))} callback={onBulkApproveSuccess} />
              </Card>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
