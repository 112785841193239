/**
 * @name Layout
 * @author Moaaz Ahmed
 * @summary This file will be containing all the major layout components for handling the layout sections and types.
 * @description As you can see in the file we are using custom javascript logic for handling sections and types.
 * @access private
 *
 * @function Section This is main component to be re-used outside the file to call.
 * @function Layout This is main component to be re-used outside the file to call.
 * @readonly Please don't make any changes to this file.
 */

import { AuthPageLayout } from 'layout/auth-page-layout';
import { BlankPageLayout } from 'layout/blank-page-layout';
import { GetStartedLayout } from 'layout/get-started-layout';
import { OrderPageLayout } from 'layout/order-page-layout';
import { SidebarPageLayout } from 'layout/sidebar-page-layout';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import { ReactNode } from 'react';
import { MobilePageLayout } from './mobile-page-layout';

// Section
interface SectionProps {
  name: string;
  sections?: any;
  children?: ReactNode;
}

export function Section(props: SectionProps): JSX.Element {
  const { name, sections, children } = props;
  let section = null;

  // Check if object
  if (_isPlainObject(sections)) {
    if (sections?.props?.name === name) section = { ...sections };
  }

  // Check if array
  if (_isArray(sections)) {
    section = sections?.find((section: any) => section?.props?.name === name);
  }

  // Check if a Section component
  if (section) {
    const elementType = typeof section?.type === 'string' ? section?.type : section?.type?.displayName;

    if (elementType !== 'Section') {
      throw new Error(
        `[${elementType}] is not a Section component type. you can use <Section name=""></Section> instead.`
      );
    }

    return <>{section?.props?.children}</>;
  }

  return <>{children}</>;
}

Section.displayName = 'Section';

// Layout
interface LayoutProps {
  type?:
    | 'sidebar_page_layout'
    | 'blank_page_layout'
    | 'auth_page_layout'
    | 'order_page_layout'
    | 'get_started_layout'
    | 'mobile_page_layout';
  children?: ReactNode;
  pageTitle?: string;
  showMobileHeader?: boolean;
  customPrevUrl?: string;
}

export function Layout({ type, children, pageTitle, showMobileHeader, customPrevUrl }: LayoutProps): JSX.Element {
  switch (type) {
    case 'sidebar_page_layout':
      return <SidebarPageLayout sections={children} pageTitle={pageTitle} />;

    case 'blank_page_layout':
      return <BlankPageLayout sections={children} />;

    case 'auth_page_layout':
      return <AuthPageLayout sections={children} showMobileHeader={showMobileHeader} customPrevUrl={customPrevUrl} />;

    case 'get_started_layout':
      return <GetStartedLayout sections={children} />;

    case 'order_page_layout':
      return <OrderPageLayout sections={children} />;

    case 'mobile_page_layout':
      return <MobilePageLayout sections={children} />;

    default:
      return <BlankPageLayout sections={children} />;
  }
}

Layout.displayName = 'Layout';
