import { useAuth } from 'hooks/use-auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { closePleaseLoginModalAction } from 'redux/general/reducer';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from 'theme/components';
import { URL } from 'utils/constants';

export const PleaseLoginModal = () => {
  const { isOpen } = useAppSelector((state) => state.general.pleaseLoginModal);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isGuest } = useAuth();

  const onClose = () => {
    dispatch(closePleaseLoginModalAction());
  };

  const onAction = () => {
    navigate(URL.LOGIN);
    onClose();
  };

  if (!isGuest) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>{t('please_login_title')}</ModalHeader>
        <ModalBody>
          <p>{t('please_login_body')}</p>
        </ModalBody>
        <ModalFooter>
          <Button variant='light' onPress={onClose}>
            {t('close')}
          </Button>
          <Button color='secondary' onPress={onAction}>
            {t('login')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
