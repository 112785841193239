import { CustomCheckbox } from 'components/custom-checkbox';
import _isArray from 'lodash/isArray';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from 'theme/components';
import { OrdersFilterSectionProps } from '.';

const alwaysArray = (value: string[]) => {
  if (_isArray(value)) return value;
  return [value];
};

export const OrdersStatusFilter = ({ filter, setFilter }: OrdersFilterSectionProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(alwaysArray(filter.status));
  }, []);

  const updateFilter = (status: string[]) => {
    setFilter((prev) => {
      return {
        ...prev,
        status,
      };
    });
  };

  useEffect(() => {
    updateFilter(selected);
  }, [selected]);

  return (
    <CheckboxGroup value={selected} onValueChange={setSelected} defaultValue={[...filter.status]}>
      <CustomCheckbox value='received'>{t('received')}</CustomCheckbox>
      <CustomCheckbox value='canceled'>{t('canceled')}</CustomCheckbox>
      <CustomCheckbox value='rejected'>{t('rejected')}</CustomCheckbox>
    </CheckboxGroup>
  );
};
