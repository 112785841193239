import { ProfileMenu } from 'components/my-profile/profile-menu';
import { useAuth } from 'hooks/use-auth';
import { useCurrentUser } from 'hooks/use-current-user';
import { useTranslation } from 'react-i18next';

export const MyProfile = () => {
  const { user } = useCurrentUser();
  const { isGuest } = useAuth();
  const { t } = useTranslation();

  return (
    <div className='flex gap-sm items-center'>
      <p className='text-lg font-bold text-secondary-500'>{isGuest ? t('guest') : user?.name}</p>

      <ProfileMenu />
    </div>
  );
};
