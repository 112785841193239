import { Box, BrandLogo, Center, Heading, Text } from '@elkaso-app/web-design';
import { Layout, Section } from 'layout';
import { NotFoundIcon } from 'pages/not-found/icons/not-found-icon';
import BgImageLg from 'pages/not-found/svg/bg-image-lg.svg';

const NotFoundPage = () => {
  return (
    <Layout type='blank_page_layout'>
      <Section name='content'>
        <Box
          minH='100vh'
          bgImage={BgImageLg}
          bgSize='contain'
          bgRepeat='no-repeat'
          bgPosition={'center'}
          filter={'invert(0%)'}>
          <Center flexDir='column'>
            <BrandLogo mt='6rem' />
            <NotFoundIcon mt={['10rem', '5rem']} width={['320px', '737px']} height={['200px', '392px']} />
            <Heading mt='5rem' size={'lg'}>
              Page not found
            </Heading>
            <Text mt={'md'} fontSize={['md', 'xl']} color={'gray.400'} maxW={['330px', '500px']} textAlign={'center'}>
              We&apos;re sorry, the page you requested could not be found Please go back to the homepage
            </Text>
          </Center>
        </Box>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
