import { useIsRTL } from 'hooks/use-is-rtl';
import { CountryType } from '../types';

export const useCountrySelect = (
  selectedCountry: CountryType,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  setSelectedCountry: (country: CountryType) => void,
  onClose: () => void,
  phoneNumber: string,
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
) => {
  const isRTL = useIsRTL();

  return (country: CountryType) => {
    setSelectedCountry(country);
    onClose();
    setSearchTerm('');

    const syntheticEvent = {
      target: {
        value: country.code + phoneNumber,
        name: isRTL ? selectedCountry.nameAr : selectedCountry.nameEn,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    onChange?.(syntheticEvent);
  };
};
