import { formatDateTime } from '@elkaso-app/web-design';
import { cn } from '@nextui-org/react';
import { t } from 'i18next';
import { HTMLProps } from 'react';

interface StatusDetailsCardProps extends HTMLProps<HTMLDivElement> {
  status: string;
  updatedByUser: Record<string, any>;
  history: Record<string, any>;
  reason: string;
}

export const StatusDetailsCard = ({
  status,
  updatedByUser,
  history,
  reason,
  className,
  ...rest
}: StatusDetailsCardProps) => {
  // Show only for non-new orders;
  if (status === 'restaurant_placed') return null;

  const createdAt = history?.find((i: any) => i.status === status)?.createdAt;

  return (
    <div className={cn('flex flex-col lg:gap-sm gap-xs lg:m-md', className)} {...rest}>
      <div>
        <p className='text-sm text-default-500'>{t(`${status}-by`)}:</p>
        <p>{`${updatedByUser?.name} on - ${formatDateTime(createdAt, 'DD/MM/YYYY [at] hh:mm A')}`}</p>
      </div>

      {status === 'rejected' && (
        <div>
          <p className='text-sm text-default-500'>{t('reason-of-rejection')}:</p>
          <p className='max-w-[300px]'>{reason ?? t('no_reason_mentioned')}.</p>
        </div>
      )}
    </div>
  );
};
