import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  isGuest: boolean;
  isLoggedIn: boolean;
};

const initialState: SliceState = {
  isGuest: false,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsGuestAction(state, action: PayloadAction<boolean>) {
      state.isGuest = action.payload;
    },
    setIsLoggedInAction(state, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setIsGuestAction, setIsLoggedInAction } = authSlice.actions;
export default authSlice.reducer;
