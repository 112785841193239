import { useAuth } from 'hooks/use-auth';
import { Navigate } from 'react-router-dom';

interface IProtectedRoute {
  component: JSX.Element;
}

export const PreLoginRoute = ({ component }: IProtectedRoute) => {
  const { isLoggedIn, isGuest } = useAuth();

  if (isLoggedIn && isGuest) {
    return component;
  }

  if (isLoggedIn && !isGuest) {
    return <Navigate to='/' />;
  }

  return component;
};
