import { Button, Modal, Tooltip } from 'antd';
import { useGetDownloadCsvLpoApi } from 'apis/orders/use-get-download-csv-lpo-api';
import { useGetDownloadPdfLpoApi } from 'apis/orders/use-get-download-pdf-lpo-api';
import { CustomRadio } from 'components/custom-radio';
import { useDisclosure } from 'hooks/use-disclosure';
import { FileIcon } from 'pages/orders/icons/file-icon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiDownload } from 'react-icons/hi';
import { RadioGroup } from 'theme/components';
import { Order } from '../types';

type fileSlug = 'csv' | 'pdf';

const fileMap: Record<fileSlug, { slug: fileSlug; value: string }> = {
  csv: {
    slug: 'csv',
    value: 'csv',
  },
  pdf: {
    slug: 'pdf',
    value: 'pdf',
  },
};

interface DownloadLpoModalProps {
  orderStatus: Order['status'];
  orderId: Order['id'];
  orderCode: Order['code'];
}

export const DownloadLpoModal = ({ orderStatus, orderId, orderCode }: DownloadLpoModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState<fileSlug>('csv');

  const {
    isLoading: isLoadingPdfLpoApi,
    isFetching: isFetchingPdfLpoApi,
    refetch: getDownloadPdfLpoApi,
  } = useGetDownloadPdfLpoApi(orderId.toString(), orderCode);

  const {
    isLoading: isLoadingCsvLpoApi,
    isFetching: isFetchingCsvLpoApi,
    refetch: getDownloadCsvLpoApi,
  } = useGetDownloadCsvLpoApi(orderId.toString(), orderCode);

  const isLoading = useMemo(() => {
    return isLoadingPdfLpoApi || isLoadingCsvLpoApi || isFetchingPdfLpoApi || isFetchingCsvLpoApi;
  }, [isLoadingPdfLpoApi, isFetchingPdfLpoApi, isLoadingCsvLpoApi, isFetchingCsvLpoApi]);

  const onDownload = () => {
    if (selected === 'pdf') {
      getDownloadPdfLpoApi().finally(() => {
        onClose();
      });
    }

    if (selected === 'csv') {
      getDownloadCsvLpoApi().finally(() => {
        onClose();
      });
    }
  };

  const options = useMemo(() => {
    return [
      { label: t('csv-file-label'), value: fileMap.csv.slug },
      { label: t('pdf-file-label'), value: fileMap.pdf.slug },
    ];
  }, []);

  const _onClose = () => {
    setSelected('csv');
    onClose();
  };

  return (
    <>
      <Tooltip title={orderStatus === t('canceled') ? '' : t('download-lpo')}>
        <Button
          onClick={onOpen}
          size='middle'
          type='text'
          icon={<HiDownload size='16px' />}
          disabled={orderStatus === 'canceled'}
        />
      </Tooltip>

      <Modal
        open={isOpen}
        onCancel={_onClose}
        destroyOnClose
        title={
          <div className='modal-header'>
            <div className='modal-title'>{t('download-lpo')}</div>
            <div className='modal-subtitle'>{t('please-select-the-file-type')}</div>
          </div>
        }
        footer={
          <div className='modal-footer'>
            <Button type='default' onClick={_onClose}>
              {t('cancel')}
            </Button>

            <Button type='primary' onClick={onDownload} loading={isLoading}>
              {t('download')}
            </Button>
          </div>
        }>
        <RadioGroup value={selected} onValueChange={(val) => setSelected(val as fileSlug)}>
          {options.map(({ value, label }) => {
            return (
              <CustomRadio key={value} value={value}>
                <div className='flex items-center gap-xs'>
                  <FileIcon fill={value === 'csv' ? '#258750' : '#E03E52'} />
                  <p>{label}</p>
                </div>
              </CustomRadio>
            );
          })}
        </RadioGroup>
      </Modal>
    </>
  );
};
