import { Switch } from 'antd';
import { useUpdateProductStatusApi } from 'apis/products/use-update-product-status-api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../type';

interface ProductsStatusCellProps {
  product: Product;
}

export const ProductsStatusCell = ({ product }: ProductsStatusCellProps) => {
  const { t } = useTranslation();
  const { mutate: updateProductStatusApi, isLoading } = useUpdateProductStatusApi(product.id);

  const isChecked = useMemo<boolean>(() => {
    return product.status === 'active';
  }, [product]);

  const onChange = (checked: boolean) => {
    const status: 'active' | 'inactive' = checked ? 'active' : 'inactive';

    const variables = {
      body: {
        status,
      },
    };

    updateProductStatusApi(variables);
  };

  return (
    <div className='flex items-center gap-2'>
      <Switch value={isChecked} onChange={onChange} size='small' loading={isLoading} />
      <p>{isChecked ? t('active') : t('inactive')}</p>
    </div>
  );
};
