import { HStack, IconProps, Text, useTab } from '@elkaso-app/web-design';
import React, { ComponentType } from 'react';

interface CustomTabProps {
  icon: ComponentType<IconProps>;
  children: React.ReactNode;
}

export const CustomTab = React.forwardRef<HTMLElement | null, CustomTabProps>(({ icon: Icon, ...rest }, ref) => {
  const tabProps = useTab({ ...rest, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <HStack
      spacing='md'
      align='center'
      cursor={isSelected ? 'default' : 'pointer'}
      p='sm'
      bgColor={isSelected ? 'gray.100' : 'white'}
      borderRadius='base'
      {...tabProps}>
      <Icon fill={isSelected ? 'blue.500' : 'gray.600'} />
      <Text fontSize='sm' fontWeight='semibold' color={isSelected ? 'blue.500' : 'gray.600'}>
        {tabProps.children}
      </Text>
    </HStack>
  );
});
