import { useGetCurrency } from 'hooks/use-get-currency';
import { t } from 'i18next';
import _multiply from 'lodash/multiply';
import { InfoItem } from 'pages/orders/components/order-details-section/info-item';
import { OrderDetailsItem } from 'pages/orders/components/order-details-section/order-details-item';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Divider } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';
import { formatPrice } from 'utils/format-price';
import { OrderItemsTable } from './order-items-table';

interface OrderDetailsSectionProps {
  subtotal: number;
  vat: number;
  total: number;
  status: string;
}

export const OrderDetailsSection = ({ subtotal, vat, total, status }: OrderDetailsSectionProps) => {
  const isReceived = status === 'received';
  const { currency } = useGetCurrency();
  const { isMobile } = useBreakpoint();

  const { clonedItems } = useAppSelector((state) => state.orderDetailsPage);

  const calculateVatValue = (totalPrice: number, vat: number): number => {
    if (!totalPrice || !vat) return 0;
    return _multiply(totalPrice, vat / 100);
  };

  const subTotalVal = useMemo(() => {
    return formatPrice(subtotal ?? 0, currency);
  }, [subtotal]);

  const vatVal = useMemo(() => {
    return `(${formatPrice(vat ?? 0)}%)` + ' ' + formatPrice(calculateVatValue(subtotal, vat) ?? 0, currency);
  }, [vat]);

  const totalVal = useMemo(() => {
    return formatPrice(total ?? 0, currency);
  }, [total]);

  const headers = [t('item-code'), t('items'), t('unit'), t('unit-price'), t('qty'), t('total-price')];

  if (isReceived) {
    headers.splice(5, 0, t('received_qty'));
  }

  if (isMobile) {
    return (
      <div className='flex flex-col gap-md'>
        <div>
          {clonedItems?.map((item: any) => (
            <OrderDetailsItem key={item?.id} item={item} currency={currency} status={status} />
          ))}
        </div>

        <div className='flex flex-col gap-sm'>
          <InfoItem label={t('number-of-items')} info={clonedItems?.length} />

          <Divider />

          <div className='flex flex-col gap-1'>
            <InfoItem label={t('sub-total')} info={subTotalVal} />
            <InfoItem label={t('vat')} info={vatVal} />
            <InfoItem label={t('total')} info={totalVal} className='text-danger-500 font-bold' />
          </div>
        </div>
      </div>
    );
  }

  return <OrderItemsTable subtotal={subTotalVal} vat={vatVal} total={totalVal} status={status} />;
};
