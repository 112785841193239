import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetRecommendedProductsApi } from './use-get-recommended-products-api';

const endpoint = 'ums/v1/supplier/catalog/marketing/recommendations';

type TData = Record<string, any>;
type TError = AxiosError;

type BodyType = {
  productIds: number[];
};

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint() {
  return `${endpoint}`;
}

export function useUpdateRecommendedProductsApi(options?: Options): ReturnType {
  const fullEndpoint = constructEndpoint();
  const { refetch } = useGetRecommendedProductsApi({ enabled: false });

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.put(fullEndpoint, body);

      // Refetch recommended products
      refetch();

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
