import { formatDateTime } from '@elkaso-app/web-design';
import { cn } from '@nextui-org/react';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomerDetailsCardProps extends HTMLProps<HTMLDivElement> {
  customerName: string;
  branch: string;
  deliveryDate: string;
  subEntityType: string;
  note: string;
}

export const CustomerDetailsCard = ({
  customerName,
  branch,
  deliveryDate,
  subEntityType,
  note,
  className,
  ...rest
}: CustomerDetailsCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex flex-col gap-sm items-start p-md rounded-md bg-default-50', className)} {...rest}>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t('customer-name')}:</p>
        <p className='font-bold'>{customerName}</p>
      </div>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t(subEntityType === 'branch' ? 'branch' : 'warehouse')}:</p>
        <p className='font-bold'>{branch}</p>
      </div>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t('delivery-date')}:</p>
        <p className='font-bold'>{formatDateTime(deliveryDate, 'DD-MM-YYYY')}</p>
      </div>

      <div className='p-sm bg-white rounded-md w-full'>
        <div className='flex flex-col items-start max-w-[300px]'>
          <p className='text-sm text-default-600'>{t('note')}:</p>
          {!note && <p className='text-sm text-default-400'>{t('no_notes_are_available')}.</p>}
          {!!note && <p>{note}</p>}
        </div>
      </div>
    </div>
  );
};
