import { useAppCheckApi } from 'apis/auth/use-app-check-api';
import { useEffect } from 'react';
import { LocalStorageKeys } from 'utils/constants';
import { useAuth } from './use-auth';

export const useAppCheck = () => {
  const { isLoggedIn } = useAuth();

  const { isLoading, mutateAsync: appCheckApi } = useAppCheckApi();

  const createPublicToken = async () => {
    const variables = { body: {} };
    const { data } = await appCheckApi(variables);
    localStorage.setItem(LocalStorageKeys.public_token, data?.token);
  };

  const getPublicToken = () => {
    return localStorage.getItem(LocalStorageKeys.public_token);
  };

  const updatePublicToken = (forceUpdate?: boolean) => {
    if (isLoggedIn && !forceUpdate) return;
    createPublicToken();
  };

  const deletePublicToken = () => {
    localStorage.removeItem(LocalStorageKeys.public_token);
  };

  const token = getPublicToken();

  useEffect(() => {
    if (isLoggedIn) {
      deletePublicToken();
    }

    if (!isLoggedIn && !token) {
      createPublicToken();
    }
  }, []);

  return {
    isLoading,
    updatePublicToken,
    getPublicToken,
    deletePublicToken,
  };
};
