import { OnboardingForm } from 'components/onboarding-form';
import { Layout, Section } from 'layout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';
import { URL } from 'utils/constants';

const GetStartedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();

  if (isMobile) {
    return (
      <Layout type='get_started_layout'>
        <Section name='content'>
          <OnboardingForm className='flex flex-col items-center px-md'>
            <OnboardingForm.Title className='text-white text-center max-w-[250px]'>
              {t('welcome-to-supplier-portal')}
            </OnboardingForm.Title>
            <OnboardingForm.Subtitle className='text-white text-center'>
              {t('get-started-welcome-message')}
            </OnboardingForm.Subtitle>

            <Button color='primary' fullWidth className='mt-xs' size='lg' onClick={() => navigate(URL.LOGIN)}>
              {t('login')}
            </Button>
          </OnboardingForm>
        </Section>
      </Layout>
    );
  }

  return (
    <Layout type='get_started_layout'>
      <Section name='content'>
        <OnboardingForm>
          <OnboardingForm.Title>{t('welcome-to-supplier-portal')}</OnboardingForm.Title>
          <OnboardingForm.Subtitle>{t('get-started-welcome-message')}</OnboardingForm.Subtitle>

          <Button color='primary' fullWidth className='mt-8' size='lg' onClick={() => navigate(URL.LOGIN)}>
            {t('login')}
          </Button>
        </OnboardingForm>
      </Section>
    </Layout>
  );
};

export default GetStartedPage;
