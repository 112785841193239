import { useBreakpoint } from 'theme/hooks';
import { DesktopRecommendationsScreen } from './desktop';
import { MobileRecommendationsScreen } from './mobile';

export const RecommendationsScreen = () => {
  const { isMobile } = useBreakpoint();

  if (isMobile) {
    return <MobileRecommendationsScreen />;
  }

  return <DesktopRecommendationsScreen />;
};
