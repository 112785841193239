import { Box, BrandLogo, Center, Heading, Text } from '@elkaso-app/web-design';
import { Layout, Section } from 'layout';
import BgImageLg from 'pages/not-found/svg/bg-image-lg.svg';

const AccessDeniedPage = () => {
  return (
    <Layout type='blank_page_layout'>
      <Section name='content'>
        <Box
          minH='100vh'
          bgImage={BgImageLg}
          bgSize='contain'
          bgRepeat='no-repeat'
          bgPosition={'center'}
          filter={'invert(0%)'}>
          <Center flexDir='column'>
            <BrandLogo mt='6rem' />
            <Heading mt='5rem' size={'lg'}>
              Access denied
            </Heading>
            <Text mt={'md'} fontSize={['md', 'xl']} color={'gray.400'} maxW={['330px', '500px']} textAlign={'center'}>
              We&apos;re sorry, the page you requested could not be accessed, please try again.
            </Text>
          </Center>
        </Box>
      </Section>
    </Layout>
  );
};

export default AccessDeniedPage;
