import {
  Divider,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useCheckboxGroup,
} from '@elkaso-app/web-design';
import { useGetRegionsApi } from 'apis/settings/use-get-regions-api';
import { useGetRegionsDetailsApi } from 'apis/settings/use-get-regions-details-api';
import _uniq from 'lodash/uniq';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionSettingsCustomDays } from './region-setting-custom-days';
import { RegionSettingsApplyAll } from './region-settings-apply-all';
import { RegionsList } from './regions-list';

export type RegionData = {
  id: string;
  name: string;
  minimumOrderValue: number;
  cutOffTime: string;
  deliverySla: number;
  weekdays: number[];
};

export const ShippingDeliveryTab = () => {
  const { t } = useTranslation();
  const { value, getCheckboxProps, setValue } = useCheckboxGroup();

  const { isLoading: isLoadingRegions, data: regionsApiData } = useGetRegionsApi();
  const { data: regions = [] } = regionsApiData ?? {};

  const { isLoading: isLoadingRegionsDetails, data: regionsDetailsApiData, isSuccess } = useGetRegionsDetailsApi();
  const { data: regionsData = [] } = regionsDetailsApiData ?? {};

  // Set default values
  useEffect(() => {
    if (!isSuccess || !regionsData) return;

    const defaultValue: string[] = regionsData?.map((r: any) => r.id.toString()) ?? [];

    /*
     * _uniq: important to remove the duplicated keys in both arrays "value" and "defaultValue"
     * Also we are spreading value here to keep the checked but not saved regions
     */
    setValue(_uniq([...value, ...defaultValue]));
  }, [isSuccess, regionsData]);

  const getSelectedRegionsData = useMemo<RegionData[]>(() => {
    return value?.map((i) => {
      /**
       * We have to get the ID and name from the regions list
       * Because when adding new region we should have the ID and name from regions list
       */
      const region = regions?.find((r: any) => r.id == i);

      // We have to get the region settings values from regionsData API
      const regionData = regionsData?.find((r: any) => r.id == i);

      return {
        id: region?.id ?? '',
        name: region?.name ?? '',
        minimumOrderValue: regionData?.minimumOrderValue ?? region.defaultValues.minimumOrderValue,
        cutOffTime: regionData?.cutOffTime ?? region.defaultValues.cutOffTime,
        deliverySla: regionData?.deliverySla ?? region.defaultValues.deliverySla,
        weekdays:
          regionData?.weekdays?.sort((a: number, b: number) => a - b) ??
          region.defaultValues.weekdays.sort((a: number, b: number) => a - b),
      };
    });
  }, [value, regionsData]);

  if (isLoadingRegions || isLoadingRegionsDetails) return <>Loading...</>;
  return (
    <>
      <Heading as='h2' color='blue.500' fontSize='xl'>
        {t('shipping-and-delivery')}
      </Heading>

      <Divider my='md' borderColor='gray.500' />

      <Tabs variant='solid-rounded' colorScheme='gray' size='md' flex={1}>
        <TabList>
          <Tab>{t('apply-to-all-selected')}</Tab>
          <Tab>{t('customize-delivery-days')}</Tab>
        </TabList>

        <HStack spacing='md' align='start' mt='md'>
          <TabPanels mt='none'>
            <TabPanel p='none'>
              <RegionSettingsApplyAll selectedRegions={getSelectedRegionsData} />
            </TabPanel>
            <TabPanel p='none'>
              <RegionSettingsCustomDays selectedRegions={getSelectedRegionsData} />
            </TabPanel>
          </TabPanels>

          <RegionsList
            regions={regions}
            regionsDataIds={regionsData?.map((r: any) => r.id.toString())}
            value={value as string[]}
            getCheckboxProps={getCheckboxProps}
          />
        </HStack>
      </Tabs>
    </>
  );
};
