import { useEffect } from 'react';
import { useBreakpoint } from 'theme/hooks';
import { gaLogEvent } from 'utils/ga-log-event';
import { DesktopProductsListScreen } from './desktop';
import { MobileProductsListScreen } from './mobile';

export const ProductsListScreen = () => {
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    gaLogEvent('screen_view_products');
  }, []);

  if (isMobile) {
    return <MobileProductsListScreen />;
  }

  return <DesktopProductsListScreen />;
};
