import type { ModalProps } from 'antd';
import { Modal, Switch } from 'antd';
import { InfoIcon } from 'components/notifications-modal/icons/info-icon';
import { useCurrentUser } from 'hooks/use-current-user';
import { useTranslation } from 'react-i18next';

export const NotificationsModal = ({ open, onCancel, ...rest }: Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      {...rest}
      title={
        <div className='modal-header'>
          <div className='modal-title'>{t('communication-preferences')}</div>
          <div className='modal-subtitle'>{t('sub-communication-preferences')}</div>
        </div>
      }>
      <div className='flex flex-col gap-md'>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col items-start'>
            <p className='font-bold'>{t('whatsApp')}</p>
            <p className='whitespace-nowrap'>{user?.phoneNumber}</p>
          </div>

          <Switch />
        </div>

        <div className='flex items-center justify-between'>
          <div className='flex flex-col items-start'>
            <p className='font-bold'>{t('email')}</p>
            <p className='whitespace-nowrap'>{user?.email}</p>
          </div>

          <Switch />
        </div>
      </div>

      <div className='flex justify-start gap-xs mt-lg'>
        <InfoIcon className='w-[50px] mt-[3px]' />
        <p className='text-gray-800 text-sm'>{t('foot-notifications-settings')}</p>
      </div>
    </Modal>
  );
};
