import { Button, Empty, Table, type TableProps } from 'antd';
import { useGetOrdersApi } from 'apis/orders/use-get-orders-api';
import { TextCell } from 'components/text-cell';
import dayjs from 'dayjs';
import { useGetCurrency } from 'hooks/use-get-currency';
import { usePageTableSettings } from 'hooks/use-page-table-settings';
import { t } from 'i18next';
import { MobileOrdersList } from 'pages/orders/components/mobile-orders-list';
import { OrderCodeCell } from 'pages/orders/components/order-code-cell';
import { OrdersActionCell } from 'pages/orders/components/orders-action-cell';
import { ViewOrderItems } from 'pages/orders/components/view-order-items';
import { useEffect, useMemo } from 'react';
import { HiOutlineMinus, HiOutlinePlus } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  addOrdersAction,
  resetSelectedOrdersAction,
  setSelectedOrdersAction,
} from 'redux/orders/orders-list-page-reducer';
import { useBreakpoint } from 'theme/hooks';
import { formatPrice } from 'utils/format-price';
import { Order } from '../../types';

export const TableNewOrders = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useBreakpoint();
  const { currency } = useGetCurrency();
  const { selectedKeys } = useAppSelector((state) => state.ordersListPage);

  const { data: ordersData, isLoading, isFetching } = useGetOrdersApi(['restaurant_placed']);

  const { pagination, scroll, loading } = usePageTableSettings({
    data: ordersData,
    total: ordersData?.total,
    isLoading,
  });

  const { orderItems } = useAppSelector((state) => state.ordersListPage);
  const { new: orders } = orderItems;

  useEffect(() => {
    dispatch(addOrdersAction({ ordersType: 'new', data: ordersData?.data }));
  }, [ordersData]);

  // 👇️ reset all selected orders before you unmount the component 👇️
  useEffect(() => {
    return () => {
      dispatch(resetSelectedOrdersAction());
    };
  }, []);

  const columns: TableProps<Order>['columns'] = [
    {
      title: t('order-code'),
      render: (_, record) => <OrderCodeCell orderId={record?.id} orderCode={record?.code} />,
    },
    {
      title: t('customer-name'),
      render: (_, record) => <TextCell value={record?.restaurant?.name} />,
    },
    {
      title: t('branch-name'),
      render: (_, record) => <TextCell value={record?.restaurant?.subEntity?.name} />,
    },
    {
      title: t('supplier-user'),
      render: (_, record) => <TextCell value={record?.supplier?.user?.name} />,
    },
    {
      title: t('total-order-value'),
      render: (_, record) => <TextCell value={formatPrice(record?.total)} />,
    },
    {
      title: t('delivery-date'),
      render: (_, record) => <TextCell value={record?.deliveryDate} />,
    },
    {
      title: t('creation-date'),
      render: (_, record) => <TextCell value={record?.createdAt} />,
    },
    {
      width: 70,
      render: (_, record) => <OrdersActionCell item={record} />,
    },
    Table.EXPAND_COLUMN,
  ];

  const items = useMemo<Order[]>(
    () =>
      ordersData?.data?.map((item: Order) => {
        return {
          key: item.id,
          ...item,
          total: formatPrice(item?.total ?? 0, currency),
          deliveryDate: dayjs(item?.deliveryDate).format('DD-MM-YYYY'),
          createdAt: dayjs(item?.createdAt).format('DD-MM-YYYY | h:mm A'),
        };
      }),
    [ordersData]
  );

  if (isMobile) {
    return (
      <MobileOrdersList
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders}
        ordersType='new'
        hasNext={ordersData?.hasNext}
        enableSelection
      />
    );
  }

  return (
    <Table
      columns={columns}
      dataSource={items}
      size='middle'
      scroll={scroll}
      loading={loading}
      pagination={pagination}
      rowSelection={{
        selectedRowKeys: selectedKeys,
        onChange: (_, selectedRows: Order[]) => {
          dispatch(setSelectedOrdersAction(selectedRows.map((o) => o.id)));
        },
      }}
      expandable={{
        rowExpandable: () => true,
        expandedRowRender: (record) => <ViewOrderItems items={record.items} />,
        expandIcon: ({ expanded, onExpand, record }) => {
          return (
            <Button
              onClick={(e) => onExpand(record, e)}
              size='middle'
              type={expanded ? 'primary' : 'text'}
              icon={expanded ? <HiOutlineMinus size='14px' /> : <HiOutlinePlus size='14px' />}
            />
          );
        },
      }}
      locale={{
        emptyText: !isLoading ? (
          <Empty className='py-[150px]' description={t('no_orders_description')} />
        ) : (
          <div className='py-[150px]' />
        ),
      }}
    />
  );
};
