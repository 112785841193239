import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='#F0AD4E' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_4842_9289)'>
        <mask id='mask0_4842_9289' maskUnits='userSpaceOnUse' x='0' y='0' width='15' height='15'>
          <path
            d='M7.25001 13.5C8.07092 13.5011 8.88396 13.3399 9.64238 13.0257C10.4008 12.7116 11.0897 12.2506 11.6694 11.6694C12.2506 11.0897 12.7116 10.4008 13.0257 9.64238C13.3399 8.88396 13.5011 8.07092 13.5 7.25001C13.5011 6.42909 13.3399 5.61606 13.0257 4.85764C12.7115 4.09922 12.2506 3.41035 11.6694 2.83063C11.0897 2.24939 10.4008 1.78844 9.64238 1.47428C8.88396 1.16012 8.07092 0.998942 7.25001 1.00001C6.42909 0.998959 5.61606 1.16015 4.85764 1.47431C4.09922 1.78847 3.41035 2.2494 2.83063 2.83063C2.2494 3.41035 1.78847 4.09922 1.47431 4.85764C1.16015 5.61606 0.998959 6.42909 1.00001 7.25001C0.998942 8.07092 1.16012 8.88396 1.47428 9.64238C1.78844 10.4008 2.24939 11.0897 2.83063 11.6694C3.41035 12.2506 4.09922 12.7115 4.85764 13.0257C5.61606 13.3399 6.42909 13.5011 7.25001 13.5Z'
            fill='white'
            stroke='white'
            strokeWidth='1.25'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.25 11.3125C7.4572 11.3125 7.65591 11.2302 7.80243 11.0837C7.94894 10.9372 8.03125 10.7385 8.03125 10.5312C8.03125 10.324 7.94894 10.1253 7.80243 9.97882C7.65591 9.83231 7.4572 9.75 7.25 9.75C7.0428 9.75 6.84409 9.83231 6.69757 9.97882C6.55106 10.1253 6.46875 10.324 6.46875 10.5312C6.46875 10.7385 6.55106 10.9372 6.69757 11.0837C6.84409 11.2302 7.0428 11.3125 7.25 11.3125Z'
            fill='black'
          />
          <path d='M7.25 3.5V8.5' stroke='black' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
        </mask>
        <g mask='url(#mask0_4842_9289)'>
          <path d='M-0.25 -0.25H14.75V14.75H-0.25V-0.25Z' fill='inherit' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_4842_9289'>
          <rect width='14.5' height='14.5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
