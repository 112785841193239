import { createSlice } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  resetRowSelection: boolean;
};

const initialState: SliceState = {
  resetRowSelection: false,
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    enableResetRowSelectionAction(state) {
      state.resetRowSelection = true;
    },
    disableResetRowSelectionAction(state) {
      state.resetRowSelection = false;
    },
  },
});

export const { enableResetRowSelectionAction, disableResetRowSelectionAction } = tableSlice.actions;
export default tableSlice.reducer;
