import { Chip as OChip, extendVariants } from '@nextui-org/react';

export const Chip = extendVariants(OChip, {
  variants: {
    color: {
      default: {
        base: 'bg-default-100 border-none',
        content: 'text-default-500 font-medium',
      },
      success: {
        base: 'bg-success-50 border-none',
        content: 'text-success-500 font-medium',
      },
      danger: {
        base: 'bg-danger-50 border-none',
        content: 'text-danger-500 font-medium',
      },
      warning: {
        base: 'bg-warning-50 border-none',
        content: 'text-warning-500 font-medium',
      },
      sky: {
        base: 'bg-sky-50 border-none',
        dot: 'bg-sky-500',
        content: 'text-sky-500 font-medium',
      },
      primary: {
        base: 'bg-primary-50 border-none',
        content: 'text-primary-500 font-medium',
      },
      secondary: {
        base: 'bg-default-100 border-none',
        content: 'text-secondary-500 font-medium',
      },
    },
  },
});
