import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';
import countries from 'i18n-iso-countries';

// You need to register english language (or any language you need)
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));

type ParseMobileNumber = { countryCode: string; code: string; nationalNumber: string; nameEn: string; nameAr: string };

export function parseMobileNumber(mobileNumber: string): ParseMobileNumber {
  const emptyResult = { countryCode: '', nameEn: '', nameAr: '', code: '', nationalNumber: '' };

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber: PhoneNumber = phoneUtil.parseAndKeepRawInput(mobileNumber);

    if (!phoneUtil.isValidNumber(phoneNumber)) {
      return emptyResult;
    }

    const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber) as string;
    const nameEn = countries.getName(countryCode, 'en') ?? ''; // getting country name in English
    const nameAr = countries.getName(countryCode, 'ar') ?? ''; // getting country name in Arabic
    const code = `+${phoneNumber.getCountryCode()}`;
    const nationalNumber = `${phoneNumber.getNationalNumber()}`;

    return { countryCode, nameEn, nameAr, code, nationalNumber };
  } catch (error) {
    return emptyResult;
  }
}
