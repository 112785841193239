import { Divider, HStack, TabList, TabPanel, TabPanels, Tabs, VStack, WhiteCard } from '@elkaso-app/web-design';
import { Modal } from 'antd';
import { useCurrentUser } from 'hooks/use-current-user';
import { useTranslation } from 'react-i18next';
import { CustomTab } from './custom-tab';
import { ShippingDeliveryIcon } from './icons/shipping-delivery-icon';
import { ShippingDeliveryTab } from './tabs/shipping-delivery-tab';
import { UserDetails } from './user-details';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsModal = ({ isOpen, onClose }: SettingsModalProps) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      centered
      width='90vw'
      title={
        <div className='modal-header'>
          <div className='modal-title'>{t('settings')}</div>
          <div className='modal-subtitle'>{t('settings_sub_title')}</div>
        </div>
      }
      footer={null}>
      <HStack spacing='lg' align='stretch' flex={1}>
        <Tabs orientation='vertical' defaultIndex={0} w='full'>
          <WhiteCard minW='250px'>
            <VStack spacing='md' align='stretch' py='md'>
              <UserDetails name={user?.name} email={user?.email} />

              <Divider />

              <TabList border='none' px='md'>
                <CustomTab icon={ShippingDeliveryIcon}> {t('shipping-and-delivery')}</CustomTab>
              </TabList>
            </VStack>
          </WhiteCard>

          <TabPanels mt='none'>
            <TabPanel>
              <ShippingDeliveryTab />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </HStack>
    </Modal>
  );
};
