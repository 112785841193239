import { message } from 'antd';
import auth from 'apis/config/auth-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

const endpoint = 'ums/v1/auth/sign-check';
export const signInCheckEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type BodyType = {
  email?: string;
  phoneNumber?: string;
};

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useSignInCheckApi(options?: Options): ReturnType {
  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await auth.post(endpoint, body);

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
