import { Heading, Text, VStack, WhiteCard } from '@elkaso-app/web-design';
import { useTranslation } from 'react-i18next';
import { RegionData } from '.';
import { RegionSettingsBox } from './region-settings-box';

interface RegionSettingsCustomDaysProps {
  selectedRegions: RegionData[];
}

export const RegionSettingsCustomDays = ({ selectedRegions }: RegionSettingsCustomDaysProps) => {
  const { t } = useTranslation();

  if (!selectedRegions?.length) {
    return (
      <WhiteCard p='md' textAlign='center'>
        <Heading color='blue.500' size='md'>
          {t('no-region-selected')}
        </Heading>
        <Text mt='sm' color='gray.500'>
          {t('sub-no-region-selected')}
        </Text>
      </WhiteCard>
    );
  }

  return (
    <VStack spacing='md'>
      {selectedRegions?.map((r) => (
        <RegionSettingsBox
          key={r.id}
          regionIds={[r.id]}
          title={r.name}
          minimumOrderValue={r.minimumOrderValue}
          cutOffTime={r.cutOffTime}
          deliverySla={r.deliverySla}
          weekdays={r.weekdays}
        />
      ))}
    </VStack>
  );
};
