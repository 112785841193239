import { MyProfile } from 'components/my-profile';
import { useAuth } from 'hooks/use-auth';
import { HiOutlineMenu } from 'react-icons/hi';
import { useAppDispatch } from 'redux/hooks';
import { toggleDesktopSidebar, toggleTabletSidebar } from 'redux/sidebar/reducer';
import { Button } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';

interface IPageHeader {
  title: string;
}

export const PageHeader = ({ title }: IPageHeader) => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { isGuest } = useAuth();

  const handleToggleSidebar = () => {
    if (isTablet) {
      dispatch(toggleTabletSidebar());
    }

    if (!isTablet) {
      dispatch(toggleDesktopSidebar());
    }
  };

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-sm'>
        {!isGuest && (
          <Button isIconOnly aria-label='sidebar' variant='light' size='md' onClick={handleToggleSidebar}>
            <HiOutlineMenu size='24px' />
          </Button>
        )}
        <h2 className='uppercase text-secondary-500 font-bold text-3xl'>{title}</h2>
      </div>

      <MyProfile />
    </div>
  );
};
