import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ButtonProps,
  Form,
  GetProp,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import { useUpdateProductApi } from 'apis/products/use-update-product-api';
import { getUnitsEndpointIdentifier } from 'apis/use-get-units-api';
import cn from 'classnames';
import { useGetCurrency } from 'hooks/use-get-currency';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlineReply, HiOutlineTrash, HiOutlineUpload } from 'react-icons/hi';
import { gaLogEvent } from 'utils/ga-log-event';
import * as yup from 'yup';
import { Product } from '../type';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type UnitOption = {
  key: number;
  value: string;
};

type StatusOption = {
  key: 'active' | 'inactive';
  value: string;
};

type FormValues = {
  imageUrl?: string;
  nameEn: string;
  nameAr: string;
  brandEn: string;
  brandAr?: string;
  unit: UnitOption;
  price: number;
  sku: string;
  status: StatusOption;
  specs?: string;
};

const schema = yup.object().shape({
  imageUrl: yup.string().nullable(),
  nameEn: yup.string().required('name_required'),
  nameAr: yup.string().required('arabic_name_required'),
  brandEn: yup.string().required('brand_name_required'),
  brandAr: yup
    .string()
    .nullable()
    .matches(/^[\u0600-\u06FF\s0-9]*$/, 'arabic_letters_only'),
  unit: yup.object().shape({ key: yup.number().required(), value: yup.string().required('unit_required') }),
  price: yup.number().required('price_required').typeError('price_number'),
  sku: yup.string().required('sku_required'),
  status: yup.object().shape({ key: yup.string().required(), value: yup.string().required('status_required') }),
  specs: yup.string().nullable().max(500, 'specs_length'),
});

interface EditProductModalProps {
  product: Product;
  isOpen: boolean;
  onClose: () => void;
}

export const EditProductModal = ({ product, isOpen, onClose }: EditProductModalProps) => {
  const { t } = useTranslation();
  const { currency } = useGetCurrency();

  const { data: unitsData } = useGetQueryClientData(getUnitsEndpointIdentifier) ?? {};
  const { mutateAsync: uploadApi, isLoading: isUploading } = useUploadFileApi();
  const { mutate: updateProductApi, isLoading: isLoadingUpdateProduct } = useUpdateProductApi(product.id);

  const unitOptions = useMemo(() => {
    return unitsData?.map((u: any) => {
      return {
        key: u.id,
        value: u.name,
      };
    });
  }, [unitsData]);

  const statusOptions = useMemo(() => {
    return [
      { key: 'active', value: t('active') },
      { key: 'inactive', value: t('inactive') },
    ];
  }, []);

  const defaultValues = useMemo(() => {
    return {
      imageUrl: product.imageUrl || undefined,
      nameEn: product.nameEn || '',
      nameAr: product.nameAr || '',
      brandEn: product.brandEn || '',
      brandAr: product.brandAr || undefined,
      unit: unitsData?.find((u: any) => u.id === product.unit.id)
        ? { key: product.unit.id, value: t(product.unit.name) }
        : undefined, // Make supplier is using one of the supported units
      price: product.defaultPrice || 0,
      sku: product.code || '',
      status: { key: product.status, value: t(product.status) },
      specs: product.specs || undefined,
    };
  }, [product]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [product]);

  const imageUrl = watch('imageUrl');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const values = {
      imageUrl: data.imageUrl || null,
      nameEn: data.nameEn,
      nameAr: data.nameAr,
      brandEn: data.brandEn,
      brandAr: data.brandAr || null,
      defaultUnit: data.unit.value,
      defaultPrice: data.price,
      code: data.sku,
      status: data.status.key,
      specs: data.specs || null,
    };

    const variables = {
      body: values,
    };

    updateProductApi(variables, {
      onSuccess: () => {
        gaLogEvent('action_edit_product');
        _onClose();
      },
    });
  };

  const buttonStyles: ButtonProps = {
    type: 'text',
    className: cn('bg-black bg-opacity-70 hover:!bg-opacity-100 hover:!bg-black disabled:opacity-20'),
    classNames: { icon: 'text-white' },
  };

  const _onClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onUploadImage = (file: UploadFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error(t('file_type'));
      return false;
    }

    const isLt2M = file.size && file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error(t('file_size'));
      return false;
    }

    const formData = new FormData();
    formData.append('file', file as FileType);

    const variables = {
      body: formData,
    };

    uploadApi(variables, {
      onSuccess: (data) => {
        setValue('imageUrl', data.url);
      },
    });

    // Important to prevent default antd auto upload method.
    return false;
  };

  const onResetImage = () => {
    setValue('imageUrl', product.imageUrl);
  };

  const onRemoveImage = () => {
    setValue('imageUrl', undefined);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={_onClose}
      width={800}
      title={
        <div className='modal-header'>
          <div className='modal-title'>{t('edit_product_title')}</div>
          <div className='modal-subtitle'>{t('edit_product_subtitle')}</div>
        </div>
      }
      footer={
        <div className='modal-footer'>
          <Button type='default' onClick={_onClose}>
            {t('cancel')}
          </Button>

          <Button type='primary' onClick={() => handleSubmit(onSubmit)()} loading={isLoadingUpdateProduct}>
            {t('save')}
          </Button>
        </div>
      }>
      <div className='flex flex-col md:flex-row items-center md:items-start gap-xl md:gap-md pb-xs'>
        <div
          className={cn(
            'relative group',
            'w-[200px] rounded-md overflow-hidden',
            'border-1 border-solid border-default-200',
            'bg-white'
          )}>
          <Spin indicator={<LoadingOutlined spin />} spinning={isUploading}>
            <Image
              src={imageUrl}
              alt='product-image'
              preview={false}
              className={cn('inline w-full')}
              fallback='https://placehold.co/600x600?text=img&font=roboto'
            />
          </Spin>

          <div
            className={cn(
              'absolute bottom-0 z-10',
              'px-xs py-xs w-full',
              'flex gap-xs items-center justify-around',
              'bg-gradient-to-t from-black transition-opacity duration-500 opacity-100 md:opacity-0 group-hover:opacity-100'
            )}>
            <Button icon={<HiOutlineTrash />} disabled={!imageUrl} onClick={onRemoveImage} {...buttonStyles} />
            <Button
              icon={<HiOutlineReply />}
              disabled={!product.imageUrl || imageUrl === product.imageUrl}
              onClick={onResetImage}
              {...buttonStyles}
            />
            <Upload beforeUpload={(file) => onUploadImage(file)} multiple={false} maxCount={1} showUploadList={false}>
              <Button icon={<HiOutlineUpload />} {...buttonStyles} />
            </Upload>
          </div>
        </div>

        <Form
          layout='vertical'
          onFinish={handleSubmit(onSubmit)}
          requiredMark={'optional'}
          className='grid grid-cols-2 gap-xs flex-1'>
          <Controller
            control={control}
            name='nameEn'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                required
                label={t('name-en')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='nameAr'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                required
                label={t('name-ar')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='brandEn'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                required
                label={t('brand-en')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='brandAr'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                label={t('brand-ar')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='unit'
            render={({ field, fieldState: { invalid } }) => (
              <Form.Item
                required
                label={t('unit')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(errors.unit?.value?.message as string)}>
                <Select
                  showSearch
                  optionFilterProp='value'
                  options={unitOptions}
                  {...field}
                  onChange={(_, option) => field.onChange(option)}
                />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='price'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                required
                label={t('price')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <InputNumber prefix={currency} {...field} style={{ width: '100%' }} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='sku'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                required
                label={t('sku')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input {...field} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='status'
            render={({ field, fieldState: { invalid } }) => (
              <Form.Item
                required
                label={t('status')}
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(errors.status?.value?.message as string)}>
                <Select options={statusOptions} {...field} onChange={(_, option) => field.onChange(option)} />
              </Form.Item>
            )}
          />
          <Controller
            control={control}
            name='specs'
            render={({ field, fieldState: { invalid, error } }) => (
              <Form.Item
                label={t('specs')}
                className='col-span-2'
                initialValue={field.value}
                validateStatus={invalid ? 'error' : ''}
                help={t(error?.message as string)}>
                <Input.TextArea {...field} maxLength={500} showCount />
              </Form.Item>
            )}
          />
        </Form>
      </div>
    </Modal>
  );
};
