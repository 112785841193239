import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { t } from 'i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetProductsApi } from './use-get-products-api';

const endpoint = 'ums/v1/supplier/catalog/upload';

type TData = Record<string, any>;
type TError = AxiosError;

type TVariables = {
  body: FormData;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useUploadProductsApi(options?: Options): ReturnType {
  const { refetch: refetchGetProducts } = useGetProductsApi({ enabled: false });

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.post(endpoint, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Refetch products
      refetchGetProducts();

      message.success(t('uploaded_successfully'));

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
