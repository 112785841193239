import { DatePicker as ODatePicker, extendVariants } from '@nextui-org/react';

export const DatePicker = extendVariants(ODatePicker, {
  defaultVariants: {
    variant: 'bordered',
    color: 'secondary',
    labelPlacement: 'outside',
    disableAnimation: 'true',
    showMonthAndYearPickers: 'true',
  },
});
