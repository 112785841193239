import { useRejectOrderApi } from 'apis/orders/use-reject-order-api';
import { CustomRadio } from 'components/custom-radio';
import { useDisclosure } from 'hooks/use-disclosure';
import { cloneElement, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  Textarea,
} from 'theme/components';

type RejectionType = 'Pending Payments' | 'Credit Limit Exceeded' | 'other';

interface RejectOrderModalProps {
  trigger: JSX.Element;
  orderId: number;
}

type TInputs = {
  reason: string;
};

const defaultValues = {
  reason: '',
};

export const RejectOrderModal = ({ trigger, orderId }: RejectOrderModalProps) => {
  const { isLoading: isRejectOrderLoading, mutate: rejectOrderApi } = useRejectOrderApi();
  const [selected, setSelected] = useState<RejectionType>('Pending Payments');
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    setError,
    register,
    reset,
    formState: { errors },
  } = useForm<TInputs>({
    defaultValues,
  });

  const onSubmit = (data: TInputs) => {
    if (selected !== 'other') {
      const variables = {
        body: {
          ids: [orderId],
          reason: selected,
        },
      };

      rejectOrderApi(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    if (selected === 'other' && !!data?.reason) {
      const variables = {
        body: {
          ids: [orderId],
          reason: data?.reason,
        },
      };

      rejectOrderApi(variables, {
        onSuccess: () => {
          onClose();
        },
      });
    }

    if (selected === 'other' && !data?.reason)
      setError('reason', {
        message: 'Required.',
      });
  };

  const options = useMemo(() => {
    return [
      { label: t('pending-payments'), value: 'Pending Payments' },
      { label: t('credit-limit-exceeded'), value: 'Credit Limit Exceeded' },
      { label: t('other'), value: 'other' },
    ];
  }, []);

  const _onClose = () => {
    setSelected('Pending Payments');
    reset(defaultValues);
    onClose();
  };

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={_onClose}>
        <ModalContent>
          <ModalHeader>
            <div>
              <h2 className='text-secondary-500 text-lg'>{t('reason-for-rejection')}</h2>
              <p className='text-default-500 text-sm font-normal'>{t('please-specify-the-reason-for-rejection')}</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <form
              className='flex flex-col gap-md items-stretch'
              id='rejectOrderReasonForm'
              onSubmit={handleSubmit(onSubmit)}
              noValidate>
              <RadioGroup value={selected} onValueChange={(val) => setSelected(val as RejectionType)}>
                {options.map(({ value, label }) => {
                  return (
                    <CustomRadio key={value} value={value}>
                      {label}
                    </CustomRadio>
                  );
                })}
              </RadioGroup>

              <Textarea
                label=''
                placeholder={t('type-here') ?? ''}
                isDisabled={selected !== 'other'}
                isInvalid={!!errors.reason}
                errorMessage={errors.reason?.message}
                {...register('reason')}
              />
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={_onClose} variant='light'>
              {t('cancel')}
            </Button>

            <Button
              isDisabled={!selected}
              color='danger'
              type='submit'
              form='rejectOrderReasonForm'
              isLoading={isRejectOrderLoading}>
              {t('reject-order')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
