import { OnboardingForm } from 'components/onboarding-form';
import { usePageParams } from 'hooks/use-page-params';
import { Layout, Section } from 'layout';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineMailOutline } from 'react-icons/md';
import { Button, Divider } from 'theme/components';
import { URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';
import { LoginEmailForm } from './login-email-form';
import { LoginPhoneNumberForm } from './login-phone-number-form';

export type LoginType = 'email' | 'phoneNumber';

const LoginPage = () => {
  const [loginType, setLoginType] = useState<LoginType>('phoneNumber');
  const { t } = useTranslation();

  const { getPageParams } = usePageParams();
  const params = getPageParams({ parseNumbers: false, decode: false });

  // Fire GA event to count how many users redirected from banner.
  useEffect(() => {
    if (params?.redirectFrom === 'email') {
      gaLogEvent('banner_click_login', {
        origin: 'email',
      });
    }

    if (params?.redirectFrom === 'external-supplier-portal') {
      gaLogEvent('banner_click_login', {
        origin: 'external-supplier-portal',
      });
    }
  }, [params?.redirectFrom]);

  useEffect(() => {
    if (params?.loginType !== 'phoneNumber' && params?.loginType !== 'email') return;
    setLoginType(params?.loginType);
  }, [params?.loginType, params?.loginValue]);

  const handleChangeLoginType = () => {
    if (loginType === 'email') setLoginType('phoneNumber');
    else setLoginType('email');
  };

  const isPhoneNumber = useMemo(() => {
    return loginType === 'phoneNumber';
  }, [loginType]);

  const isEmail = useMemo(() => {
    return loginType === 'email';
  }, [loginType]);

  return (
    <Layout type='auth_page_layout' customPrevUrl={URL.GET_STARTED} showMobileHeader>
      <Section name='content'>
        <OnboardingForm>
          <OnboardingForm.Title>{t('lets-get-started')}</OnboardingForm.Title>

          <OnboardingForm.Subtitle>
            {t('login-page-sub-title', { loginType: isEmail ? t('lc-email') : t('lc-mobile-number') })}
          </OnboardingForm.Subtitle>

          <LoginPhoneNumberForm className={`${isPhoneNumber ? 'block' : 'hidden'} mt-8`} />

          <LoginEmailForm className={`${isEmail ? 'block' : 'hidden'} mt-8`} />

          <Divider className='my-lg' />

          <Button
            onClick={handleChangeLoginType}
            size='lg'
            variant='faded'
            fullWidth
            startContent={<MdOutlineMailOutline size='25px' />}
            className='bg-white justify-start ps-sm'
            color='secondary'>
            {t('login-type-switch-button', { loginType: isPhoneNumber ? t('email') : t('phone-number') })}
          </Button>
        </OnboardingForm>
      </Section>
    </Layout>
  );
};

export default LoginPage;
