import { InputProps } from '@nextui-org/react';
import { FiChevronDown } from 'react-icons/fi';
import { Divider, Input } from 'theme/components';
import { CountryType } from '../types';
import { CountryFlagIcon } from './country-flag-icon';

interface CountryCodeInputProps extends InputProps {
  onOpen: () => void;
  selectedCountry: CountryType;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  phoneNumber: string;
}

export const PhoneNumberInput = ({
  onOpen,
  selectedCountry,
  handlePhoneNumberChange,
  phoneNumber,
  ...inputProps
}: CountryCodeInputProps) => {
  return (
    <Input
      type='tel'
      onChange={handlePhoneNumberChange}
      maxLength={9}
      startContent={
        <div className='flex items-center cursor-pointer gap-2' onClick={onOpen}>
          <CountryFlagIcon countryCode={selectedCountry?.countryCode} />
          <p className='text-base'>{selectedCountry?.code}</p>
          <FiChevronDown />
          <Divider orientation='vertical' className='h-[24px] me-2' />
        </div>
      }
      {...inputProps}
      value={phoneNumber}
    />
  );
};
