import { analytics } from 'configs/firebase';
import { AnalyticsCallOptions, EventParams, logEvent } from 'firebase/analytics';

enum EventName {
  ACCEPT_ORDER = 'ACCEPT_ORDER',
  action_send_otp = 'action_send_otp',
  action_resend_otp = 'action_resend_otp',
  action_otp_outcome = 'action_otp_outcome',
  banner_click_login = 'banner_click_login',
  banner_click_book_demo = 'banner_click_book_demo',
  email_click_modify_sendlpo = 'email_click_modify_sendlpo',
  email_click_modify_downloadlpo = 'email_click_modify_downloadlpo',
  email_click_confirm_downloadlpo = 'email_click_confirm_downloadlpo',
  email_click_modify_reject = 'email_click_modify_reject',
  email_click_modify_confirm = 'email_click_modify_confirm',
  email_click_modify_edit = 'email_click_modify_edit',
  email_click_accept = 'email_click_accept',
  email_click_modify = 'email_click_modify',
  action_aggregate_popup = 'action_aggregate_popup',
  action_export_aggregate_orders = 'action_export_aggregate_orders',
  action_ext_sp_click_modify_confirm = 'action_ext_sp_click_modify_confirm',
  action_ext_sp_click_modify_reject = 'action_ext_sp_click_modify_reject',
  action_ext_sp_click_modify_edit = 'action_ext_sp_click_modify_edit',
  action_ext_sp_click_accept = 'action_ext_sp_click_accept',
  action_ext_sp_click_modify = 'action_ext_sp_click_modify',
  screen_guest_view = 'screen_guest_view',
  action_click_login_ext = 'action_click_login_ext',
  action_successful_guest_login = 'action_successful_guest_login',
  screen_view_products = 'screen_view_catalogue',
  action_download_products_file = 'action_download_catalogue_file',
  action_upload_products_file = 'action_upload_catalogue_file',
  action_upload_products_failed = 'action_upload_products_failed',
  action_edit_product = 'action_edit_product',
}

export const gaLogEvent = (
  eventName: keyof typeof EventName,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions
) => {
  if (!analytics) return;
  logEvent(analytics, eventName, eventParams, options);
};
