import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input } from 'antd';
import { useSendOTPApi } from 'apis/auth/use-send-otp-api';
import { useValidateOTPApi } from 'apis/auth/use-validate-otp-api';
import { OnboardingForm } from 'components/onboarding-form';
import { useAuth } from 'hooks/use-auth';
import { usePageParams } from 'hooks/use-page-params';
import { Layout, Section } from 'layout';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageKeys, SessionStorageKeys, URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';
import { handleRetryAttempts } from 'utils/handle-retry-attempts';
import { hashPortionOfString } from 'utils/hash-portion-of-string';
import { SessionHandler } from 'utils/session-handler';
import * as yup from 'yup';
import { ResendCodeButton } from './components/resend-code-button';

type FormValues = {
  otp: string;
};

const schema = yup.object().shape({
  otp: yup
    .string()
    .length(4, 'OTP code must be exactly 4 digits.')
    .matches(/^\d+$/, 'OTP code must be numbers only.')
    .required('OTP code is required.'),
});

const defaultValues: FormValues = {
  otp: '',
};

const getLoginBody = (loginType: string, loginValue: string) => {
  if (loginType === 'phoneNumber' || loginType === 'email') return { [loginType]: loginValue };
  return {};
};

const VerifyCode = () => {
  const sessionHandler = new SessionHandler(SessionStorageKeys.otp_expiration_time);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isGuest } = useAuth();

  const { isLoading: isLoadingSendOTP, mutate: sendOTP } = useSendOTPApi();
  const { isLoading: isLoadingValidateOTP, mutateAsync: validateOTP } = useValidateOTPApi();

  const { getPageParams } = usePageParams();
  const { loginType, loginValue } = getPageParams({ parseNumbers: false, decode: false });

  const { getRetryAttempts, increaseRetryAttempts, resetRetryAttempts } = handleRetryAttempts();

  const onSendOTP = () => {
    const otpBody = getLoginBody(loginType as string, loginValue as string);
    const variables = { body: otpBody };

    sendOTP(variables, {
      onSuccess(data) {
        sessionHandler.setSession(data?.secondsRemaining);

        increaseRetryAttempts();

        gaLogEvent('action_resend_otp', {
          login_credentials: loginValue,
          retry_attempts: getRetryAttempts(),
        });
      },
      onError(error) {
        // CASE [1]: Trying to send another OTP while the remaining time still counting.
        if (error.response?.status === 403) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          return;
        }

        // CASE [2]: Locked for one hour due to many attempts incorrect OTPs.
        if (error.response?.status === 429) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          navigate(URL.LOGIN + `?loginType=${loginType}&loginValue=${loginValue}`);
          return;
        }

        // CASE [4]: Any other error.
        if (error.response) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          navigate(URL.LOGIN + `?loginType=${loginType}&loginValue=${loginValue}`);
          return;
        }
      },
    });
  };

  const { handleSubmit, control, reset } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const otpVerifyBody = { otp: data.otp, ...getLoginBody(loginType as string, loginValue as string) };

    validateOTP(
      { body: otpVerifyBody },
      {
        onSuccess: ({ accessToken, refreshToken }) => {
          // Set new tokens
          localStorage.setItem(LocalStorageKeys.access_token, accessToken);
          localStorage.setItem(LocalStorageKeys.refresh_token, refreshToken);

          resetRetryAttempts();

          gaLogEvent('action_otp_outcome', {
            outcome: 'success',
          });

          const guestOrderURL = localStorage.getItem(LocalStorageKeys.guest_order_url);

          if (isGuest && guestOrderURL) {
            // Send GA event to record successful guest login
            gaLogEvent('action_successful_guest_login', {
              outcome: 'success',
            });

            // Handle guest redirection
            window.location.href = guestOrderURL;

            // Make sure guest flag and order url is removed
            localStorage.removeItem(LocalStorageKeys.is_guest_token);
            localStorage.removeItem(LocalStorageKeys.guest_order_url);
            return;
          }

          // Handle redirection
          window.location.href = URL.ORDERS;
        },
        onError: () => {
          reset(defaultValues);

          gaLogEvent('action_otp_outcome', {
            outcome: 'failure',
          });
        },
      }
    );
  };

  const _onChange = (value: string, callback: any) => {
    const syntheticEvent = {
      target: {
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    callback?.(syntheticEvent);

    if (value.length === 4) {
      handleSubmit(onSubmit)();
    }
  };

  const getTitleLoginType = () => {
    if (loginType === 'email') return 'email_address';
    else return 'phone_number';
  };

  const getSubtitleLoginType = () => {
    if (loginType === 'email') return 'email';
    else return 'SMS';
  };

  if (!loginType || !loginValue) {
    return <Navigate to={URL.GET_STARTED} />;
  }

  return (
    <Layout type='auth_page_layout' customPrevUrl={URL.LOGIN + location.search} showMobileHeader>
      <Section name='content'>
        <OnboardingForm>
          <OnboardingForm.Title>{t('verify_login_type', { loginType: t(getTitleLoginType()) })}</OnboardingForm.Title>
          <OnboardingForm.Subtitle>
            {t('otp_receiver_type', { otpReceiverType: t(getSubtitleLoginType()) })}
          </OnboardingForm.Subtitle>

          <p className='text-secondary-500 font-bold mt-8 text-lg'>
            {hashPortionOfString(loginValue.toString(), 'center', 0.4)}
          </p>

          <Form size='large' className='mt-8'>
            <Controller
              control={control}
              name='otp'
              render={({ field: { onChange, value, name }, fieldState: { error, invalid } }) => (
                <Form.Item
                  name={name}
                  valuePropName={value}
                  validateStatus={invalid ? 'error' : ''}
                  help={t(error?.message as string)}>
                  <Input.OTP
                    length={4}
                    value={value}
                    onChange={(val) => _onChange(val, onChange)}
                    disabled={isLoadingSendOTP || isLoadingValidateOTP}
                    autoFocus
                  />
                </Form.Item>
              )}
            />
          </Form>

          <ResendCodeButton
            onResend={onSendOTP}
            isLoading={isLoadingSendOTP}
            secondsRemaining={sessionHandler.remainingTime()}
          />
        </OnboardingForm>
      </Section>
    </Layout>
  );
};

export default VerifyCode;
