import { useDisclosure } from 'hooks/use-disclosure';
import { InfoIcon } from 'pages/orders/icons/info-icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil } from 'react-icons/hi';
import { useAppSelector } from 'redux/hooks';
import { ClonedOrderDetailsItem } from 'redux/orders/order-details-page-reducer';
import { Button, Chip } from 'theme/components';
import { formatPrice } from 'utils/format-price';
import { InitialPriceModal } from './initial-price-modal';
import { ModifyItemModal } from './modify-item-modal';

interface OrderDetailsItemProps {
  item: ClonedOrderDetailsItem;
  currency: string;
  status: string;
}

export const OrderDetailsItem = ({ item, currency, status }: OrderDetailsItemProps) => {
  const { t } = useTranslation();
  const { isModifyItemsEnabled } = useAppSelector((state) => state.orderDetailsPage);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isReceivedOrder = useMemo(() => {
    return status === 'received';
  }, [status]);

  const isOutOfStock = useMemo(() => {
    return item.quantity === 0;
  }, [item]);

  const isModified = useMemo(() => {
    return item.modified?.length;
  }, [item]);

  const initialPrice = useMemo(() => {
    const _item = item?.history?.find((i: any) => i.status === 'restaurant_placed');
    if (!_item) return item?.price;
    return _item?.price;
  }, [item]);

  const isPriceChanged = useMemo(() => {
    const _item = item?.history?.find((i: any) => i.status === 'restaurant_placed');
    if (!_item) return false;
    return _item?.price !== item?.price && isReceivedOrder;
  }, []);

  return (
    <>
      <div className='flex-1 flex justify-between border-b-1 py-xs'>
        <div className='flex-1 flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <p className='font-bold text-secondary-500 flex-1'>{item?.name}</p>

            {!isModifyItemsEnabled && (
              <>
                <p className='ps-md pe-2xl text-secondary-500 font-bold text-sm'>x{item?.quantity}</p>
                <p className='text-secondary-500 font-bold text-sm'>{formatPrice(item?.total, currency)}</p>
              </>
            )}
          </div>

          <div className='flex gap-xs items-center text-xs text-default-500'>
            <div>{item?.code}</div>

            <div className='bg-default-300 rounded-full w-[7px] h-[7px]' />

            {!isPriceChanged && <div>{formatPrice(item?.price, `${currency}/${item?.unit}`)}</div>}

            {isPriceChanged && (
              <div>
                <InitialPriceModal
                  itemName={item?.name}
                  initialPrice={initialPrice}
                  receivedPrice={item?.price}
                  trigger={
                    <div className='flex items-center cursor-pointer'>
                      <InfoIcon className='cursor-pointer fill-[#00BCF2] mt-[-1px]' />
                      <p className='text-[#00bcf2]'>{formatPrice(initialPrice, `${currency}/${item?.unit}`)}</p>
                    </div>
                  }
                />
              </div>
            )}
          </div>

          <div className='flex items-center gap-xs'>
            {isOutOfStock && (
              <div className='mt-xs'>
                <Chip variant='dot' color='danger' size='sm' className='capitalize'>
                  {t('out_of_stock')}
                </Chip>
              </div>
            )}

            {!!isModified && (
              <div className='mt-xs'>
                <Chip variant='dot' color='warning' size='sm' className='capitalize'>
                  {t('modified')}
                </Chip>
              </div>
            )}
          </div>
        </div>

        {isModifyItemsEnabled && (
          <Button isIconOnly variant='light' size='sm' onClick={onOpen}>
            <HiOutlinePencil size='18' />
          </Button>
        )}
      </div>

      <ModifyItemModal item={item} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
