import { Box, Text, TextProps, Tooltip } from '@chakra-ui/react';
import React from 'react';

export interface TruncateTextProps extends TextProps {
  text: string;
  width: string;
}

export const TruncateText: React.FC<TruncateTextProps> = ({ text, width, ...props }) => {
  return (
    <Tooltip label={text} fontSize='md'>
      <Box width={width} isTruncated>
        <Text {...props} isTruncated>
          {text}
        </Text>
      </Box>
    </Tooltip>
  );
};
