import type { TableColumnsType, TableProps } from 'antd';
import { Button, Empty, Image, Input, Modal, Table } from 'antd';
import { getRecommendedProductsEndpointIdentifier } from 'apis/marketing/use-get-recommended-products-api';
import { useUpdateRecommendedProductsApi } from 'apis/marketing/use-update-recommended-products-api';
import { useGetProductsApi } from 'apis/products/use-get-products-api';
import { TextCell } from 'components/text-cell';
import { useGetCurrency } from 'hooks/use-get-currency';
import { useGetQueryClientData } from 'hooks/use-get-query-client-data';
import _debounce from 'lodash/debounce';
import _without from 'lodash/without';
import { Product } from 'pages/products/type';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineSearch } from 'react-icons/hi';
import { formatPrice } from 'utils/format-price';
import { RecommendedProduct } from '../type';

interface SelectRecommendedProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectRecommendedProductsModal = ({ isOpen, onClose }: SelectRecommendedProductsModalProps) => {
  const { t } = useTranslation();
  const { currency } = useGetCurrency();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const recommendedProductsData = useGetQueryClientData(getRecommendedProductsEndpointIdentifier);

  // Set default selected
  useEffect(() => {
    setSelectedRowKeys(recommendedProductsData?.map((i: RecommendedProduct) => i.productId));
  }, [recommendedProductsData]);

  const [searchText, setSearchText] = useState<string>('');

  const {
    data: productsData,
    isLoading: isLoadingGetProducts,
    refetch: refetchGetProducts,
  } = useGetProductsApi({}, { search: searchText, sort: 'recommended.asc' });

  const { mutate: updateRecommendedProducts, isLoading: isLoadingUpdateProducts } = useUpdateRecommendedProductsApi();

  const debouncedFetchData = useCallback(_debounce(refetchGetProducts, 500), []);

  useEffect(() => {
    debouncedFetchData();
  }, [searchText]);

  const rowSelection: TableProps['rowSelection'] = {
    selectedRowKeys,
    onSelect: (record, selected) => {
      // Add item
      if (selected && selectedRowKeys.length + 1 <= 10) {
        setSelectedRowKeys((prev) => [...prev, record.key]);
      }

      // Remove item
      if (!selected) {
        setSelectedRowKeys((prev) => _without(prev, record.key));
      }
    },
    getCheckboxProps: (record: Product) => ({
      disabled: selectedRowKeys.length >= 10 && !selectedRowKeys.includes(record.id), // Disable checkbox only if it's not selected and 10 are already selected
    }),
  };

  const _onClose = () => {
    onClose();

    // Make sure to refetch the products again to re-sort them
    refetchGetProducts();

    // Reset search input
    setSearchText('');
  };

  const onUpdateProducts = () => {
    const variables = {
      body: {
        productIds: selectedRowKeys.map((i) => Number(i)),
      },
    };

    updateRecommendedProducts(variables, {
      onSuccess: () => {
        _onClose();
      },
    });
  };

  const columns: TableColumnsType<Product> = [
    {
      dataIndex: 'imageUrl',
      render: (val) => (
        <Image
          width={35}
          height={35}
          src={val}
          preview={false}
          className='rounded-md'
          fallback='https://placehold.co/600?text=img&font=roboto'
        />
      ),
    },
    {
      title: t('name'),
      dataIndex: 'nameEn',
      render: (val) => <TextCell value={val} minWidth={280} />,
    },
    {
      title: t('unit'),
      dataIndex: ['unit', 'name'],
      render: (val) => <TextCell value={t(val)} minWidth={30} />,
    },
    {
      title: t('price'),
      dataIndex: 'defaultPrice',
      render: (val) => <TextCell value={val} minWidth={80} />,
    },
  ];

  const items = useMemo<Product[]>(
    () =>
      productsData?.data?.map((item: Product) => {
        return {
          key: item.id,
          ...item,
          defaultPrice: formatPrice(item.defaultPrice, currency),
        };
      }),
    [productsData]
  );

  return (
    <Modal
      open={isOpen}
      onCancel={_onClose}
      width={700}
      title={
        <div className='modal-header'>
          <div className='modal-title'>{t('select_products')}</div>
          <div className='modal-subtitle'>{t('select_up_to_10_products')}</div>
        </div>
      }
      footer={
        <div className='modal-footer'>
          <Button type='default' onClick={_onClose}>
            {t('cancel')}
          </Button>

          <Button type='primary' onClick={onUpdateProducts} loading={isLoadingUpdateProducts}>
            {t('save')}
          </Button>
        </div>
      }>
      <div className='flex flex-col gap-md'>
        <Input
          placeholder={t('type_product_name') as string}
          size='large'
          value={searchText}
          prefix={<HiOutlineSearch className='stroke-default-400' />}
          onChange={(e) => setSearchText(e.currentTarget.value)}
          allowClear
        />

        <Table
          columns={columns}
          dataSource={items}
          pagination={false}
          rowSelection={rowSelection}
          size='middle'
          locale={{
            emptyText: !isLoadingGetProducts ? (
              <Empty className='py-[150px]' description={t('no_products_description')} />
            ) : (
              <div className='py-[150px]' />
            ),
          }}
        />
      </div>
    </Modal>
  );
};
