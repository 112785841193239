import { Icon, IconProps } from '@elkaso-app/web-design';

export const FacebookIcon = (props: IconProps) => {
  return (
    <Icon width='20px' height='20px' viewBox='0 0 16 16' fill='white' {...props}>
      <path
        d='M2.9215 0C1.30312 0 0 1.30312 0 2.9215V13.0785C0 14.6969 1.30312 16 2.9215 16H8.42651V9.74499H6.77251V7.49299H8.42651V5.569C8.42651 4.0574 9.40378 2.6695 11.655 2.6695C12.5665 2.6695 13.2405 2.757 13.2405 2.757L13.1875 4.86001C13.1875 4.86001 12.5001 4.85351 11.75 4.85351C10.9382 4.85351 10.808 5.22756 10.808 5.84852V7.49301H13.252L13.1455 9.745H10.808V16H13.0785C14.6969 16 16 14.6969 16 13.0785V2.92151C16 1.30313 14.6969 1.6e-05 13.0785 1.6e-05H2.92149L2.9215 0Z'
        fill='inherit'
      />
    </Icon>
  );
};
