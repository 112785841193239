import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'antd/node_modules/dayjs';
import { CustomRadio } from 'components/custom-radio';
import { OrdersFilterSectionProps } from 'pages/orders/components/orders-filter-modal';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from 'theme/components';
import * as yup from 'yup';

type DateSlug = 'today' | 'last7days' | 'last30days' | 'custom';

const dateMap: Record<DateSlug, { slug: DateSlug; value: string }> = {
  today: {
    slug: 'today',
    value: dayjs().format('YYYY-MM-DD'),
  },
  last7days: {
    slug: 'last7days',
    value: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
  },
  last30days: {
    slug: 'last30days',
    value: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
  },
  custom: {
    slug: 'custom',
    value: 'custom',
  },
};

interface FormValues {
  dateRange: Dayjs[] | null | undefined;
}

const schema = yup.object().shape({
  dateRange: yup
    .array()
    .of(yup.date().nullable().required('Date is required'))
    .length(2, 'Please select both start and end date')
    .nullable()
    .notRequired()
    .test('is-after', 'End date must be after start date', (dates: Dayjs[]) => {
      if (!dates || dates.length < 2) {
        return true; // If date range is not provided, the validation passes
      }

      return dayjs(dates?.[1]).isAfter(dayjs(dates?.[0]));
    }),
});

export const DateFilter = ({ filter, setFilter }: OrdersFilterSectionProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');

  const setDefaultFormValues = () => {
    const defaultValues: FormValues = { dateRange: [] };

    if (dayjs(filter.from).isValid() && dayjs(filter.to).isValid()) {
      defaultValues.dateRange = [dayjs(filter.from), dayjs(filter.to)];
    }

    return defaultValues;
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: setDefaultFormValues(),
  });

  const dateRange = watch('dateRange');

  const setDefaultOption = (): string => {
    if (filter.to) return 'custom';

    return Object.values(dateMap).reduce((accValue, curValue) => {
      if (curValue.value === filter.from) return curValue.slug;
      return accValue;
    }, '');
  };

  useEffect(() => {
    setSelected(setDefaultOption());
  }, []);

  const updateFilter = (from: string, to: string) => {
    setFilter((prev) => {
      return {
        ...prev,
        from,
        to,
      };
    });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    // On clear date range field
    if (!data.dateRange) {
      updateFilter('', '');
    }

    // On select valid date range
    if (data.dateRange?.length === 2) {
      const from = dayjs(data.dateRange?.[0]).format('YYYY-MM-DD');
      const to = dayjs(data.dateRange?.[1]).format('YYYY-MM-DD');

      updateFilter(from, to);
    }
  };

  useEffect(() => {
    updateFilter('', '');

    // Update date field except custom field
    if (selected in dateMap && selected !== 'custom') {
      updateFilter(dateMap[selected as DateSlug].value, '');
    }

    // Update custom field
    if (selected in dateMap && selected === 'custom' && isValid) {
      handleSubmit(onSubmit)();
    }
  }, [selected, isValid, dateRange]);

  const options = useMemo(() => {
    return [
      { label: t('today'), value: dateMap.today.slug },
      { label: t('last-7-days'), value: dateMap.last7days.slug },
      { label: t('last-30-days'), value: dateMap.last30days.slug },
      { label: t('custom'), value: dateMap.custom.slug },
    ];
  }, []);

  return (
    <Suspense>
      <RadioGroup value={selected} onValueChange={setSelected}>
        {options.map(({ value, label }) => {
          return (
            <CustomRadio key={value} value={value}>
              {label}
            </CustomRadio>
          );
        })}
      </RadioGroup>

      {selected === 'custom' && (
        <Form autoComplete='off' size='large' className='mt-3xs px-2'>
          <Controller
            control={control}
            name='dateRange'
            render={({ field: { onBlur, onChange, name, value }, fieldState: { error, invalid } }) => (
              <Form.Item name={name} validateStatus={invalid ? 'error' : ''} help={error?.message}>
                <DatePicker.RangePicker
                  defaultValue={[value?.[0], value?.[1]]}
                  value={[value?.[0], value?.[1]]}
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={[t('start_date'), t('end_date')]}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
          />
        </Form>
      )}
    </Suspense>
  );
};
