export const Logo = () => {
  return (
    <svg width='300' height='59' viewBox='0 0 300 59' fill='#E53E51'>
      <path
        d='M43.6058 31.648C54.4636 21.2121 59.8821 7.35208 59.8821 3.15747C59.8896 2.96496 59.8586 2.77289 59.791 2.59248C59.7234 2.41207 59.6205 2.24694 59.4884 2.10671C59.3563 1.96649 59.1976 1.85398 59.0215 1.77577C58.8455 1.69756 58.6556 1.65521 58.463 1.65118H45.9105C45.4867 1.65329 45.0761 1.79884 44.7456 2.06414C44.4151 2.32943 44.1842 2.69883 44.0904 3.11214C42.082 11.606 32.8037 30.2917 14.8712 30.2917C14.655 30.2917 14.5225 30.2917 14.5225 30.0546V3.58286C14.5225 3.07517 14.3208 2.58828 13.9618 2.22928C13.6028 1.87029 13.1159 1.66861 12.6082 1.66861H1.91425C1.40656 1.66861 0.919662 1.87029 0.560671 2.22928C0.20168 2.58828 3.17596e-06 3.07517 3.17596e-06 3.58286V55.0688C-0.00045525 55.3204 0.0487186 55.5697 0.144713 55.8024C0.240708 56.035 0.381637 56.2465 0.559437 56.4246C0.737237 56.6027 0.948419 56.744 1.18089 56.8405C1.41337 56.9369 1.66257 56.9865 1.91425 56.9865H12.6117C12.8634 56.9865 13.1126 56.9369 13.3451 56.8405C13.5775 56.744 13.7887 56.6027 13.9665 56.4246C14.1443 56.2465 14.2853 56.035 14.3812 55.8024C14.4772 55.5697 14.5264 55.3204 14.526 55.0688V44.0749C20.9533 44.0813 27.282 42.4952 32.9467 39.4584C37.6538 43.3462 41.3986 49.1064 44.5507 55.522C44.9342 56.3066 45.4956 56.9865 46.3708 56.9865H58.9023C59.7217 56.9865 60.5655 56.2612 60.3249 55.4802C56.615 43.4438 49.7077 36.5295 43.6058 31.648Z'
        fill='inherit'
      />
      <path
        d='M112.428 2.47853C112.312 2.32693 112.178 2.19097 112.027 2.07406C111.427 1.62426 110.611 1.61032 109.858 1.61381L97.3755 1.68005C96.3294 1.68005 95.2834 1.62426 94.2583 1.68005C93.3935 1.74281 93.2889 2.27978 92.9263 3.02247C92.0023 4.89836 67.9121 54.0446 67.8389 54.1946C67.4414 55.0105 67.1101 56.1506 67.8389 56.6876C68.1953 56.8943 68.6073 56.9845 69.0174 56.9456L80.9422 57.0119C81.6346 57.0661 82.3303 56.9649 82.9785 56.7155C85.1055 55.6974 84.7951 51.2064 86.8698 50.0906C87.6857 49.6548 89.4674 49.8291 90.3914 49.8884C99.0212 50.4497 106.957 49.585 114.771 45.8786C115.15 45.6382 115.604 45.544 116.047 45.6136C116.514 45.7531 116.783 46.2307 117.003 46.6596L121.459 55.537C121.6 56.0283 121.92 56.4493 122.355 56.7176C122.79 56.9858 123.31 57.0821 123.812 56.9875L136.55 56.9561C137.121 56.9561 137.77 56.9177 138.133 56.4784C138.61 55.9031 138.3 55.0384 137.969 54.3689C129.632 37.4057 112.641 2.75747 112.428 2.47853ZM110.594 33.1902C112.285 36.8687 99.8685 37.9287 95.0707 37.6986C94.2653 37.6602 93.2087 36.7188 94.1153 34.9091C95.8971 31.3456 99.5861 23.7898 101.532 19.7974C101.648 19.5597 101.829 19.3595 102.054 19.2195C102.279 19.0795 102.538 19.0053 102.803 19.0053C103.067 19.0053 103.327 19.0795 103.551 19.2195C103.776 19.3595 103.957 19.5597 104.074 19.7974C106.002 23.6991 109.537 30.8958 110.594 33.1902V33.1902Z'
        fill='inherit'
      />
      <path
        d='M279.079 1.27834H239.4C224.609 1.27834 216 9.78958 216 29.3191C216 48.8486 224.609 57.3563 239.4 57.3563H279.079C291.743 57.3563 300 46.8088 300 28.7647C300 10.7206 291.879 1.27834 279.079 1.27834ZM276.45 42.4434H242.032C232.618 42.4434 230.264 39.11 230.264 29.3121C230.264 19.5142 234.999 16.1913 242.032 16.1913H276.45C281.558 16.1913 286.562 18.8308 286.562 29.3191C286.562 39.8073 278.72 42.4434 276.45 42.4434Z'
        fill='inherit'
      />
      <path
        d='M207.597 31.5058C207.534 31.3977 207.468 31.2896 207.401 31.185C204.779 27.0322 199.776 25.0622 194.967 24.058C185.204 22.0112 175.047 22.8725 165.124 21.7288C163.862 21.5824 162.561 21.3801 161.466 20.7455C160.372 20.1109 159.514 18.8906 159.657 17.6249C159.901 15.4387 162.607 14.6088 164.793 14.3612C171.943 13.5626 179.162 13.6047 186.303 14.4868C187.551 14.6437 188.859 14.8354 189.856 15.6165C191.25 16.6939 191.843 18.7302 193.482 19.3683C194.626 19.8111 195.923 19.4136 197.049 18.9499C200.518 17.5405 203.721 15.5487 206.519 13.0607C206.844 12.8154 207.1 12.4898 207.262 12.1157C207.366 11.647 207.313 11.1569 207.112 10.721C206.066 7.9316 203.838 5.72795 201.261 4.2635C198.684 2.79905 195.773 2.0215 192.865 1.44966C185.257 -0.042683 177.443 -0.196103 169.702 0.163037C166.001 0.2856 162.314 0.678326 158.67 1.33808C156.849 1.68757 155.054 2.16054 153.297 2.75372C151.721 3.29068 149.904 3.86949 148.625 4.93993C146.682 6.56478 145.173 8.221 144.161 10.5885C143.205 12.8924 142.708 15.3606 142.697 17.855C142.662 20.7176 143.15 23.6675 144.681 26.0838C146.958 29.6857 151.166 31.5755 155.295 32.6285C165.406 35.2087 176.069 33.9326 186.4 35.3343C187.851 35.5295 189.336 35.7945 190.585 36.5651C191.833 37.3357 192.788 38.7339 192.638 40.1914C192.502 41.4919 191.512 42.5798 190.347 43.176C189.183 43.7723 187.858 43.9641 186.561 44.1175C179.43 44.9613 172.188 45.0519 165.093 43.9676C163.157 43.6712 161.079 43.2005 159.803 41.7151C159.353 41.1921 158.973 40.5226 158.314 40.3204C157.655 40.1181 157.031 40.4459 156.452 40.7458L145.577 46.3944C145.183 46.5582 144.837 46.8198 144.573 47.1545C144.329 47.6129 144.258 48.144 144.374 48.6503C144.834 51.8965 147.432 54.5256 150.41 55.8993C153.387 57.2731 156.728 57.6114 159.995 57.8868C172.038 58.8945 184.34 59.3269 196.034 56.2829C200.218 55.195 204.546 53.4691 206.979 49.9056C208.583 47.5589 209.176 44.6754 209.399 41.8302C209.675 38.298 209.385 34.5567 207.597 31.5058Z'
        fill='inherit'
      />
    </svg>
  );
};
