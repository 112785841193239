import { useEffect, useState } from 'react';

export function useViewportHeight() {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight + 'px');

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight + 'px');
    };

    // Adjust the height on initial load and on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewportHeight;
}
