import { useGetRecommendedProductsApi } from 'apis/marketing/use-get-recommended-products-api';
import { FullPageSpinner } from 'components/full-page-spinner';
import { Layout, Section } from 'layout';
import { RecommendationsPreview } from 'pages/marketing/components/recommendations-preview';
import { RecommendedList } from 'pages/marketing/components/recommended-list';
import { useTranslation } from 'react-i18next';

export const DesktopRecommendationsScreen = () => {
  const { t } = useTranslation();
  const { data: recommendedProductsData, isLoading: isLoadingRecommendedProducts } = useGetRecommendedProductsApi();

  if (isLoadingRecommendedProducts) {
    return <FullPageSpinner />;
  }

  return (
    <Layout type='sidebar_page_layout' pageTitle='marketing'>
      <Section name='content'>
        <div className='page'>
          <div className='page-header'>
            <div className='flex items-start justify-between w-full flex-wrap gap-sm'>
              <div className='page-title-container'>
                <p className='page-title'>{t('recommendations')}</p>
                <p className='page-subtitle'>{t('recommendations_subtitle')}</p>
              </div>
            </div>
          </div>

          <div className='page-body' id='sp_page_body'>
            <div className='h-full'>
              <div className='grid grid-cols-10 h-full divide-x-1'>
                <div className='col-span-6 p-md'>
                  <RecommendedList items={recommendedProductsData ?? []} />
                </div>

                <div className='col-span-4 flex flex-col items-center justify-center py-md'>
                  <p className='text-secondary-500 font-semibold text-lg mb-md'>{t('how_they_will_show')}</p>
                  <RecommendationsPreview items={recommendedProductsData ?? []} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};
