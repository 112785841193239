import { useMemo, useState } from 'react';
import { CountryType } from '../types';
import countryCodes from '../utils/phone-codes-data.json';

export const useCountrySearch = (selectedCountry: CountryType, prioritizedWhiteList: string[]) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredCountries = useMemo(() => {
    const normalizedSearchTerm = searchTerm.toLowerCase().trim();

    const applyFilterAndReduce = (countries: CountryType[]) => {
      return countries
        .filter(
          (country) =>
            country.nameEn.toLowerCase().includes(normalizedSearchTerm) ||
            country.nameAr.toLowerCase().includes(normalizedSearchTerm) ||
            country.code.includes(normalizedSearchTerm)
        )
        .reduce((result: CountryType[], country: CountryType) => {
          if (country.nameEn === selectedCountry.nameEn) {
            result.unshift(country);
          } else {
            result.push(country);
          }
          return result;
        }, []);
    };

    if (prioritizedWhiteList.length) {
      const prioritizedCountries = countryCodes.filter((country) => prioritizedWhiteList.includes(country.countryCode));
      return applyFilterAndReduce(prioritizedCountries);
    }

    return applyFilterAndReduce(countryCodes);
  }, [searchTerm, selectedCountry, prioritizedWhiteList]);

  return { searchTerm, setSearchTerm, filteredCountries };
};
