import { useIsRTL } from 'hooks/use-is-rtl';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Input,
  Listbox,
  ListboxItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'theme/components';
import { CountryType } from '../types';
import { CountryFlagIcon } from './country-flag-icon';

interface CountryCodeDropdownProps {
  isOpen: boolean;
  onClose: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleCountrySelect: (country: CountryType) => void;
  filteredCountries: CountryType[];
  selectedCountry: CountryType;
}

export const CountryCodeDropdown = ({
  isOpen,
  onClose,
  searchTerm,
  setSearchTerm,
  handleCountrySelect,
  filteredCountries,
  selectedCountry,
}: CountryCodeDropdownProps) => {
  const [selectedKeys, setSelectedKeys] = useState(new Set([selectedCountry.nameEn]));
  const { t } = useTranslation();
  const isRTL = useIsRTL();

  const _onClose = () => {
    setSearchTerm('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={_onClose} size='md'>
      <ModalContent>
        {() => (
          <>
            <ModalHeader>{t('country_codes')}</ModalHeader>

            <ModalBody>
              <Input
                type='text'
                variant='bordered'
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
                placeholder={t('united_arab_emirates') as string}
                size='lg'
                isClearable
                onClear={() => setSearchTerm('')}
                label={t('country_name')}
                labelPlacement='inside'
                radius='sm'
              />

              <Divider className='my-2' />

              <Listbox
                aria-label='countries'
                variant='flat'
                selectionMode='single'
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys as any}
                items={filteredCountries}>
                {(item: any) => (
                  <ListboxItem key={item.nameEn} onClick={() => handleCountrySelect(item)}>
                    <div className='flex items-center justify-start gap-2'>
                      <CountryFlagIcon countryCode={item.countryCode} size='md' />
                      <p>{isRTL ? item.nameAr : item.nameEn}</p>
                    </div>
                  </ListboxItem>
                )}
              </Listbox>
            </ModalBody>
            <ModalFooter />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
