import { ModalProps } from '@nextui-org/react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { onLogout } from 'utils/on-logout';

export const LogoutModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={
        <div className='modal-header'>
          <div className='modal-title'>{t('logout')}</div>
        </div>
      }
      footer={
        <div className='modal-footer'>
          <Button type='default' onClick={onClose}>
            {t('cancel')}
          </Button>

          <Button type='primary' onClick={onLogout}>
            {t('logout')}
          </Button>
        </div>
      }>
      <p className='text-gray-500'>{t('are-you-sure-you-want-to-logout')}</p>
    </Modal>
  );
};
