import { Columns, useTableState } from 'components/table';
import { useCurrentUser } from 'hooks/use-current-user';
import _toNumber from 'lodash/toNumber';
import _toString from 'lodash/toString';
import { InfoIcon } from 'pages/orders/icons/info-icon';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  ClonedOrderDetailsItem,
  setOnItemPriceChange,
  setOnItemQtyChange,
} from 'redux/orders/order-details-page-reducer';
import { Input, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from 'theme/components';
import { formatPrice } from 'utils/format-price';
import { InitialPriceModal } from './initial-price-modal';

const initialVisibleColumns = ['code', 'name', 'unit', 'unit_price', 'quantity', 'total_price'];

interface OrderItemsTableProps {
  subtotal: string;
  vat: string;
  total: string;
  status: string;
}

export const OrderItemsTable = ({ subtotal, vat, total, status }: OrderItemsTableProps) => {
  const { clonedItems } = useAppSelector((state) => state.orderDetailsPage);
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const dispatch = useAppDispatch();

  const columns: Columns = [
    { label: t('item-code'), key: 'code' },
    { label: t('name'), key: 'name', sortable: true },
    { label: t('unit'), key: 'unit' },
    { label: t('price'), key: 'unit_price' },
    { label: t('qty'), key: 'quantity', sortable: true },
    { label: t('received_qty'), key: 'received_quantity', sortable: true },
    { label: t('total'), key: 'total_price' },
  ];

  const isUAEUser = useMemo(() => {
    return user?.country?.code === 'AE';
  }, [user]);

  const onChangeItemQuantity = (itemId: number, newQty: number) => {
    dispatch(setOnItemQtyChange({ itemId, newQty }));
  };

  const onChangeItemPrice = (itemId: number, newPrice: number) => {
    dispatch(setOnItemPriceChange({ itemId, newPrice }));
  };

  const { visibleColumns, setVisibleColumns, sortDescriptor, setSortDescriptor, headerColumns, sortedItems } =
    useTableState<ClonedOrderDetailsItem>({
      data: clonedItems,
      columns,
      initialVisibleColumns,
      totalItems: clonedItems?.length,
    });

  const isNewOrder = useMemo(() => {
    return status === 'restaurant_placed';
  }, [status]);

  const isReceivedOrder = useMemo(() => {
    return status === 'received';
  }, [status]);

  useEffect(() => {
    if (isReceivedOrder) {
      const array = Array.from(visibleColumns);
      array.splice(5, 0, 'received_quantity');
      setVisibleColumns(new Set(array));
    }
  }, [isReceivedOrder]);

  const bottomContent = useMemo(() => {
    return (
      <div className='flex flex-col gap-xs px-md'>
        <div className='flex justify-between items-center text-sm'>
          <p>{t('number-of-items')}</p>
          <p>{clonedItems?.length}</p>
        </div>

        <div className='flex flex-col gap-1'>
          <div className='flex justify-between items-center text-sm'>
            <p>{t('sub-total')}</p>
            <p>{subtotal}</p>
          </div>
          <div className='flex justify-between items-center text-sm'>
            <p>{t('vat')}</p>
            <p>{vat}</p>
          </div>
          <div className='flex justify-between items-center font-bold text-red-500'>
            <p>{t('total')}</p>
            <p>{total}</p>
          </div>
        </div>
      </div>
    );
  }, []);

  const renderCell = useCallback((item: ClonedOrderDetailsItem, columnKey: React.Key) => {
    switch (columnKey) {
      case 'code': {
        return <div>{item?.code}</div>;
      }
      case 'name': {
        return <div>{item?.name}</div>;
      }
      case 'unit': {
        return <div>{item?.unit}</div>;
      }
      case 'unit_price': {
        const isPriceChanged = (() => {
          const _item = item?.history?.find((i: any) => i.status === 'restaurant_placed');
          if (!_item) return false;
          return _item?.price !== item?.price;
        })();

        const initialPrice = (() => {
          const _item = item?.history?.find((i: any) => i.status === 'restaurant_placed');
          if (!_item) return item?.price;
          return _item?.price;
        })();

        return (
          <div>
            {isNewOrder && (
              <>
                {isUAEUser && (
                  <>
                    {item?.isEditEnabled && (
                      <>
                        <Input
                          type='number'
                          value={_toString(item?.price)}
                          onChange={(e) => onChangeItemPrice(item?.id, _toNumber(e.target.value))}
                          size='sm'
                          color='default'
                          variant='flat'
                        />
                      </>
                    )}

                    {!item?.isEditEnabled && <>{formatPrice(item?.price ?? 0)}</>}
                  </>
                )}

                {!isUAEUser && <>{formatPrice(item?.price ?? 0)}</>}
              </>
            )}

            {isReceivedOrder && (
              <div className='flex items-center justify-between'>
                {!isPriceChanged && <p>{formatPrice(item?.price ?? 0)}</p>}

                {isPriceChanged && (
                  <InitialPriceModal
                    itemName={item?.name}
                    initialPrice={initialPrice}
                    receivedPrice={item?.price}
                    trigger={
                      <div className='flex items-center justify-between cursor-pointer w-full'>
                        <p className='text-[#00bcf2]'>{formatPrice(initialPrice ?? 0)}</p>
                        <InfoIcon className='cursor-pointer fill-[#00BCF2] mt-[-1px]' />
                      </div>
                    }
                  />
                )}
              </div>
            )}
          </div>
        );
      }
      case 'quantity': {
        return (
          <div>
            {!isReceivedOrder && (
              <div>
                {!item?.isEditEnabled && <>{item?.quantity}</>}

                {!!item?.isEditEnabled && (
                  <Input
                    type='number'
                    value={_toString(item?.quantity)}
                    onChange={(e) => onChangeItemQuantity(item?.id, _toNumber(e.target.value))}
                    size='sm'
                    color='default'
                    variant='flat'
                  />
                )}
              </div>
            )}

            {!!isReceivedOrder && (
              <div>{item?.history?.find((i: any) => i.status === 'restaurant_placed')?.quantity ?? 0}</div>
            )}
          </div>
        );
      }
      case 'received_quantity': {
        return <div>{item?.quantity}</div>;
      }
      case 'total_price': {
        return <div>{formatPrice(item?.total ?? 0)}</div>;
      }

      default: {
        return <div>---</div>;
      }
    }
  }, []);

  return (
    <Table
      aria-label='Order items details table'
      isHeaderSticky
      bottomContent={bottomContent}
      bottomContentPlacement='outside'
      classNames={{
        base: 'px-sm',
        td: 'text-start',
      }}
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}>
      <TableHeader columns={headerColumns}>
        {(column) => (
          <TableColumn key={column.key} allowsSorting={column.sortable}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody emptyContent='No items found' items={sortedItems}>
        {(item) => (
          <TableRow key={item.id}>{(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}</TableRow>
        )}
      </TableBody>
    </Table>
  );
};
