import { Button, Empty, Image, message, Typography } from 'antd';
import { useUpdateRecommendedProductsApi } from 'apis/marketing/use-update-recommended-products-api';
import { useDisclosure } from 'hooks/use-disclosure';
import { useGetCurrency } from 'hooks/use-get-currency';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil } from 'react-icons/hi';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { formatPrice } from 'utils/format-price';
import { RecommendedProduct } from '../type';
import { SelectRecommendedProductsModal } from './select-recommended-products-modal';

interface RecommendedListProps {
  items: RecommendedProduct[];
}

export const RecommendedList = ({ items }: RecommendedListProps) => {
  const { currency } = useGetCurrency();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { mutate: updateRecommendedProducts } = useUpdateRecommendedProductsApi();

  const [list, setList] = useState<RecommendedProduct[]>([]);

  // Update list items
  useEffect(() => {
    setList(items);
  }, [items]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(list);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setList(reorderedItems);

    const variables = {
      body: {
        productIds: reorderedItems.map((i) => i.productId),
      },
    };

    updateRecommendedProducts(variables, {
      onSuccess: () => {
        message.success(t('updated'));
      },
    });
  };

  if (!list?.length) {
    return (
      <>
        <Empty description={<p>{t('no_recommended_products')}</p>} className='md:mt-[150px]'>
          <Button type='primary' onClick={onOpen}>
            {t('get_started')}
          </Button>
        </Empty>

        <SelectRecommendedProductsModal isOpen={isOpen} onClose={onClose} />
      </>
    );
  }

  return (
    <>
      <div className='grid grid-cols-1 gap-xs'>
        <div className='flex items-center'>
          <div className='flex-1'>
            <p className='text-secondary-500 font-semibold text-lg'>{t('selected_products')}</p>
            <p className='text-default-500 text-md'>{t('drag_to_reorder')}</p>
          </div>

          <Button type='primary' icon={<HiOutlinePencil />} onClick={onOpen}>
            {t('edit')}
          </Button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className='flex flex-col gap-2xs'>
                {list?.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='flex items-center gap-sm select-none border-1 px-sm rounded-sm bg-white'>
                        <RxDragHandleDots2 />

                        <Image
                          src={item.imageUrl}
                          fallback='https://placehold.co/400?text=img&font=roboto'
                          preview={false}
                          width={50}
                        />

                        <div className='flex flex-col py-1'>
                          <Typography.Text
                            style={{ maxWidth: 300 }}
                            ellipsis={{
                              suffix: '',
                              tooltip: item.name,
                            }}>
                            {item.name}
                          </Typography.Text>
                          <p className='text-default-500'>{`${formatPrice(item.price, currency)}/${item.unit}`}</p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <SelectRecommendedProductsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
