import { cn } from '@nextui-org/react';
import { NavBar } from 'antd-mobile';
import { useApproveOrderApi } from 'apis/orders/use-approve-order-api';
import { useGetOrderDetailsByIdApi } from 'apis/orders/use-get-order-details-by-id-api';
import { UploadOrderInvoiceFiles } from 'components/order-details/upload-order-invoice-files';
import { useAuth } from 'hooks/use-auth';
import { usePageParams } from 'hooks/use-page-params';
import { t } from 'i18next';
import { Layout, Section } from 'layout';
import { CustomerContactInfo } from 'pages/orders/components/customer-contact-info';
import { CustomerDetailsCard } from 'pages/orders/components/customer-details-card';
import { DownloadLpoModal } from 'pages/orders/components/download-lpo-modal';
import { OrderDetailsSection } from 'pages/orders/components/order-details-section';
import { OrderStatusCard } from 'pages/orders/components/order-status-card';
import { RejectOrderModal } from 'pages/orders/components/reject-order-modal';
import { StatusDetailsCard } from 'pages/orders/components/status-details-card';
import { useEffect, useMemo } from 'react';
import { HiOutlineArrowLeft, HiOutlineCheck, HiOutlinePencilAlt, HiOutlineX } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  disableModifyItems,
  enableModifyItems,
  resetModifiedItems,
  saveModifyItems,
  setInitialClonedItems,
} from 'redux/orders/order-details-page-reducer';
import { Accordion, AccordionItem, Button, Spinner } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';
import { URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';
import { OrderDetailsSkeleton } from './order-details-skeleton';

export const OrderDetailsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useBreakpoint();
  const { orderId } = useParams<{ orderId: string }>();
  const { isGuest } = useAuth();
  const { getPageParams } = usePageParams();
  const { status } = getPageParams();
  const hasBackHistory = window.history.length > 1;

  const {
    data,
    isLoading: isLoadingOrderDetails,
    isFetching: isFetchingOrderDetails,
  } = useGetOrderDetailsByIdApi(orderId as string, {
    onSuccess(data) {
      dispatch(setInitialClonedItems(data?.items));
    },
  });

  // Make sure to disable modify mode before unmount the component
  useEffect(() => {
    return () => {
      dispatch(disableModifyItems());
    };
  }, []);

  const { isLoading: isLoadingApproveOrder, mutate: approveOrderApi } = useApproveOrderApi();

  const isLoading = isLoadingOrderDetails || isFetchingOrderDetails;

  const { clonedItems, isModified, isModifyItemsEnabled, isSavingModifiedItems } = useAppSelector(
    (state) => state.orderDetailsPage
  );

  const isNewOrder = useMemo<boolean>(() => {
    return data?.status === 'restaurant_placed';
  }, [data?.status]);

  const onEnableModifyMode = () => {
    dispatch(enableModifyItems());
  };

  const onSaveModifyMode = () => {
    dispatch(saveModifyItems());
  };

  const onResetModifyMode = () => {
    dispatch(resetModifiedItems(data?.items));
  };

  const onAcceptOrder = () => {
    const variables = {
      body: {
        id: Number(orderId),
        orderItems: clonedItems?.map((i) => {
          return {
            id: i.id,
            confirmedQuantity: i.quantity >= 0 ? i.quantity : 0,
            confirmedPrice: i.price >= 0 ? i.price : 0,
          };
        }),
      },
    };

    approveOrderApi(variables, {
      onSuccess: () => {
        gaLogEvent('ACCEPT_ORDER', {
          ids: [variables?.body?.id],
          total_approved_orders: 1,
        });
      },
    });
  };

  // Check if this order has auto-approve status
  useEffect(() => {
    if (!isLoading && isNewOrder && status === 'approved') {
      onAcceptOrder();
    }
  }, [isLoading, isNewOrder, status]);

  if (isMobile) {
    return (
      <Layout type='mobile_page_layout'>
        <Section name='header'>
          <NavBar
            onBack={() => navigate(-1)}
            backIcon={!isGuest}
            right={
              isNewOrder ? (
                <RejectOrderModal
                  trigger={
                    <Button variant='light' color='danger' isDisabled={isLoading}>
                      {t('reject-order')}
                    </Button>
                  }
                  orderId={data?.id}
                />
              ) : undefined
            }
            left={<p className='text-secondary-500 font-semibold'>{t('order-details')}</p>}
            style={{
              '--height': '60px',
              '--border-bottom': '1px #cbd5e0 solid',
            }}
          />
        </Section>

        <Section name='content'>
          {isLoading && (
            <div className='grid place-content-center'>
              <Spinner color='secondary' />
            </div>
          )}

          {!isLoading && (
            <div className='flex flex-col gap-sm p-sm'>
              <OrderStatusCard status={data?.status} orderCode={data?.code} createdAt={data?.createdAt} />

              <StatusDetailsCard
                status={data?.status}
                updatedByUser={data?.updatedByUser}
                history={data?.history}
                reason={data?.comment}
              />

              <CustomerDetailsCard
                customerName={data?.restaurant?.name}
                branch={data?.restaurant?.subEntity?.name}
                subEntityType={data?.restaurant?.subEntity?.type}
                deliveryDate={data?.deliveryDate}
                note={data?.note}
              />

              <Accordion defaultExpandedKeys={['2']} selectionMode='multiple' itemClasses={{ title: 'font-bold' }}>
                <AccordionItem key='1' aria-label='Accordion 1' title={t('customer_contact_info')}>
                  <CustomerContactInfo
                    placedBy={data?.restaurant?.user?.name}
                    email={data?.restaurant?.user?.email}
                    phoneNumber={data?.restaurant?.user?.phoneNumber}
                  />
                </AccordionItem>

                <AccordionItem key='2' aria-label='Accordion 2' title={t('order-details')}>
                  <OrderDetailsSection
                    subtotal={data?.subtotal}
                    vat={data?.vat}
                    total={data?.total}
                    status={data?.status}
                  />
                </AccordionItem>
              </Accordion>

              <DownloadLpoModal orderStatus={data?.status} orderId={data?.id} orderCode={data?.code} />

              <UploadOrderInvoiceFiles
                currentInvoicesUrlList={data?.invoiceImages?.map((orderInvoice: { url: string }) => orderInvoice.url)}
              />
            </div>
          )}

          {isNewOrder && (
            <div
              className={cn(
                'sticky bottom-0 bg-white z-10',
                'flex gap-xs',
                'px-sm py-2xs',
                'border-t-1 border-t-gray-300'
              )}>
              {!isModifyItemsEnabled && (
                <>
                  <Button
                    onClick={() => dispatch(enableModifyItems())}
                    variant='light'
                    color='secondary'
                    isDisabled={isLoading}
                    fullWidth>
                    {t('modify')}
                  </Button>

                  <Button onClick={onAcceptOrder} variant='solid' color='success' isDisabled={isLoading} fullWidth>
                    {t('accept-order')}
                  </Button>
                </>
              )}

              {isModifyItemsEnabled && (
                <>
                  <Button
                    onClick={() => {
                      dispatch(resetModifiedItems(data?.items));
                      dispatch(disableModifyItems());
                    }}
                    variant='light'
                    color='secondary'
                    isDisabled={isLoading}
                    fullWidth>
                    {t('reset')}
                  </Button>

                  <Button
                    onClick={() => dispatch(saveModifyItems())}
                    variant='solid'
                    color='success'
                    isDisabled={isLoading}
                    fullWidth>
                    {t('save')}
                  </Button>
                </>
              )}
            </div>
          )}
        </Section>
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout type='sidebar_page_layout' pageTitle='orders'>
        <Section name='content'>
          <OrderDetailsSkeleton />
        </Section>
      </Layout>
    );
  }

  return (
    <Layout type='sidebar_page_layout' pageTitle='orders'>
      <Section name='content'>
        <div className='page'>
          <div className='page-header'>
            <div className='flex items-start w-full flex-wrap gap-sm'>
              {!isGuest && (
                <Button
                  isIconOnly
                  variant='light'
                  onClick={() => (hasBackHistory ? navigate(-1) : navigate(URL.ORDERS))}>
                  <HiOutlineArrowLeft size='24' />
                </Button>
              )}

              <div className='page-title-container'>
                <p className='page-title'>{t('order-details')}</p>
                <p className='page-subtitle'>
                  {isNewOrder ? t('new-order-details-sub-title') : t('general-order-details-sub-title')}
                </p>
              </div>

              <div className='page-header-buttons-container ms-auto'>
                {isNewOrder && !isModifyItemsEnabled && (
                  <>
                    <RejectOrderModal
                      trigger={
                        <Button color='default' variant='bordered' startContent={<HiOutlineX size='18' />}>
                          {t('reject')}
                        </Button>
                      }
                      orderId={data?.id}
                    />

                    <Button
                      onClick={onEnableModifyMode}
                      color='default'
                      variant='bordered'
                      startContent={<HiOutlinePencilAlt size='18' />}>
                      {t('modify')}
                    </Button>

                    <Button
                      color='success'
                      variant='solid'
                      className='p-md'
                      onClick={onAcceptOrder}
                      isLoading={isLoadingApproveOrder}
                      startContent={<HiOutlineCheck size='18' />}>
                      {t('accept-order')}
                    </Button>
                  </>
                )}

                {isModifyItemsEnabled && (
                  <>
                    <Button isDisabled={!isModified} onClick={onResetModifyMode} variant='light'>
                      {t('reset')}
                    </Button>

                    <Button
                      onClick={onSaveModifyMode}
                      color='secondary'
                      variant='solid'
                      isLoading={isSavingModifiedItems}>
                      {t('save')}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='page-body' id='sp_page_body'>
            <div className='flex divide-x'>
              {/* Left Side */}
              <div className='flex flex-col gap-sm items-stretch p-2xs'>
                <OrderStatusCard status={data?.status} orderCode={data?.code} createdAt={data?.createdAt} />

                <StatusDetailsCard
                  status={data?.status}
                  updatedByUser={data?.updatedByUser}
                  history={data?.history}
                  reason={data?.comment}
                />

                <CustomerDetailsCard
                  customerName={data?.restaurant?.name}
                  branch={data?.restaurant?.subEntity?.name}
                  subEntityType={data?.restaurant?.subEntity?.type}
                  deliveryDate={data?.deliveryDate}
                  note={data?.note}
                />

                <UploadOrderInvoiceFiles
                  currentInvoicesUrlList={data?.invoiceImages?.map((orderInvoice: { url: string }) => orderInvoice.url)}
                  width='350px'
                />

                <CustomerContactInfo
                  placedBy={data?.restaurant?.user?.name}
                  email={data?.restaurant?.user?.email}
                  phoneNumber={data?.restaurant?.user?.phoneNumber}
                />
              </div>

              {/* Right Side */}
              <div className='flex-1'>
                <div className='flex items-center justify-between p-sm'>
                  <p className='font-bold text-secondary-500'>{t('order-list')}</p>

                  <DownloadLpoModal orderStatus={data?.status} orderId={data?.id} orderCode={data?.code} />
                </div>

                <OrderDetailsSection
                  subtotal={data?.subtotal}
                  vat={data?.vat}
                  total={data?.total}
                  status={data?.status}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};
