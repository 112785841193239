import _get from 'lodash/get';
import _isInteger from 'lodash/isInteger';
import _toNumber from 'lodash/toNumber';
import qs from 'query-string';

export const getPageParam = (defaultPage = 1) => {
  const params = qs.parse(window.location.search, {
    parseNumbers: false,
  });

  const pageParam = _get(params, 'page', defaultPage);
  const page = _toNumber(pageParam);

  if (_isInteger(page) && page >= 1) {
    return page;
  }

  return defaultPage;
};
