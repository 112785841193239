import { useEffect, useMemo } from 'react';
import { usePageParams } from './use-page-params';

interface UsePaginationParamsReturn {
  current: number;
  pageSize: number;
  onPaginationChange: (newPage?: number, newPageSize?: number) => void;
}

export const usePaginationParams = (): UsePaginationParamsReturn => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams() ?? {};

  // Set initial page & limit params
  useEffect(() => {
    const isInvalidPage = isNaN(Number(params.page));
    if (isInvalidPage) {
      params.page = 1;
    }

    const isInvalidLimit = isNaN(Number(params.limit));
    if (isInvalidLimit) {
      params.limit = 25;
    }

    setPageParams(params);
  }, []);

  // Get current page
  const current = useMemo<number>(() => {
    const isValid = !isNaN(Number(params.page));
    if (isValid) return Number(params.page);
    return 1;
  }, [params]);

  // Handle page size
  const pageSize = useMemo<number>(() => {
    const isValid = !isNaN(Number(params.limit));
    if (isValid) return Number(params.limit);
    return 25;
  }, [params]);

  // Handle pagination change
  const onPaginationChange = (newPage?: number, newPageSize?: number) => {
    params.page = newPage || 1;
    params.limit = newPageSize || 25;
    setPageParams(params);
  };

  return {
    current,
    pageSize,
    onPaginationChange,
  };
};
