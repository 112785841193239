import { cn, TabsProps } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from 'theme/components';
import { useBreakpoint } from 'theme/hooks';

export type NavTabsOption = { name: string; route: string; notificationsCount?: number };

interface NavTapsProps extends TabsProps {
  options: NavTabsOption[];
}

export const NavTabs = ({ options, className, ...rest }: NavTapsProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();

  return (
    <Tabs
      aria-label='nav-tabs'
      variant='solid'
      defaultSelectedKey={location.pathname}
      color='default'
      size='lg'
      className={cn('m-[-3px]', className)}
      onSelectionChange={(key) => navigate(key as string)}
      fullWidth={isMobile}
      {...rest}>
      {options?.map((o) => (
        <Tab key={o.route} title={t(o.name)} />
      ))}
    </Tabs>
  );
};
