import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'ums/v1/supplier/catalog/download';
export const downloadProductsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;
type TQueryKey = [string];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint() {
  return `${endpoint}`;
}

export function useDownloadProductsApi(options?: Options): ReturnType {
  const fullEndpoint = constructEndpoint();

  const queryFn = async () => {
    try {
      await http
        .get(fullEndpoint, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          fileDownload(response.data, `products_${new Date().getTime()}.xlsx`);
        });
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper([fullEndpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
