import { usePageParams } from 'hooks/use-page-params';
import _isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { compareObjects } from 'utils/compare-two-objects';

// Define the type of UseFilterProps as a generic interface
interface UseFilterProps<T> {
  initialState: T;
}

// The useFilter function now accepts a type argument T,
// which is used to type the initialState argument.
export const useFilter = <T extends Record<string, any>>({ initialState }: UseFilterProps<T>) => {
  const [filter, setFilter] = useState<T>(initialState);
  const [isDirty, setIsDirty] = useState(false);

  // Get page params
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();
  const filterParams = compareObjects(initialState, params);
  const isActive = !!Object.keys(filterParams).length;

  useEffect(() => {
    setIsDirty(!_isEqual(initialState, filter));
  }, [filter]);

  // Set initial filter values as per page params
  useEffect(() => {
    setFilter((prev) => {
      return {
        ...prev,
        ...filterParams,
      };
    });
  }, []);

  // Update params
  const onApply = () => {
    setPageParams({ ...params, ...filter });
  };

  // Reset params
  const onReset = () => {
    setFilter(initialState);
    setPageParams({ ...params, ...initialState });
  };

  return {
    filter,
    isActive,
    isDirty,
    setFilter,
    onApply,
    onReset,
  };
};
