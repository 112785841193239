import { LinkProps } from '@nextui-org/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'theme/components';
dayjs.extend(duration);

function formatSeconds(seconds: number): string {
  // Create a duration using the seconds provided.
  const dur = dayjs.duration(seconds, 'seconds');

  // Format the duration as 'mm:ss'.
  return `${String(dur.minutes()).padStart(2, '0')}:${String(dur.seconds()).padStart(2, '0')}`;
}

interface ResendCodeButtonProps extends LinkProps {
  onResend: () => void;
  isLoading?: boolean;
  secondsRemaining?: number;
}

export const ResendCodeButton = ({
  onResend,
  isLoading,
  secondsRemaining = 59,
  className,
  ...rest
}: ResendCodeButtonProps) => {
  const [remainingTime, setRemainingTime] = useState(secondsRemaining);
  const remainingTimeRef = useRef(remainingTime);
  const [startTimer, setStartTimer] = useState(true);
  const { t } = useTranslation();

  const syncRemainingTime = () => {
    setRemainingTime(remainingTimeRef.current);
  };

  useEffect(() => {
    remainingTimeRef.current = secondsRemaining;
    syncRemainingTime();
  }, [secondsRemaining]);

  useEffect(() => {
    if (!startTimer) return;

    const timerId = setInterval(() => {
      if (remainingTimeRef.current <= 0) {
        clearInterval(timerId);
        setStartTimer(false);
        return;
      }
      remainingTimeRef.current = remainingTimeRef.current - 1;
      syncRemainingTime();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [startTimer]);

  const reset = () => {
    remainingTimeRef.current = secondsRemaining;
    syncRemainingTime();
    setStartTimer(true);
  };

  const _onResend = () => {
    onResend();
    reset();
  };

  return (
    <Link
      className={`uppercase text-gray-800 font-bold text-xs cursor-pointer ${className}`}
      isDisabled={remainingTimeRef.current > 0}
      onClick={_onResend}
      {...rest}>
      {remainingTimeRef.current > 0 &&
        `${t('resend_code')} ${isLoading ? '00:00' : formatSeconds(remainingTimeRef.current)}`}
      {remainingTimeRef.current <= 0 && t('resend_code')}
    </Link>
  );
};
