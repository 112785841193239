import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';
import { Order } from '../../pages/orders/types';
import { initialMetaData, MetaData } from '../utils/page-meta-data';

export type OrdersType = 'new' | 'confirmed' | 'history';

interface SliceState {
  meta: MetaData;
  selectedKeys: number[];
  isFilterActive: boolean;
  orderItems: {
    new: Order[];
    confirmed: Order[];
    history: Order[];
  };
}

const initialState: SliceState = {
  meta: initialMetaData,
  selectedKeys: [],
  isFilterActive: false,
  orderItems: {
    new: [],
    confirmed: [],
    history: [],
  },
};

const ordersListPageSlice = createSlice({
  name: 'orders-list-page',
  initialState,
  reducers: {
    setPageHaveUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.meta.pageHaveUnsavedChanges = action.payload;
    },
    setPrevUrl(state, action: PayloadAction<string>) {
      state.meta.prevUrl = action.payload;
    },
    setSelectedOrdersAction(state, action: PayloadAction<number[]>) {
      state.selectedKeys = action.payload;
    },
    resetSelectedOrdersAction(state) {
      state.selectedKeys = [];
    },
    setIsFilterActiveAction(state, action: PayloadAction<SliceState['isFilterActive']>) {
      state.isFilterActive = action.payload;
    },
    resetOrdersAction(state, action: PayloadAction<{ ordersType: OrdersType; data: Order[] }>) {
      const { ordersType, data } = action.payload;

      state.orderItems[ordersType] = data;
    },
    addOrdersAction(state, action: PayloadAction<{ ordersType: OrdersType; data: Order[] }>) {
      const { ordersType, data } = action.payload;
      const prevOrders = state.orderItems[ordersType] ?? [];
      const newOrders = data ?? [];

      state.orderItems[ordersType] = _uniqBy([...prevOrders, ...newOrders], 'id');
    },
    removeOrdersByIdsAction(state, action: PayloadAction<{ ordersType: OrdersType; ids: number[] }>) {
      const { ordersType, ids } = action.payload;
      const filtered = state.orderItems[ordersType].filter((o) => !ids.includes(o.id));

      state.orderItems[ordersType] = _uniqBy(filtered, 'id');
    },
  },
});

export const {
  setPageHaveUnsavedChanges,
  setPrevUrl,
  setSelectedOrdersAction,
  resetSelectedOrdersAction,
  setIsFilterActiveAction,
  resetOrdersAction,
  addOrdersAction,
  removeOrdersByIdsAction,
} = ordersListPageSlice.actions;

export default ordersListPageSlice.reducer;
