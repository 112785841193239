import { Card, CardBody, CardHeader, Skeleton } from 'theme/components';

export const OrderDetailsSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <Skeleton className='rounded-lg flex-1'>
          <div className='h-[100px]'></div>
        </Skeleton>
      </CardHeader>
      <CardBody className='flex flex-row gap-md'>
        <Skeleton className='w-1/4 rounded-lg'>
          <div className='w-1/4 h-[400px]'></div>
        </Skeleton>
        <Skeleton className='w-3/4 rounded-lg'>
          <div className='w-3/4 h-[400px]'></div>
        </Skeleton>
      </CardBody>
    </Card>
  );
};
