import _get from 'lodash/get';
import _isInteger from 'lodash/isInteger';
import _toNumber from 'lodash/toNumber';
import qs from 'query-string';

export const getLimitParam = (defaultLimit = 25) => {
  const params = qs.parse(window.location.search, {
    parseNumbers: false,
  });

  const limitParam = _get(params, 'limit', defaultLimit);
  const limit = _toNumber(limitParam);

  if (_isInteger(limit) && limit >= 1 && limit <= 100) {
    return limit;
  }

  return defaultLimit;
};
