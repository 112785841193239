import { Button as OButton, extendVariants } from '@nextui-org/react';

export const Button = extendVariants(OButton, {
  variants: {
    color: {
      success: 'text-white',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'solid',
    color: 'default',
    radius: 'sm',
  },
});
