import { useDisclosure } from 'hooks/use-disclosure';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from 'theme/components';

export const TermsAndConditions = (props: HTMLProps<HTMLDivElement>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <div {...props}>
        <p className='inline text-[10px]'>{t('by_tapping_on_continue_you_are_accepting_our')}</p>
        <Link className='inline text-[10px] ms-1 font-semibold cursor-pointer' color='secondary' onClick={onOpen}>
          {t('terms_conditions_title')}
        </Link>
      </div>

      <Modal size='3xl' isOpen={isOpen} onClose={onClose} scrollBehavior='inside'>
        <ModalContent>
          {() => (
            <>
              <ModalHeader className='flex flex-col gap-1'>{t('terms_conditions_title')}</ModalHeader>
              <ModalBody>
                <p>{t('terms_conditions_body')}</p>
              </ModalBody>
              <ModalFooter />
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
