import { usePageParams } from 'hooks/use-page-params';
import { useEffect } from 'react';
import { getLimitParam } from 'utils/get-limit-param';
import { getPageParam } from 'utils/get-page-param';

export const useOnScrollPaginationChange = () => {
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams() ?? {};
  const page = getPageParam();
  const limit = getLimitParam();

  const nextPage = () => {
    params.page = page + 1;
    setPageParams(params);
  };

  useEffect(() => {
    // Make sure always to start from page 1 when user refresh the page.
    params.page = 1;
    params.limit = limit;
    setPageParams(params);
  }, []);

  return { nextPage };
};
