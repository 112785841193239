import { SVGProps } from 'react';

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='13' height='14' viewBox='0 0 13 14' fill='white' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.125 0.875C2.7417 0.875 0 3.61747 0 7C0 10.3825 2.7417 13.125 6.125 13.125C9.5083 13.125 12.25 10.3825 12.25 7C12.25 3.61747 9.5083 0.875 6.125 0.875ZM8.96317 8.75558C9.10673 8.89914 9.18738 9.09385 9.18738 9.29688C9.18738 9.4999 9.10673 9.69461 8.96317 9.83817C8.81961 9.98173 8.6249 10.0624 8.42188 10.0624C8.21885 10.0624 8.02414 9.98173 7.88058 9.83817L6.125 8.08259L4.36942 9.83817C4.29848 9.90952 4.21414 9.96613 4.12125 10.0048C4.02835 10.0434 3.92873 10.0633 3.82812 10.0633C3.72752 10.0633 3.6279 10.0434 3.535 10.0048C3.44211 9.96613 3.35777 9.90952 3.28683 9.83817C3.21567 9.76713 3.15921 9.68276 3.12069 9.58987C3.08217 9.49699 3.06235 9.39743 3.06235 9.29688C3.06235 9.19632 3.08217 9.09676 3.12069 9.00388C3.15921 8.91099 3.21567 8.82662 3.28683 8.75558L5.04241 7L3.28683 5.24442C3.14327 5.10086 3.06262 4.90615 3.06262 4.70312C3.06262 4.5001 3.14327 4.30539 3.28683 4.16183C3.43039 4.01827 3.6251 3.93762 3.82812 3.93762C4.03115 3.93762 4.22586 4.01827 4.36942 4.16183L6.125 5.91741L7.88058 4.16183C8.02414 4.01827 8.21885 3.93762 8.42188 3.93762C8.6249 3.93762 8.81961 4.01827 8.96317 4.16183C9.10673 4.30539 9.18738 4.5001 9.18738 4.70312C9.18738 4.90615 9.10673 5.10086 8.96317 5.24442L7.20759 7L8.96317 8.75558Z'
        fill='inherit'
      />
    </svg>
  );
};
