import { cn, RadioProps } from '@nextui-org/react';
import { Radio } from 'theme/components';

export const CustomRadio = ({ children, ...rest }: RadioProps) => {
  return (
    <Radio
      {...rest}
      color='secondary'
      classNames={{
        base: cn(
          'inline-flex hover:bg-content2 items-center justify-between flex-row-reverse m-0 max-w-full',
          'cursor-pointer rounded-lg gap-4 py-1 border-2 border-transparent'
        ),
        labelWrapper: 'ml-0',
      }}>
      {children}
    </Radio>
  );
};
