import { Typography } from 'antd';

interface TextCellProps {
  value: string | number | null | undefined;
  placeholder?: string;
  minWidth?: number;
  maxWidth?: number;
}

export const TextCell = ({ value, placeholder = '-', minWidth = 1, maxWidth = 200 }: TextCellProps) => {
  if (value) {
    return (
      <Typography.Text
        style={{ minWidth, maxWidth }}
        ellipsis={{
          suffix: '',
          tooltip: value,
        }}>
        {value.toString()}
      </Typography.Text>
    );
  }

  return <div className='text-default-400'>{placeholder}</div>;
};
