import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetProductsApi } from './use-get-products-api';

const endpoint = 'ums/v1/supplier/catalog/products';

type TData = Record<string, any>;
type TError = AxiosError;

interface BodyType {
  imageUrl: string | null;
  nameEn: string;
  nameAr: string;
  brandEn: string;
  brandAr: string | null;
  defaultUnit: string;
  defaultPrice: number;
  code: string;
  status: 'active' | 'inactive';
  specs: string | null;
}

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(productId: number) {
  return `${endpoint}/${productId}`;
}

export function useUpdateProductApi(productId: number, options?: Options): ReturnType {
  const { refetch: refetchGetProducts } = useGetProductsApi({ enabled: false });
  const { t } = useTranslation();
  const fullEndpoint = constructEndpoint(productId);

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.put(fullEndpoint, body);

      // Refetch get products
      refetchGetProducts();

      message.success(t('updated_successfully'));

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
