export function mergeAndReplaceByKey<T>(array1: T[], array2: T[], key: keyof T): T[] {
  const result = [...array1];

  array2.forEach((element) => {
    const matchIndex = result.findIndex((item) => item[key] === element[key]);

    if (matchIndex !== -1) {
      result[matchIndex] = element;
    } else {
      result.push(element);
    }
  });

  return result;
}
