import { Divider, Dropdown, theme, type MenuProps } from 'antd';
import { LogoutModal } from 'components/logout-modal';
import { NotificationsModal } from 'components/notifications-modal';
import { UserAvatar } from 'components/user-avatar';
import { useFeatureFlags } from 'feature-flags';
import { useAuth } from 'hooks/use-auth';
import { useChangeLanguage } from 'hooks/use-change-language';
import { useCurrentUser } from 'hooks/use-current-user';
import { useDisclosure } from 'hooks/use-disclosure';
import { useIsRTL } from 'hooks/use-is-rtl';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineBell, HiOutlineLogin, HiOutlineLogout, HiOutlineTranslate } from 'react-icons/hi';
import { LANGUAGES } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';

export const ProfileMenu = () => {
  const { isOpen: isOpenNotifications, onOpen: onOpenNotifications, onClose: onCloseNotifications } = useDisclosure();
  const { isOpen: isOpenLogout, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure();
  const { onToggleLang } = useChangeLanguage();
  const { t } = useTranslation();
  const { ar, en } = LANGUAGES;
  const { isGuest } = useAuth();
  const { user } = useCurrentUser();
  const isRTL = useIsRTL();
  const { is_notifications_settings_enabled } = useFeatureFlags();

  const onClickLoginButton = () => {
    if (isGuest) {
      gaLogEvent('action_click_login_ext');
    }
  };

  const items = useMemo<MenuProps['items']>(() => {
    const i: MenuProps['items'] = [];

    // Notifications
    if (!isGuest || is_notifications_settings_enabled) {
      i.push({
        key: '1',
        onClick: onOpenNotifications,
        icon: <HiOutlineBell size='18' />,
        label: <p>{t('notifications-settings')}</p>,
      });
    }

    // Language
    i.push({
      key: '2',
      onClick: onToggleLang,
      icon: <HiOutlineTranslate size='18' />,
      label: (
        <div className='flex align-center justify-between'>
          <p>{t('language')}</p>
          <p className='text-red-500'>{isRTL ? en.label : ar.label}</p>
        </div>
      ),
    });

    // Login
    if (isGuest) {
      i.push({
        key: '3',
        onClick: onClickLoginButton,
        icon: <HiOutlineLogin size='18' />,
        label: <p> {t('login')}</p>,
      });
    }

    // Logout
    if (!isGuest) {
      i.push({
        key: '4',
        onClick: onOpenLogout,
        icon: <HiOutlineLogout size='18' />,
        label: <p>{t('logout')}</p>,
      });
    }

    return i;
  }, [isGuest, is_notifications_settings_enabled, isRTL]);

  const { useToken } = theme;
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{ items }}
        dropdownRender={(menu) => (
          <div style={contentStyle}>
            <div className='flex flex-col items-center p-sm'>
              <UserAvatar name={user?.name} size={60} />
              <p className='text-lg font-bold text-secondary-500 mt-3xs'>{user?.name}</p>
              <p className='text-sm text-default-500'>{user?.email ? user?.email : user?.phoneNumber}</p>
            </div>
            <Divider className='m-0' />
            {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none' } })}
          </div>
        )}>
        <UserAvatar name={isGuest ? t('guest') : user?.name} className='cursor-pointer' size='large' />
      </Dropdown>

      <NotificationsModal open={isOpenNotifications} onCancel={onCloseNotifications} />
      <LogoutModal isOpen={isOpenLogout} onClose={onCloseLogout} />
    </>
  );
};
