import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from 'utils/constants';

export const useIsRTL = () => {
  const { i18n } = useTranslation();
  const { ar } = LANGUAGES;

  const isRTL = useMemo(() => {
    return i18n.language === ar.code;
  }, [i18n.language]);

  return isRTL;
};
