import { Button, Image, Typography } from 'antd';
import { Swiper } from 'antd-mobile';
import Cart from 'assets/img/cart.png';
import frozenChicken from 'assets/img/frozen-chicken.png';
import juice from 'assets/img/juice.png';
import milk from 'assets/img/milk.png';
import nescafe from 'assets/img/nescafe.png';
import pineapple from 'assets/img/pineapple.png';
import cn from 'classnames';
import { useMemo } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';
import { formatPrice } from 'utils/format-price';
import { RecommendedProduct } from '../type';

const defaultItems: RecommendedProduct[] = [
  {
    id: 1,
    productId: 1,
    imageUrl: pineapple,
    name: 'Fresh Pineapple',
    unit: 'kg',
    price: 23,
    currency: 'AED',
  },
  {
    id: 2,
    productId: 2,
    imageUrl: milk,
    name: 'Almarai Milk Low Fat',
    unit: 'ml',
    price: 9,
    currency: 'AED',
  },
  {
    id: 3,
    productId: 3,
    imageUrl: nescafe,
    name: 'Nescafe Classic',
    unit: 'g',
    price: 44,
    currency: 'AED',
  },
  {
    id: 4,
    productId: 4,
    imageUrl: frozenChicken,
    name: 'Frozen Halal Chicken',
    unit: 'g',
    price: 14,
    currency: 'AED',
  },
  {
    id: 5,
    productId: 5,
    imageUrl: juice,
    name: 'Barakat Orange Juice',
    unit: 'L',
    price: 10,
    currency: 'AED',
  },
];

interface RecommendationsPreviewProps {
  items: RecommendedProduct[];
}

export const RecommendationsPreview = ({ items }: RecommendationsPreviewProps) => {
  const _items = useMemo(() => {
    return items?.length ? items : defaultItems;
  }, [items]);

  return (
    <div className='relative'>
      <Image src={Cart} preview={false} width={250} />
      <div className='absolute bottom-[125px] left-[11px] w-[231px] h-[152px] z-10 flex flex-col'>
        <p className='text-default-600 font-semibold text-[10px] py-[6px] px-2'>Recommended for you</p>

        <Swiper slideSize={38} defaultIndex={0} indicator={false} className='h-full'>
          {_items.map((i) => (
            <Swiper.Item key={i.id} className='h-full px-1'>
              <div className='h-full select-none'>
                <div
                  className={cn('relative h-[80px]', 'rounded-sm w-full', `bg-cover`)}
                  style={{ backgroundImage: `url('${i.imageUrl}')` }}>
                  <div
                    className={cn(
                      'absolute top-0 right-0 z-10',
                      'bg-primary-500 rounded-sm text-white text-[10px]',
                      'px-1 py-[1px]',
                      { hidden: !i.discount }
                    )}>
                    Save {i.discount}%
                  </div>

                  <Button
                    type='primary'
                    icon={<HiOutlinePlus />}
                    size='small'
                    className='absolute bottom-0 right-0 z-10'
                  />
                </div>

                <div className='flex flex-col mt-[4px]'>
                  <Typography.Text
                    className='text-[9px]'
                    style={{ maxWidth: 100 }}
                    ellipsis={{
                      suffix: '',
                    }}>
                    {i.name}
                  </Typography.Text>

                  <div className='text-[9px] flex items-center'>
                    <p className='font-semibold'>{formatPrice(i.price, i.currency)}</p>
                    <p>/{i.unit}</p>
                  </div>
                </div>
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
