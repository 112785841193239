import { SVGIconProps } from 'types/elements';

export const InfoIcon = (props: SVGIconProps) => {
  return (
    <svg width='23' height='22' viewBox='0 0 23 22' fill='#F0AD4E' {...props}>
      <g clipPath='url(#clip0_1500_17892)'>
        <mask id='mask0_1500_17892' maskUnits='userSpaceOnUse' x='3' y='4' width='15' height='14'>
          <path
            d='M10.25 17.25C11.0709 17.2511 11.884 17.0899 12.6424 16.7757C13.4008 16.4616 14.0897 16.0006 14.6694 15.4194C15.2506 14.8397 15.7116 14.1508 16.0257 13.3924C16.3399 12.634 16.5011 11.8209 16.5 11C16.5011 10.1791 16.3399 9.36606 16.0257 8.60764C15.7115 7.84922 15.2506 7.16035 14.6694 6.58063C14.0897 5.99939 13.4008 5.53844 12.6424 5.22428C11.884 4.91012 11.0709 4.74894 10.25 4.75001C9.42909 4.74896 8.61606 4.91015 7.85764 5.22431C7.09922 5.53847 6.41035 5.9994 5.83063 6.58063C5.2494 7.16035 4.78847 7.84922 4.47431 8.60764C4.16015 9.36606 3.99896 10.1791 4.00001 11C3.99894 11.8209 4.16012 12.634 4.47428 13.3924C4.78844 14.1508 5.24939 14.8397 5.83063 15.4194C6.41035 16.0006 7.09922 16.4615 7.85764 16.7757C8.61606 17.0899 9.42909 17.2511 10.25 17.25Z'
            fill='white'
            stroke='white'
            strokeWidth='1.25'
            strokeLinejoin='round'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.25 15.0625C10.4572 15.0625 10.6559 14.9802 10.8024 14.8337C10.9489 14.6872 11.0312 14.4885 11.0312 14.2812C11.0312 14.074 10.9489 13.8753 10.8024 13.7288C10.6559 13.5823 10.4572 13.5 10.25 13.5C10.0428 13.5 9.84409 13.5823 9.69757 13.7288C9.55106 13.8753 9.46875 14.074 9.46875 14.2812C9.46875 14.4885 9.55106 14.6872 9.69757 14.8337C9.84409 14.9802 10.0428 15.0625 10.25 15.0625Z'
            fill='black'
          />
          <path d='M10.25 7.25V12.25' stroke='black' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
        </mask>
        <g mask='url(#mask0_1500_17892)'>
          <path d='M2.75 3.5H17.75V18.5H2.75V3.5Z' fill='inherit' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1500_17892'>
          <rect width='14.5' height='14.5' fill='white' transform='translate(3 3.75)' />
        </clipPath>
      </defs>
    </svg>
  );
};
