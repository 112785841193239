import { Avatar, HStack, Text, VStack } from '@elkaso-app/web-design';
import { TruncateText } from 'components/truncate-text';

interface UserDetailsProps {
  name: string;
  email: string;
}

export const UserDetails = ({ name, email }: UserDetailsProps) => {
  return (
    <HStack spacing='sm' px='md'>
      <Avatar name={name} bg='gray.200' color='blue.500' borderRadius='base' />

      <VStack align={'start'} spacing={0}>
        <Text color={'blue.500'} fontWeight='bold'>
          {name}
        </Text>

        <TruncateText fontSize='sm' color='gray.500' text={email} width='160px' />
      </VStack>
    </HStack>
  );
};
