import { Selection, SortDescriptor } from '@nextui-org/react';
import { Columns } from 'components/table/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface UseTableStateProps<T> {
  data: T[];
  columns: Columns;
  initialVisibleColumns: string[];
  totalItems: number;
  onVisibleColumnChange?: () => void;
  onRowsPerPageChange?: () => void;
  onSelectedKeysChange?: () => void;
  onSortChange?: () => void;
  onPageChange?: () => void;
}

export function useTableState<T>({
  data,
  columns,
  initialVisibleColumns,
  totalItems,
  onVisibleColumnChange,
  onRowsPerPageChange,
  onSelectedKeysChange,
  onSortChange,
  onPageChange,
}: UseTableStateProps<T>) {
  const [visibleColumns, setVisibleColumns] = useState<Selection>(new Set(initialVisibleColumns));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set([]));
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({});
  const [page, setPage] = useState(1);

  // Visible columns changes
  useEffect(() => {
    // ...

    onVisibleColumnChange?.();
  }, [visibleColumns]);

  // Row per page changes
  useEffect(() => {
    // ...

    onRowsPerPageChange?.();
  }, [rowsPerPage]);

  // Selected keys changes
  useEffect(() => {
    // ...

    onSelectedKeysChange?.();
  }, [selectedKeys]);

  // Sort descriptor changes
  useEffect(() => {
    // ...

    onSortChange?.();
  }, [sortDescriptor]);

  // Page number changes
  useEffect(() => {
    // ...

    onPageChange?.();
  }, [page]);

  const headerColumns = useMemo(() => {
    if (visibleColumns === 'all') return columns;

    return columns.filter((column) => Array.from(visibleColumns).includes(column.key));
  }, [visibleColumns]);

  const items = useMemo(() => {
    // You can reshape the data object here before you return it.
    return data;
  }, [data]);

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof T] as number;
      const second = b[sortDescriptor.column as keyof T] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === 'descending' ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const pages = Math.ceil(totalItems / rowsPerPage);

  const onNextPage = useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const updateRowsPerPage = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  return {
    visibleColumns,
    setVisibleColumns,
    rowsPerPage,
    setRowsPerPage,
    selectedKeys,
    setSelectedKeys,
    sortDescriptor,
    setSortDescriptor,
    page,
    setPage,
    headerColumns,
    sortedItems,
    items,
    onNextPage,
    onPreviousPage,
    updateRowsPerPage,
  };
}
