import { CountryType } from '../types';

export const usePhoneNumberChange = (
  selectedCountry: CountryType,
  setPhoneNumber: (phoneNumber: string) => void,
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
) => {
  return (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (isNaN(Number(value))) return;

    setPhoneNumber(value);

    event.target.value = selectedCountry.code + event.target.value;
    onChange?.(event);
  };
};
