import { Button, GetProp, Upload } from 'antd';
import { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useUploadFileApi } from 'apis/config/use-upload-file-api';
import { useSetOrderInvoiceApi } from 'apis/orders/use-set-order-invoice-api';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudUpload } from 'react-icons/hi';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface UploadOrderInvoiceFileOptions {
  width?: string;
  currentInvoicesUrlList: string[];
}

export const UploadOrderInvoiceFiles = ({ width, currentInvoicesUrlList }: UploadOrderInvoiceFileOptions) => {
  const [files, setFile] = useState<UploadFile[]>([]);

  const { t } = useTranslation();

  const { mutateAsync: uploadApi, isLoading: isLoadingUploadFile } = useUploadFileApi();
  const { mutate: updateInvoicesApi, isLoading: isLoadingUpdateInvoices } = useSetOrderInvoiceApi();
  const isLoading = isLoadingUploadFile || isLoadingUpdateInvoices;

  const handleUpload = async () => {
    const urls = [...currentInvoicesUrlList];

    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files?.[i] as FileType);

      const variables = {
        body: formData,
      };

      const { url } = await uploadApi(variables);

      urls.push(url);
    }

    const variables = {
      body: {
        invoiceImages: urls,
      },
    };

    updateInvoicesApi(variables);
    setFile([]);
  };

  return (
    <div className='flex flex-col gap-xs md:border-t-1 md:border-b-1 md:py-xs' style={{ width: width || '100%' }}>
      <p className='text-secondary-500 font-semibold text-sm'>{t('upload_invoices')}</p>

      <Upload.Dragger
        className='select-none'
        height={120}
        accept='application/pdf,image/jpeg,image/png'
        multiple
        maxCount={4}
        fileList={files}
        beforeUpload={(file) => {
          setFile((prev) => [...(prev || []), file as FileType]);
          return false;
        }}
        onRemove={(file) => {
          setFile((prev) => prev?.filter((_file) => _file.uid !== file.uid));
        }}>
        <div className='flex flex-col place-items-center'>
          <HiOutlineCloudUpload size='30px' className='stroke-secondary-500' />
          <p className='text-secondary-500 font-semibold mt-xs'>{t('drag_drop_txt')}</p>
          <p className='text-default-500 text-center mt-[5px]'>{t('file_size_validation')}</p>
        </div>
      </Upload.Dragger>

      <Button
        className={cn('w-full', { hidden: !files?.length })}
        type='primary'
        size='middle'
        loading={isLoading}
        onClick={handleUpload}>
        {t('upload')}
      </Button>
    </div>
  );
};
