import { CheckboxProps, cn } from '@nextui-org/react';
import { Checkbox } from 'theme/components';

export const CustomCheckbox = ({ children, ...rest }: CheckboxProps) => {
  return (
    <Checkbox
      color='secondary'
      classNames={{
        base: cn(
          'inline-flex hover:bg-content2 items-center justify-between flex-row-reverse m-0 max-w-full',
          'cursor-pointer rounded-lg gap-4 py-1 border-2 border-transparent'
        ),
        label: 'w-full',
        wrapper: 'm-0',
      }}
      {...rest}>
      {children}
    </Checkbox>
  );
};
