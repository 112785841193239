import {
  Button,
  ChakraSelect,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
} from '@elkaso-app/web-design';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSaveAllRegionsDetailsApi } from 'apis/settings/use-save-all-regions-details-api';
import { useSaveRegionDetailsApi } from 'apis/settings/use-save-region-details-api';
import { useGetCurrency } from 'hooks/use-get-currency';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

type TOption = {
  value: number;
  label: string;
};

type FormValues = {
  minimumOrderValue: number;
  cutOffTime: string;
  deliverySla: TOption | undefined;
  weekdays: TOption[];
};

const schema = yup.object().shape({
  minimumOrderValue: yup
    .number()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .required('please-enter-mov-value'),
  cutOffTime: yup.string().required('please-enter-cut-off-time-value'),
  deliverySla: yup.object().shape({
    label: yup.string(),
    value: yup.number().required('please-enter-SLA-value'),
  }),
  weekdays: yup
    .array()
    .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .min(1, 'please-select-at-least-one-delivery-day'),
});

interface RegionSettingsBoxProps {
  regionIds: string[];
  title: string;
  minimumOrderValue: number;
  cutOffTime: string;
  deliverySla: number;
  weekdays: number[];
  subTitle?: string;
  isDisabled?: boolean;
  isApplyAll?: boolean;
}

export const RegionSettingsBox = ({
  regionIds,
  title,
  minimumOrderValue,
  cutOffTime,
  deliverySla,
  weekdays,
  subTitle,
  isDisabled,
  isApplyAll,
}: RegionSettingsBoxProps) => {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValue] = useState<FormValues>();
  const defaultSubTitle = t('sub-apply-all');
  const { isLoading: isLoadingSaveRegionDetails, mutate: saveRegionDetails } = useSaveRegionDetailsApi();
  const { isLoading: isLoadingSaveAllRegionsDetails, mutate: saveAllRegionsDetails } = useSaveAllRegionsDetailsApi();
  const { currency } = useGetCurrency();

  const daysOptions = useMemo<TOption[]>(() => {
    return [
      { value: 1, label: t('monday') },
      { value: 2, label: t('tuesday') },
      { value: 3, label: t('wednesday') },
      { value: 4, label: t('thursday') },
      { value: 5, label: t('friday') },
      { value: 0, label: t('sunday') },
      { value: 6, label: t('saturday') },
    ];
  }, []);

  const slaOptions = useMemo<TOption[]>(() => {
    return [
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
    ];
  }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    setDefaultValue({
      minimumOrderValue,
      cutOffTime,
      deliverySla: slaOptions?.find((i) => i.value === deliverySla),
      weekdays: daysOptions?.filter((day) => weekdays?.includes(day.value)),
    });
  }, [minimumOrderValue, cutOffTime, deliverySla, weekdays]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!isApplyAll) {
      const variables = {
        regionId: regionIds?.[0],
        body: {
          minimumOrderValue: data?.minimumOrderValue,
          deliverySla: data?.deliverySla?.value || 0,
          cutOffTime: data?.cutOffTime,
          weekdays: data.weekdays?.map((i) => Number(i.value)),
        },
      };

      saveRegionDetails(variables);
    }

    if (isApplyAll) {
      const variables = {
        body: {
          regionIds: regionIds.map((i) => Number(i)),
          minimumOrderValue: data?.minimumOrderValue,
          deliverySla: data?.deliverySla?.value || 0,
          cutOffTime: data?.cutOffTime,
          weekdays: data.weekdays?.map((i) => Number(i.value)),
        },
      };

      saveAllRegionsDetails(variables);
    }
  };

  const onDiscard = () => {
    reset(defaultValues);
  };

  return (
    <VStack
      as='form'
      id={`shippingAndDeliveryId${isApplyAll ? 'All' : regionIds?.[0]}`}
      onSubmit={handleSubmit(onSubmit)}
      spacing={'md'}
      align={'stretch'}
      w={'full'}
      bg={'white'}
      border='1px solid'
      borderColor='gray.200'
      borderRadius={'base'}
      noValidate
      p={'md'}>
      <VStack align={'start'} spacing='xs'>
        <Heading as='h2' color='blue.500' fontSize='md'>
          {title}
        </Heading>

        <Text fontSize={'sm'} color={'gray.500'}>
          {subTitle ?? defaultSubTitle}
        </Text>
      </VStack>

      <HStack align='start'>
        <FormControl isInvalid={!!errors.minimumOrderValue} isRequired>
          <FormLabel color='gray.500'>{t('minimum-order-value')}</FormLabel>
          <InputGroup>
            <Input
              placeholder='0.00'
              color='gray.500'
              variant='outline'
              type='number'
              {...register('minimumOrderValue')}
            />

            <InputRightElement me='sm' fontSize='sm' fontWeight='semibold' color='gray.500'>
              {currency}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{t(`${errors.minimumOrderValue?.message}`)}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.cutOffTime} isRequired>
          <FormLabel color='gray.500'>{t('cut-off-time')}</FormLabel>

          <Input
            placeholder='Select Date and Time'
            color='gray.500'
            variant='outline'
            type='time'
            {...register('cutOffTime')}
          />

          <FormErrorMessage>{t(`${errors?.cutOffTime?.message}`)}</FormErrorMessage>
        </FormControl>
      </HStack>

      <Controller
        control={control}
        name='deliverySla'
        render={({ field: { value, onChange, onBlur, name } }) => (
          <FormControl isInvalid={!!errors.deliverySla} isRequired>
            <FormLabel color='gray.500'>{t('SLA')}</FormLabel>
            <ChakraSelect
              variant='outline'
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              isSearchable={false}
              placeholder={t('Please select an SLA value')}
              options={slaOptions}
              maxMenuHeight={200}
            />
            <FormErrorMessage>{t(`${errors.deliverySla?.message}`)}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name='weekdays'
        render={({ field: { value, onChange, onBlur, name } }) => (
          <FormControl isInvalid={!!errors.weekdays} isRequired>
            <FormLabel color='gray.500'>{t('delivery-days')}</FormLabel>
            <ChakraSelect
              isMulti
              variant='outline'
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              closeMenuOnSelect={false}
              placeholder={t('select')}
              options={daysOptions}
            />
            <FormErrorMessage>{t(`${errors.weekdays?.message}`)}</FormErrorMessage>
          </FormControl>
        )}
      />

      <HStack justify={'end'} spacing='md'>
        <Button
          variant='outline'
          colorScheme='blue'
          onClick={onDiscard}
          isDisabled={!isDirty || !isValid || isDisabled}>
          {t('discard')}
        </Button>

        <Button
          form={`shippingAndDeliveryId${isApplyAll ? 'All' : regionIds?.[0]}`}
          type='submit'
          colorScheme='blue'
          isLoading={isLoadingSaveRegionDetails || isLoadingSaveAllRegionsDetails}
          isDisabled={!isDirty || !isValid || isDisabled}>
          {t('save')}
        </Button>
      </HStack>
    </VStack>
  );
};
