import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';

export const BlankPageLayout = ({ sections }: LayoutProps) => {
  return (
    <main>
      {/* Start Content */}
      <section className='min-h-screen'>
        <Section sections={sections} name='content' />
      </section>
      {/* End Content */}
    </main>
  );
};
