import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  desktop: {
    isOpen: boolean;
  };
  tablet: {
    isOpen: boolean;
  };
  isContactUsOpen: boolean;
};

const initialState: SliceState = {
  desktop: {
    isOpen: true,
  },
  tablet: {
    isOpen: false,
  },
  isContactUsOpen: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleDesktopSidebar(state) {
      state.desktop = { ...state.desktop, isOpen: !state.desktop.isOpen };
    },
    setDesktopSidebar(state, action: PayloadAction<boolean>) {
      state.desktop = { ...state.desktop, isOpen: action.payload };
    },
    toggleTabletSidebar(state) {
      state.tablet = { ...state.tablet, isOpen: !state.tablet.isOpen };
    },
    closeTabletSidebar(state) {
      state.tablet = { ...state.tablet, isOpen: false };
    },
    toggleContactUs(state) {
      state.isContactUsOpen = !state.isContactUsOpen;
    },
  },
});

export const { toggleDesktopSidebar, setDesktopSidebar, toggleTabletSidebar, closeTabletSidebar, toggleContactUs } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
