import { useMemo } from 'react';
import { LocalStorageKeys } from 'utils/constants';
import { setIsGuestAction, setIsLoggedInAction } from '../redux/auth/reducer';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

interface ReturnType {
  isLoggedIn: boolean;
  isGuest: boolean;
}

export const useAuth = (): ReturnType => {
  const dispatch = useAppDispatch();
  const { isLoggedIn, isGuest } = useAppSelector((state) => state.auth);

  const accessToken = localStorage.getItem(LocalStorageKeys.access_token);
  const isGuestToken = localStorage.getItem(LocalStorageKeys.is_guest_token);

  useMemo(() => {
    if (accessToken) {
      dispatch(setIsLoggedInAction(true));

      if (isGuestToken) {
        dispatch(setIsGuestAction(true));
      }

      if (!isGuestToken) {
        dispatch(setIsGuestAction(false));
      }
    }

    if (!accessToken) {
      dispatch(setIsLoggedInAction(false));
      dispatch(setIsGuestAction(false));
    }
  }, [accessToken, isGuestToken]);

  return { isLoggedIn, isGuest };
};
