import { InputProps } from '@nextui-org/react';
import { useGetCountriesApi } from 'apis/use-get-countries-api';
import { useDisclosure } from 'hooks/use-disclosure';
import React, { useMemo, useState } from 'react';
import { CountryCodeDropdown } from './components/country-code-dropdown';
import { PhoneNumberInput } from './components/phone-number-input';
import { useCountrySearch } from './hooks/use-country-search';
import { useCountrySelect } from './hooks/use-country-select';
import { usePhoneNumberChange } from './hooks/use-phone-number-change';
import { CountryType } from './types';
import { parseMobileNumber } from './utils/parse-mobile-number';
import countryCodes from './utils/phone-codes-data.json';
export interface MobileInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  countryCode?: string;
  whiteList?: string[];
}

export const MobileInput = ({
  onChange,
  countryCode = 'AE',
  whiteList = [],
  variant,
  size,
  ...inputProps
}: MobileInputProps) => {
  const { data: countriesApiData } = useGetCountriesApi();
  const { data: whiteListData = [] } = countriesApiData ?? {};

  const prioritizedWhiteList = useMemo<string[]>(() => {
    // Priority goes to manual whiteList
    if (whiteList.length) return whiteList;

    // Return dynamic whiteList if no manual whiteList added
    return whiteListData?.map((c: any) => c?.code);
  }, [whiteList, whiteListData]);

  const defaultValue = useMemo(() => parseMobileNumber(inputProps?.value as string), [inputProps?.value]);

  const defaultSelectedCountryValue = useMemo(() => {
    const countryCodeFound = countryCodes.find((c) => c.countryCode === countryCode);

    if (countryCodeFound) return countryCodeFound;
    return {
      nameEn: 'United Arab Emirates',
      nameAr: 'الإمارات العربية المتحدة',
      code: '+971',
      countryCode: 'AE',
    };
  }, []);

  const autoSelectedCountryValue = useMemo(
    () => ({
      nameEn: defaultValue.nameEn,
      nameAr: defaultValue.nameAr,
      code: defaultValue.code,
      countryCode: defaultValue.countryCode,
    }),
    [defaultValue]
  );

  const getSelectedValue = useMemo(() => {
    if (inputProps?.value) {
      return autoSelectedCountryValue;
    }

    return defaultSelectedCountryValue;
  }, [inputProps, autoSelectedCountryValue, defaultSelectedCountryValue]);

  const [selectedCountry, setSelectedCountry] = useState<CountryType>(getSelectedValue);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [phoneNumber, setPhoneNumber] = useState(defaultValue.nationalNumber);

  const handlePhoneNumberChange = usePhoneNumberChange(selectedCountry, setPhoneNumber, onChange);

  const { searchTerm, setSearchTerm, filteredCountries } = useCountrySearch(selectedCountry, prioritizedWhiteList);

  const handleCountrySelect = useCountrySelect(
    selectedCountry,
    setSearchTerm,
    setSelectedCountry,
    onClose,
    phoneNumber,
    onChange
  );

  return (
    <>
      <PhoneNumberInput
        variant={variant}
        size={size}
        onOpen={onOpen}
        selectedCountry={selectedCountry}
        handlePhoneNumberChange={handlePhoneNumberChange}
        phoneNumber={phoneNumber}
        {...inputProps}
      />

      <CountryCodeDropdown
        isOpen={isOpen}
        onClose={onClose}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleCountrySelect={handleCountrySelect}
        filteredCountries={filteredCountries}
        selectedCountry={selectedCountry}
      />
    </>
  );
};
