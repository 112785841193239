import { LoadingOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { Alert, Button, Modal, Spin, Upload } from 'antd';
import { useDownloadProductsApi } from 'apis/products/use-download-products-api';
import { useUploadProductsApi } from 'apis/products/use-upload-products-api';
import { useDisclosure } from 'hooks/use-disclosure';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudUpload, HiOutlineDownload, HiOutlinePaperClip } from 'react-icons/hi';
import { gaLogEvent } from 'utils/ga-log-event';

const { Dragger } = Upload;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface UpdateProductsModalProps {
  hasProducts: number;
}

export const UpdateProductsModal = ({ hasProducts }: UpdateProductsModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [showFullErrorsList, setShowFullErrorsList] = useState(false);

  const {
    refetch: downloadProductsList,
    isLoading: isLoadingDownloadProducts,
    isFetching: isFetchingDownloadProducts,
  } = useDownloadProductsApi();

  const { mutate: uploadProducts, isLoading: isLoadingUploadProducts } = useUploadProductsApi();

  const handleDownload = () => {
    gaLogEvent('action_download_products_file');
    downloadProductsList();
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    style: { background: 'white' },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setErrorsList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      setErrorsList([]);
      return false;
    },
    fileList,
  };

  const _onClose = () => {
    onClose();
    setFileList([]);
    setErrorsList([]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file as FileType);
    });

    gaLogEvent('action_upload_products_file');

    uploadProducts(
      { body: formData },
      {
        onSuccess: () => {
          _onClose();
        },
        onError: (errors: any) => {
          gaLogEvent('action_upload_products_failed', {
            errors: errors.response?.data?.errors || 'No errors received',
          });

          setErrorsList(errors.response?.data?.errors);
        },
      }
    );
  };

  return (
    <>
      <Button icon={<HiOutlinePaperClip size='18px' />} onClick={onOpen} size='large' type='default'>
        {t('update-products')}
      </Button>

      <Modal
        open={isOpen}
        onCancel={_onClose}
        destroyOnClose
        width={700}
        title={
          <div className='modal-header'>
            <div className='modal-title'>{t('update-products')}</div>
            <div className='modal-subtitle'>{t('sub_update_products')}</div>
          </div>
        }
        footer={
          <div className='modal-footer justify-center'>
            {!!fileList?.length && (
              <Button
                type='primary'
                className='w-[300px]'
                onClick={handleUpload}
                size='large'
                disabled={!!errorsList?.length}
                loading={isLoadingUploadProducts}>
                {t('upload')}
              </Button>
            )}
          </div>
        }>
        {!!errorsList?.length && (
          <Alert
            type='error'
            showIcon
            message={t('upload_errors')}
            description={
              <div className='flex flex-col justify-center gap-xs'>
                <ul className={`${errorsList?.length > 1 && 'list-disc'}`}>
                  {errorsList.slice(0, showFullErrorsList ? errorsList?.length : 3).map((e, i) => (
                    <li key={i} className='mt-2'>
                      {e}
                    </li>
                  ))}
                </ul>

                {errorsList?.length > 3 && (
                  <Button type='link' onClick={() => setShowFullErrorsList(!showFullErrorsList)}>
                    {t('show_errors_list', { key: showFullErrorsList ? t('less') : t('full') })}
                  </Button>
                )}
              </div>
            }
          />
        )}

        <div className='grid grid-cols-2 gap-md mt-md'>
          <div>
            <p className='upload-products-step-title'>{t('step_1')}</p>
            <p className='upload-products-step-description'>{t('step_1_desc')}</p>

            <div className='upload-products-step-body'>
              <Spin
                spinning={isLoadingDownloadProducts || isFetchingDownloadProducts}
                indicator={<LoadingOutlined spin />}>
                <div
                  className='flex flex-col place-items-center p-md border-1 rounded-md cursor-pointer select-none hover:border-secondary-500'
                  onClick={handleDownload}>
                  <HiOutlineDownload size='40px' className='stroke-secondary-500' />
                  <p className='text-secondary-500 font-bold mt-xs'>{t('click_here_to_download')}</p>
                  <p className='text-default-500 text-center mt-[5px]'>
                    {hasProducts ? t('download_current_products') : t('download_empty_template')}
                  </p>
                </div>
              </Spin>
            </div>
          </div>

          <div>
            <p className='upload-products-step-title'>{t('step_2')}</p>
            <p className='upload-products-step-description'>{t('step_2_desc')}</p>

            <div className='upload-products-step-body'>
              <Dragger className='select-none' {...props}>
                <div className='flex flex-col place-items-center'>
                  <HiOutlineCloudUpload size='40px' className='stroke-secondary-500' />
                  <p className='text-secondary-500 font-bold mt-xs'>{t('drag_drop_txt')}</p>
                  <p className='text-default-500 text-center mt-[5px]'>{t('file_size_validation')}</p>
                </div>
              </Dragger>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
