import { SVGProps } from 'react';

export const FileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='#258750' {...props}>
      <g clipPath='url(#clip0_838_21265)'>
        <path
          d='M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z'
          fill='inherit'
        />
      </g>
      <defs>
        <clipPath id='clip0_838_21265'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
