import { MouseEvent, TouchEvent, useCallback, useEffect, useRef } from 'react';

export function useLongPress(
  onShortPress: (e: MouseEvent | TouchEvent) => void,
  onLongPress: (e: MouseEvent | TouchEvent) => void,
  threshold = 500
) {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const moved = useRef<boolean>(false); // Flag to track if touch has moved

  const start = useCallback(
    (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      moved.current = false; // Reset moved flag on touch start
      timerRef.current = window.setTimeout(() => {
        // Only call onLongPress if there hasn't been significant movement
        if (!moved.current) {
          onLongPress(e);
        }
      }, threshold) as any;
    },
    [onLongPress, threshold]
  );

  const move = useCallback(() => {
    moved.current = true; // Set moved flag on movement
  }, []);

  const stop = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      // Only call onShortPress if the touch did not move significantly
      if (!moved.current) {
        onShortPress(e);
      }
    },
    [onShortPress]
  );

  // Clean up the timer if the component unmounts while pressing
  const cleanUp = useCallback(() => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: cleanUp,
    onTouchStart: start,
    onTouchEnd: stop,
    onTouchMove: move,
    onTouchMoveCapture: move,
  };
}
