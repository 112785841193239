import { useDisclosure } from 'hooks/use-disclosure';
import { useGetCurrency } from 'hooks/use-get-currency';
import { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from 'theme/components';
import { formatPrice } from 'utils/format-price';

interface InitialPriceModalProps {
  trigger: JSX.Element;
  itemName: string;
  initialPrice: number;
  receivedPrice: number;
}

export const InitialPriceModal = ({ trigger, itemName, initialPrice, receivedPrice }: InitialPriceModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currency } = useGetCurrency();
  const { t } = useTranslation();

  return (
    <>
      {cloneElement(trigger, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalContent>
          <ModalHeader>
            <div>
              <h2 className='text-secondary-500 text-lg'>{t('price_change')}</h2>
              <p className='text-default-500 text-sm font-normal'>{t('price_change_body')}</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <Table removeWrapper>
              <TableHeader>
                <TableColumn>{t('item-name')}</TableColumn>
                <TableColumn>{t('unit-price')}</TableColumn>
                <TableColumn>{t('received-price')}</TableColumn>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>{itemName}</TableCell>
                  <TableCell>{formatPrice(Number(initialPrice) ?? 0, currency)}</TableCell>
                  <TableCell className='text-[#00BCF2] font-semibold'>
                    {formatPrice(Number(receivedPrice) ?? 0, currency)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
