import { Order } from '../types';
import { DownloadLpoModal } from './download-lpo-modal';

interface OrdersActionCellProps {
  item: Order;
}

export const OrdersActionCell = ({ item }: OrdersActionCellProps) => {
  return (
    <div className='flex items-center justify-end'>
      <DownloadLpoModal orderStatus={item?.status} orderId={item?.id} orderCode={item?.code} />
    </div>
  );
};
