import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useAuth } from 'hooks/use-auth';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'catalog/v1/measurement-units';
export const getUnitsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = [typeof endpoint];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetUnitsApi(options?: Options): ReturnType {
  const { isLoggedIn } = useAuth();

  const queryFn = async () => {
    try {
      const { data } = await http.get(endpoint, {
        params: {},
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: isLoggedIn,
    ...options,
  });
}
