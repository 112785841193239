import { NavBar } from 'antd-mobile';
import { NavTabs, NavTabsOption } from 'components/nav-tabs';
import { Layout, Section } from 'layout';
import { AcceptOrdersButton } from 'pages/orders/components/accept-orders-button';
import { AggregateOrdersModal } from 'pages/orders/components/aggregate-orders-modal';
import { OrdersFilterModal } from 'pages/orders/components/orders-filter-modal';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useBreakpoint } from 'theme/hooks';
import { URL } from 'utils/constants';

const navTabsOptions: NavTabsOption[] = [
  {
    name: 'new-orders',
    route: URL.ORDERS,
    notificationsCount: 10,
  },
  {
    name: 'confirmed',
    route: URL.ORDERS_CONFIRMED,
  },
  {
    name: 'order-history',
    route: URL.ORDERS_HISTORY,
  },
];

export const OrdersListScreen = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoint();
  const { selectedKeys } = useAppSelector((state) => state.ordersListPage);
  const location = useLocation();

  if (isMobile) {
    return (
      <Layout type='mobile_page_layout'>
        <Section name='header'>
          <NavBar
            back={null}
            left={<p className='text-secondary-500 font-semibold'>{t('orders-list')}</p>}
            right={
              <div className='flex items-center justify-end gap-xs'>
                <OrdersFilterModal />
                <AggregateOrdersModal />
              </div>
            }
            style={{
              '--height': '60px',
            }}
          />

          <div className='px-sm pb-sm border-b-1 border-b-gray-300'>
            <NavTabs options={navTabsOptions} />
          </div>
        </Section>

        <Section name='content'>
          <Outlet />
        </Section>
      </Layout>
    );
  }

  return (
    <Layout type='sidebar_page_layout' pageTitle='orders'>
      <Section name='content'>
        <div className='page'>
          <div className='page-header flex flex-col gap-lg'>
            <div className='flex items-start justify-between w-full flex-wrap gap-sm'>
              <div className='page-header-start'>
                <div className='page-title-container'>
                  <p className='page-title'>{t('orders-list')}</p>
                  <p className='page-subtitle'>{t('orders-list-sup-title')}</p>
                </div>
              </div>

              <div className='page-header-buttons-container'>
                <OrdersFilterModal />
                <AggregateOrdersModal />
              </div>
            </div>

            {/* Tabs & Accept order & download LPO */}
            <div className='flex justify-between items-center w-full'>
              <NavTabs options={navTabsOptions} />

              {location.pathname === URL.ORDERS && <AcceptOrdersButton selectedOrders={selectedKeys} />}
            </div>
          </div>

          <div className='page-body' id='sp_page_body'>
            <Outlet />
          </div>
        </div>
      </Section>
    </Layout>
  );
};
