import React from 'react';
import { Card } from 'theme/components';

interface EmptyStateTemplateProps {
  icon: JSX.Element;
  title: string;
  body: string;
}

export const EmptyStateTemplate = ({ icon, title, body }: EmptyStateTemplateProps) => {
  const _icon = React.cloneElement(icon, { className: 'fill-secondary-500' });

  return (
    <div className='flex flex-col items-center text-center px-xl'>
      <Card className='bg-gray-200 border-none w-[80px] h-[80px] rounded-3xl outline-none items-center justify-center shadow-lg'>
        {_icon}
      </Card>

      <p className='text-secondary-500 font-bold text-xl mt-lg uppercase'>{title}</p>
      <p className='text-gray-500 mt-1'>{body}</p>
    </div>
  );
};
