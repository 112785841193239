import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useGetRegionsDetailsApi } from './use-get-regions-details-api';

const endpoint = 'ums/v1/suppliers/regions';
export const saveRegionDetailsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type BodyType = {
  minimumOrderValue: number;
  deliverySla: number;
  cutOffTime: string;
  weekdays: number[];
};

type TVariables = {
  regionId: string;
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(regionId: string) {
  return `${endpoint}/${regionId}`;
}

export function useSaveRegionDetailsApi(options?: Options): ReturnType {
  const { refetch: getRegionsDetails } = useGetRegionsDetailsApi({ enabled: false });

  const mutationFn = async ({ regionId, body }: TVariables) => {
    try {
      const fullEndpoint = constructEndpoint(regionId);
      const { data } = await http.put(fullEndpoint, body);

      // Refetch the region details
      getRegionsDetails();

      message.success('Region has been saved successfully');

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
