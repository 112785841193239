import type { CollapseProps } from 'antd';
import { Button, Collapse, Modal } from 'antd';
import { useDisclosure } from 'hooks/use-disclosure';
import { useFilter } from 'hooks/use-filter';
import { usePageParams } from 'hooks/use-page-params';
import { DateFilter } from 'pages/orders/components/orders-filter-modal/date-filter';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineFilter } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { resetOrdersAction, setIsFilterActiveAction } from 'redux/orders/orders-list-page-reducer';
import { useBreakpoint } from 'theme/hooks';
import { useGetActiveOrdersTabName } from '../../hooks/use-active-tab';
import { BranchNameFilter } from './branch-name-filter';
import { CustomerNameFilter } from './customer-name-filter';
import { OrdersStatusFilter } from './orders-status-filter';

export interface OrdersFilterSectionProps {
  setFilter: Dispatch<SetStateAction<InitialStateType>>;
  filter: InitialStateType;
}

type InitialStateType = {
  from: string;
  to: string;
  restaurantId: string;
  restaurantLang: string;
  status: string[];
  subEntityId: string;
  subEntityType: string;
  subEntityLang: string;
};

const initialState: InitialStateType = {
  from: '',
  to: '',
  restaurantId: '',
  restaurantLang: '',
  status: [],
  subEntityId: '',
  subEntityType: '',
  subEntityLang: '',
};

export const OrdersFilterModal = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getPageParams } = usePageParams();
  const params = getPageParams();
  const { isMobile } = useBreakpoint();
  const activeTabName = useGetActiveOrdersTabName();

  const { filter, setFilter, onApply, onReset, isActive, isDirty } = useFilter({
    initialState,
  });

  // Update redux state to detect the filter active state outside of the filter component
  useEffect(() => {
    dispatch(setIsFilterActiveAction(isActive));
  }, [isActive]);

  const onResetFilter = () => {
    onReset();
    onClose();

    // Reset redux orders state for the active tab
    dispatch(resetOrdersAction({ ordersType: activeTabName, data: [] }));
  };

  const onApplyFilter = () => {
    onApply();
    onClose();

    // Reset redux orders state for the active tab
    dispatch(resetOrdersAction({ ordersType: activeTabName, data: [] }));
  };

  // Clear filter if user select a value and closed the filter before apply changes
  const onOpenModal = () => {
    // Date
    if (params?.from) {
      setFilter((prev) => {
        return {
          ...prev,
          from: params.from as string,
        };
      });
    }

    if (params?.to) {
      setFilter((prev) => {
        return {
          ...prev,
          to: params.to as string,
        };
      });
    }

    if (!params?.from && !params?.to) {
      setFilter((prev) => {
        return {
          ...prev,
          from: initialState.from,
          to: initialState.to,
        };
      });
    }

    // Restaurant ID
    if (params?.restaurantId && params?.restaurantLang) {
      setFilter((prev) => {
        return {
          ...prev,
          restaurantId: params.restaurantId as string,
          restaurantLang: params.restaurantLang as string,
        };
      });
    }

    if (!params?.restaurantId || !params?.restaurantLang) {
      setFilter((prev) => {
        return {
          ...prev,
          restaurantId: initialState.restaurantId,
          restaurantLang: initialState.restaurantLang,
        };
      });
    }

    // Branch ID
    if (params?.subEntityId && params?.subEntityType && params?.subEntityLang) {
      setFilter((prev) => {
        return {
          ...prev,
          subEntityId: params.subEntityId as string,
          subEntityType: params.subEntityType as string,
          subEntityLang: params.subEntityLang as string,
        };
      });
    }

    if (!params?.subEntityId || !params?.subEntityType || !params?.subEntityLang) {
      setFilter((prev) => {
        return {
          ...prev,
          subEntityId: initialState.subEntityId,
          subEntityType: initialState.subEntityType,
          subEntityLang: params.subEntityLang as string,
        };
      });
    }

    // Status
    if (params?.status) {
      setFilter((prev) => {
        return {
          ...prev,
          status: params.status as string[],
        };
      });
    }

    if (!params?.status) {
      setFilter((prev) => {
        return {
          ...prev,
          status: initialState.status,
        };
      });
    }

    onOpen();
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('order-creation-date'),
      children: <DateFilter filter={filter as InitialStateType} setFilter={setFilter} />,
    },
    {
      key: '2',
      label: t('customer-name'),
      children: <CustomerNameFilter filter={filter} setFilter={setFilter} />,
    },
    {
      key: '3',
      label: t('branch-name'),
      children: <BranchNameFilter filter={filter} setFilter={setFilter} />,
    },
    {
      key: '4',
      label: t('orders-status'),
      children: <OrdersStatusFilter filter={filter} setFilter={setFilter} />,
    },
  ];

  const _items = items.filter((i) => {
    // Hide order status filter except in history page
    if (!location.pathname.includes('orders/history')) {
      return i.key !== '4';
    }

    return i;
  });

  return (
    <>
      <Button
        icon={<HiOutlineFilter size='17' stroke={isActive ? '#ffffff' : '#160C45'} />}
        onClick={onOpenModal}
        size='large'
        type={isActive ? 'primary' : 'default'}>
        {!isMobile && t('filters')}
      </Button>

      <Modal
        open={isOpen}
        onCancel={onClose}
        destroyOnClose
        title={
          <div className='modal-header'>
            <div className='modal-title'>{t('filters')}</div>
          </div>
        }
        footer={
          <div className='modal-footer'>
            <Button type='default' onClick={onResetFilter} disabled={!isDirty}>
              {t('reset-filter')}
            </Button>

            <Button type='primary' onClick={onApplyFilter} disabled={!isDirty}>
              {t('apply-filter')}
            </Button>
          </div>
        }>
        <Collapse items={_items} defaultActiveKey={['1', '2', '3', '4']} ghost expandIconPosition='end' />
      </Modal>
    </>
  );
};
