import { cn } from '@nextui-org/react';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomerContactInfoProps extends HTMLProps<HTMLDivElement> {
  placedBy: string;
  email: string;
  phoneNumber: string;
}

export const CustomerContactInfo = ({ placedBy, email, phoneNumber, className, ...rest }: CustomerContactInfoProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex flex-col gap-sm items-start p-md', className)} {...rest}>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t('placed-by')}:</p>
        <p className='font-bold'>{placedBy}</p>
      </div>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t('email')}:</p>
        <p className='font-bold'>{email}</p>
      </div>
      <div className='flex flex-col items-start'>
        <p className='text-sm'>{t('phone-number')}:</p>
        <p className='font-bold'>{phoneNumber}</p>
      </div>
    </div>
  );
};
