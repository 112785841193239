import { useState } from 'react';

export const useSelectedOrders = () => {
  const [selected, setSelected] = useState<string[]>([]);

  const setSelectedOrders = (ids: string[]) => {
    setSelected(ids);
  };

  const resetSelectedOrders = () => {
    setSelected([]);
  };

  return {
    selected,
    setSelectedOrders,
    resetSelectedOrders,
  };
};
