import { cn } from '@nextui-org/react';
import { LogoutModal } from 'components/logout-modal';
import { NotificationsModal } from 'components/notifications-modal';
import { UserAvatar } from 'components/user-avatar';
import { useFeatureFlags } from 'feature-flags';
import { useAuth } from 'hooks/use-auth';
import { useChangeLanguage } from 'hooks/use-change-language';
import { useCurrentUser } from 'hooks/use-current-user';
import { useDisclosure } from 'hooks/use-disclosure';
import { useIsRTL } from 'hooks/use-is-rtl';
import { Layout, Section } from 'layout';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineBell,
  HiOutlineChevronRight,
  HiOutlineLogin,
  HiOutlineLogout,
  HiOutlineTranslate,
} from 'react-icons/hi';
import { Listbox, ListboxItem } from 'theme/components';
import { LANGUAGES, URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';

export const UserDetails = () => {
  const { user } = useCurrentUser();
  const { isGuest } = useAuth();
  const { t } = useTranslation();

  return (
    <div className='flex flex-col items-center'>
      <UserAvatar name={isGuest ? t('guest') : user?.name} size={80} />
      <p className='text-2xl font-bold text-secondary-500 mt-lg'>{isGuest ? t('guest') : user?.name}</p>
      {!isGuest && <p className='text-sm text-default-500'>{user?.email ? user?.email : user?.phoneNumber}</p>}
    </div>
  );
};

const OptionsList = () => {
  const { t } = useTranslation();
  const { onToggleLang } = useChangeLanguage();
  const { is_notifications_settings_enabled } = useFeatureFlags();
  const isRTL = useIsRTL();
  const { isGuest } = useAuth();

  const { isOpen: isOpenLogoutModal, onOpen: onOpenLogoutModal, onClose: onCloseLogoutModal } = useDisclosure();

  const {
    isOpen: isOpenNotificationsModal,
    onOpen: onOpenNotificationsModal,
    onClose: onCloseNotificationsModal,
  } = useDisclosure();

  const onClickLoginButton = () => {
    if (isGuest) {
      gaLogEvent('action_click_login_ext');
    }
  };

  return (
    <div>
      <Listbox aria-label='user-options' itemClasses={{ base: 'bg-gray-50 px-md py-sm' }}>
        <ListboxItem
          hidden={!is_notifications_settings_enabled}
          onClick={onOpenNotificationsModal}
          startContent={<HiOutlineBell size='20' />}
          endContent={<HiOutlineChevronRight size='18' className={cn(isRTL ? 'rotate-180' : 'rotate-0')} />}>
          {t('notifications')}
        </ListboxItem>

        <ListboxItem
          onClick={onToggleLang}
          startContent={<HiOutlineTranslate size='20' />}
          endContent={
            <p className='text-secondary-500 font-semibold'>{isRTL ? LANGUAGES.en.label : LANGUAGES.ar.label}</p>
          }>
          {t('language')}
        </ListboxItem>

        <ListboxItem
          onClick={onClickLoginButton}
          href={URL.LOGIN}
          className={cn(!isGuest ? 'hidden' : 'flex', 'mt-md')}
          startContent={<HiOutlineLogin size='20' className={cn(isRTL ? 'rotate-180' : 'rotate-0')} />}
          endContent={<HiOutlineChevronRight size='18' className={cn(isRTL ? 'rotate-180' : 'rotate-0')} />}>
          {t('login')}
        </ListboxItem>

        <ListboxItem
          onClick={onOpenLogoutModal}
          className={cn(isGuest ? 'hidden' : 'flex', 'mt-md')}
          startContent={<HiOutlineLogout size='20' className={cn(isRTL ? 'rotate-180' : 'rotate-0')} />}
          endContent={<HiOutlineChevronRight size='18' className={cn(isRTL ? 'rotate-180' : 'rotate-0')} />}>
          {t('logout')}
        </ListboxItem>
      </Listbox>

      <LogoutModal isOpen={isOpenLogoutModal} onClose={onCloseLogoutModal} />
      <NotificationsModal open={isOpenNotificationsModal} onCancel={onCloseNotificationsModal} />
    </div>
  );
};

const ProfilePage = () => {
  return (
    <Layout type='mobile_page_layout'>
      <Section name='content'>
        <div className='flex flex-col gap-lg px-sm py-2xl'>
          <UserDetails />
          <OptionsList />
        </div>
      </Section>
    </Layout>
  );
};

export default ProfilePage;
