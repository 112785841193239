import { EmptyStateTemplate } from 'components/empty-state-template';
import { EmptyBucketIcon } from 'pages/orders/icons/empty-bucket-icon';
import { useTranslation } from 'react-i18next';

export const OrdersEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateTemplate
      icon={<EmptyBucketIcon />}
      title={t('your_orders_will_show_here')}
      body={t('your_orders_will_show_here_body')}
    />
  );
};
