import { SVGProps } from 'react';

export const EmptyBucketIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='43' height='44' viewBox='0 0 43 44' fill='#160C45' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.03036 12.3398C8.54871 9.22971 11.2396 6.9502 14.3926 6.9502H28.6073C31.7603 6.9502 34.4512 9.22971 34.9695 12.3398L36.55 22.0002V32.7502C36.55 35.125 34.6248 37.0502 32.25 37.0502H10.75C8.37513 37.0502 6.44995 35.125 6.44995 32.7502V22.0002L8.03036 12.3398ZM14.3926 11.2502C13.3416 11.2502 12.4446 12.01 12.2719 13.0467L10.75 22.0002V24.1502H13.748C15.0214 24.1502 16.2663 24.5271 17.3258 25.2335L19.1147 26.4261C20.5591 27.389 22.4408 27.389 23.8852 26.4261L25.6741 25.2335C26.7336 24.5271 27.9785 24.1502 29.2519 24.1502H32.25V22.0002L30.7281 13.0467C30.5553 12.01 29.6583 11.2502 28.6073 11.2502H14.3926Z'
        fill='inherit'
      />
    </svg>
  );
};
