import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialMetaData, MetaData } from '../utils/page-meta-data';

export interface OrderDetailsItem {
  id: number;
  code: string;
  name: string;
  unit: string;
  price: number;
  quantity: number;
  receivedQty: number;
  total: number;
  history: {
    price: number;
    quantity: number;
    status: string;
  }[];
}

export interface ClonedOrderDetailsItem extends OrderDetailsItem {
  isEditEnabled: boolean;
  original: OrderDetailsItem;
  modified: Array<keyof OrderDetailsItem>;
}

interface SliceState {
  meta: MetaData;
  isModified: boolean;
  isModifyItemsEnabled: boolean;
  isSavingModifiedItems: boolean;
  clonedItems: ClonedOrderDetailsItem[];
}

const initialState: SliceState = {
  meta: initialMetaData,
  isModified: false,
  isModifyItemsEnabled: false,
  isSavingModifiedItems: false,
  clonedItems: [],
};

const orderDetailsPageSlice = createSlice({
  name: 'order-details-page',
  initialState,
  reducers: {
    resetOrderDetailsPageState(state) {
      state.meta = initialState.meta;
      state.isModified = initialState.isModified;
      state.isModifyItemsEnabled = initialState.isModifyItemsEnabled;
      state.isSavingModifiedItems = initialState.isSavingModifiedItems;
      state.clonedItems = initialState.clonedItems;
    },
    setPageHaveUnsavedChanges(state, action: PayloadAction<boolean>) {
      state.meta.pageHaveUnsavedChanges = action.payload;
    },
    setPrevUrl(state, action: PayloadAction<string>) {
      state.meta.prevUrl = action.payload;
    },
    setInitialClonedItems(state, action: PayloadAction<OrderDetailsItem[]>) {
      state.clonedItems = action.payload?.map((item: OrderDetailsItem) => {
        return {
          ...item,
          isEditEnabled: false,
          original: item,
          modified: [],
        };
      });
    },
    resetModifiedItems(state, action: PayloadAction<OrderDetailsItem[]>) {
      state.isModified = false;
      state.clonedItems = action.payload?.map((item: OrderDetailsItem) => {
        return {
          ...item,
          isEditEnabled: true,
          original: item,
          modified: [],
        };
      });
    },
    enableModifyItems(state) {
      state.isModifyItemsEnabled = true;
      state.clonedItems = state.clonedItems.map((item) => {
        return {
          ...item,
          isEditEnabled: true,
        };
      });
    },
    disableModifyItems(state) {
      state.isSavingModifiedItems = true;

      state.clonedItems = state.clonedItems.map((item) => {
        return {
          ...item,
          isEditEnabled: false,
        };
      });

      state.isSavingModifiedItems = false;
      state.isModifyItemsEnabled = false;
    },
    saveModifyItems(state) {
      state.isSavingModifiedItems = true;

      state.clonedItems = state.clonedItems.map((item) => {
        const modified: ClonedOrderDetailsItem['modified'] = [];

        if (item.price !== item.original.price) modified.push('price');
        if (item.quantity !== item.original.quantity) modified.push('quantity');

        return {
          ...item,
          isEditEnabled: false,
          modified,
        };
      });

      state.isModified = !!state.clonedItems.find((item) => item.modified.length);
      state.isSavingModifiedItems = false;

      state.isModifyItemsEnabled = false;
    },
    setOnItemQtyChange(state, action: PayloadAction<{ itemId: number; newQty: number }>) {
      const _items: ClonedOrderDetailsItem[] = JSON.parse(JSON.stringify(state.clonedItems));
      const item = _items.find((item) => item.id === action.payload.itemId);
      if (!item) return;

      const index = state.clonedItems.findIndex((i) => i.id === action.payload.itemId);
      _items[index].quantity = action.payload.newQty;
      _items[index].total = action.payload.newQty * _items[index].price;
      _items[index].modified.push('quantity');

      state.clonedItems = _items;
      state.isModified = true;
    },
    setOnItemPriceChange(state, action: PayloadAction<{ itemId: number; newPrice: number }>) {
      const _items: ClonedOrderDetailsItem[] = JSON.parse(JSON.stringify(state.clonedItems));
      const item = _items.find((item) => item.id === action.payload.itemId);
      if (!item) return;

      const index = state.clonedItems.findIndex((i) => i.id === action.payload.itemId);
      _items[index].price = action.payload.newPrice;
      _items[index].total = action.payload.newPrice * _items[index].quantity;
      _items[index].modified.push('price');

      state.clonedItems = _items;
      state.isModified = true;
    },
  },
});

export const {
  resetOrderDetailsPageState,
  setPageHaveUnsavedChanges,
  setPrevUrl,
  setOnItemQtyChange,
  setOnItemPriceChange,
  setInitialClonedItems,
  resetModifiedItems,
  enableModifyItems,
  disableModifyItems,
  saveModifyItems,
} = orderDetailsPageSlice.actions;

export default orderDetailsPageSlice.reducer;
