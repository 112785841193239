import { Drawer } from 'antd';
import { PageHeader } from 'components/page-header';
import { UnlockFullAccess } from 'components/unlock-full-access';
import { useAuth } from 'hooks/use-auth';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeTabletSidebar, setDesktopSidebar, toggleTabletSidebar } from 'redux/sidebar/reducer';
import { useBreakpoint } from 'theme/hooks';
import { DesktopMenu } from './components/menu';

export const SidebarPageLayout = ({ sections, pageTitle }: LayoutProps) => {
  const { isDesktop, isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { isGuest } = useAuth();

  const isDesktopSidebarOpen = useAppSelector((state) => state.sidebar.desktop.isOpen);
  const isTabletSidebarOpen = useAppSelector((state) => state.sidebar.tablet.isOpen);
  const sidebarWidth = 268;

  // Auto close and open sidebar in narrow and wide screen sizes
  useEffect(() => {
    if (isDesktop) {
      dispatch(setDesktopSidebar(true));
    }

    if (!isDesktop) {
      dispatch(setDesktopSidebar(false));
    }
  }, [isDesktop]);

  useEffect(() => {
    if (isTabletSidebarOpen) {
      dispatch(closeTabletSidebar());
    }
  }, [location.pathname]);

  if (isTablet) {
    return (
      <>
        {/* Start Sider */}
        {!isGuest && (
          <Drawer
            open={isTabletSidebarOpen}
            placement='left'
            width={sidebarWidth}
            classNames={{ header: '!hidden', body: '!p-0' }}
            onClose={() => dispatch(toggleTabletSidebar())}>
            <DesktopMenu />
          </Drawer>
        )}
        {/* End Sider */}

        <main className='flex flex-col gap-lg items-stretch min-h-screen bg-gray-50 p-lg h-screen w-screen overflow-hidden'>
          {/* Start Unlock Full Access */}
          <UnlockFullAccess />
          {/* End Unlock Full Access */}

          {/* Start Header */}
          <Section sections={sections} name='header'>
            {!!pageTitle && <PageHeader title={t(pageTitle)} />}
          </Section>
          {/* End Header */}

          {/* Start Content */}
          <section className='flex flex-col flex-1 overflow-y-auto'>
            <Section sections={sections} name='content' />
          </section>
          {/* End Content */}
        </main>
      </>
    );
  }

  return (
    <main className='flex min-h-screen'>
      {/* Start Sider */}
      {isDesktopSidebarOpen && !isGuest && (
        <aside className='shadow-lg z-10 overflow-auto h-screen' style={{ minWidth: sidebarWidth }}>
          <Section sections={sections} name='sider'>
            <DesktopMenu />
          </Section>
        </aside>
      )}
      {/* End Sider */}

      <main
        className='flex flex-col gap-lg items-stretch min-h-screen flex-1 bg-gray-50 p-lg h-screen overflow-hidden'
        style={{ width: !isDesktopSidebarOpen ? '100vw' : `calc(100vw - ${sidebarWidth}px)` }}>
        {/* Start Unlock Full Access */}
        <UnlockFullAccess />
        {/* End Unlock Full Access */}

        {/* Start Header */}
        <Section sections={sections} name='header'>
          {!!pageTitle && <PageHeader title={t(pageTitle)} />}
        </Section>
        {/* End Header */}

        {/* Start Content */}
        <section className='flex flex-col flex-1 overflow-y-auto'>
          <Section sections={sections} name='content' />
        </section>
        {/* End Content */}
      </main>
    </main>
  );
};
