import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useAuth } from 'hooks/use-auth';
import { TParam, usePageParams } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { getLimitParam } from 'utils/get-limit-param';
import { getPageParam } from 'utils/get-page-param';

const endpoint = 'oms/v1/suppliers/portal/orders';
export const getOrdersEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
    from: TParam;
    to: TParam;
    restaurantId: TParam;
    subEntityId: TParam;
    subEntityType: TParam;
    status: TParam;
  }
];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

const handleStatus = (statusPage: TParam, statusParam: TParam) => {
  if (statusParam) {
    return Array.isArray(statusParam) ? statusParam : [statusParam];
  }

  return statusPage;
};

function constructEndpoint() {
  return `${endpoint}`;
}

export function useGetOrdersApi(statusPage: TParam, options?: Options): ReturnType {
  const page = getPageParam();
  const limit = getLimitParam();
  const { isLoggedIn } = useAuth();
  const { getPageParams } = usePageParams();
  const { from, to, restaurantId, subEntityId, subEntityType, status: statusParam } = getPageParams();
  const fullEndpoint = constructEndpoint();

  const queryFn = async () => {
    try {
      const { data } = await http.get(fullEndpoint, {
        params: {
          page,
          limit,
          from,
          to,
          restaurantId,
          subEntityId,
          subEntityType,
          status: handleStatus(statusPage, statusParam),
        },
      });

      // Update the status value for rejected orders
      data.data = data?.data?.map((order: any) => {
        if (order?.status === 'canceled' && !order?.updatedByUser?.isRestaurantUser) {
          return { ...order, status: 'rejected' };
        }

        return { ...order };
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        page,
        limit,
        from,
        to,
        restaurantId,
        subEntityId,
        subEntityType,
        status: handleStatus(statusPage, statusParam),
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
