import { cn, InputProps } from '@nextui-org/react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Input } from 'theme/components';

interface PinInputFieldProps extends InputProps {
  index: number;
  mask?: boolean;
  type?: 'alphanumeric' | 'number';
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
}

const PinInputField = forwardRef<HTMLInputElement, PinInputFieldProps>(
  ({ index, mask, type = 'number', handleChange, handleKeyDown, ...props }, ref) => {
    useEffect(() => {
      if (type === 'alphanumeric') {
        props.inputMode = 'text';
      } else {
        props.inputMode = 'numeric';
      }
    }, [type, props]);

    return (
      <Input
        {...props}
        ref={ref}
        style={{ textAlign: 'center' }}
        maxLength={1}
        type={mask ? 'password' : 'text'}
        onChange={(e) => handleChange(e, index)}
        onKeyDown={(e) => handleKeyDown(e, index)}
      />
    );
  }
);

PinInputField.displayName = 'PinInputField';

interface PinInputProps extends Omit<InputProps, 'onChange'> {
  length: number;
  mask?: boolean;
  type?: 'alphanumeric' | 'number';
  size?: 'sm' | 'md' | 'lg';
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  gap?: 'xs' | 'sm' | 'md' | 'lg';
  onComplete?: (value: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PinInput: React.FC<PinInputProps> = ({
  length,
  mask = false,
  type = 'number',
  size = 'md',
  defaultValue = '',
  value = '',
  placeholder = '',
  gap = 'sm',
  onComplete,
  onChange,
  ...props
}) => {
  const [values, setValues] = useState<string[]>(Array(length).fill(''));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const focusFirstEmptyInput = (valuesArray: string[]) => {
    const firstEmptyIndex = valuesArray.findIndex((val) => val === '');
    if (firstEmptyIndex !== -1 && inputsRef.current[firstEmptyIndex]) {
      inputsRef.current[firstEmptyIndex].focus();
    }
  };

  useEffect(() => {
    if (inputsRef.current[0] && !value && !defaultValue && props.autoFocus) {
      inputsRef.current[0].focus();
    }
  }, [value, defaultValue, props.autoFocus]);

  useEffect(() => {
    // Give priority to value over defaultValue
    if (value) {
      const newValues = value.split('').slice(0, length);
      const paddedValues = [...newValues, ...Array(length - newValues.length).fill('')];
      setValues(paddedValues);
      focusFirstEmptyInput(paddedValues);
      return;
    }

    if (defaultValue) {
      const newValues = defaultValue.split('').slice(0, length);
      const paddedValues = [...newValues, ...Array(length - newValues.length).fill('')];
      setValues(paddedValues);
      focusFirstEmptyInput(paddedValues);

      if (newValues.length === length && onComplete) {
        onComplete(newValues.join(''));
      }
    }
  }, [value, defaultValue, length, onComplete]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;
    if (type === 'number' && !/^\d*$/.test(value)) return;
    if (type === 'alphanumeric' && !/^[a-zA-Z0-9]*$/.test(value)) return;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    if (onChange) {
      const syntheticEvent = {
        target: {
          value: newValues.join(''),
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(syntheticEvent);
    }

    if (value && index < length - 1) {
      inputsRef.current[index + 1].focus();
    }

    if (newValues.every((val) => val) && onComplete) {
      onComplete(newValues.join(''));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text').slice(0, length);
    if (type === 'number' && /\D/.test(pasteData)) return;
    if (type === 'alphanumeric' && /[^a-zA-Z0-9]/.test(pasteData)) return;

    const newValues = pasteData.split('').slice(0, length);
    const paddedValues = [...newValues, ...Array(length - newValues.length).fill('')];
    setValues(paddedValues);

    newValues.forEach((value, i) => {
      if (inputsRef.current[i]) {
        inputsRef.current[i].value = value;
      }
    });

    if (onChange) {
      const syntheticEvent = {
        target: {
          value: newValues.join(''),
        },
      } as React.ChangeEvent<HTMLInputElement>;

      onChange(syntheticEvent);
    }

    if (newValues.every((val) => val) && newValues.join('').length === length && onComplete) {
      onComplete(newValues.join(''));
    }
  };

  return (
    <div className={cn('flex', `gap-${gap}`)}>
      {Array.from({ length }).map((_, index) => (
        <PinInputField
          key={index}
          ref={(el: HTMLInputElement) => {
            inputsRef.current[index] = el;
          }}
          index={index}
          mask={mask}
          type={type}
          size={size}
          value={values[index]}
          placeholder={placeholder}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          onPaste={index === 0 ? handlePaste : undefined}
          {...props}
        />
      ))}
    </div>
  );
};
