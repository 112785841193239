import { hasFlag } from 'country-flag-icons';
import * as FlagIcons from 'country-flag-icons/react/3x2';
import { useMemo } from 'react';

interface CountryFlagIconProps {
  countryCode: string;
  size?: 'sm' | 'md' | 'lg';
}

export const CountryFlagIcon = ({ countryCode = 'AC', size }: CountryFlagIconProps) => {
  if (!hasFlag(countryCode)) return null;

  const sizeMap = useMemo(() => {
    switch (size) {
      case 'lg':
        return 25;

      case 'md':
        return 20;

      case 'sm':
      default:
        return 16;
    }
  }, [size]);

  const FlagIcon = FlagIcons?.[countryCode as keyof typeof FlagIcons];

  return (
    <div>
      <FlagIcon width={sizeMap} />
    </div>
  );
};
