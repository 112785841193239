import { yupResolver } from '@hookform/resolvers/yup';
import { HTMLProps } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'theme/components';
import * as yup from 'yup';
import { TermsAndConditions } from './components/terms-n-conditions';
import { useGetDefaultLoginValue } from './hooks/use-get-default-login-value';
import { useSignInCheck } from './hooks/use-sign-in-check';

type FormValues = {
  email: string;
};

export const LoginEmailForm = (props: HTMLProps<HTMLFormElement>) => {
  const { isLoading: isLoadingSignInCheck, onSignInCheck } = useSignInCheck();
  const { email } = useGetDefaultLoginValue();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('please-enter-email-address') as string)
      .email(t('please-enter-a-valid-email') as string),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      email,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const value = data?.email;
    onSignInCheck('email', value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate {...props}>
      <Controller
        control={control}
        name='email'
        render={({ field: { name, value, onChange, onBlur } }) => (
          <Input
            type='email'
            variant='bordered'
            size='lg'
            label={t('email')}
            placeholder='email@example.com'
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            isInvalid={!!errors.email}
            errorMessage={errors.email?.message}
          />
        )}
      />

      <TermsAndConditions className='mt-lg' />
      <Button type='submit' color='primary' fullWidth className='mt-1' size='lg' isLoading={isLoadingSignInCheck}>
        {t('continue')}
      </Button>
    </form>
  );
};
