import { cn } from '@nextui-org/react';
import { TabBar } from 'antd-mobile';
import { UnlockFullAccess } from 'components/unlock-full-access';
import { useAuth } from 'hooks/use-auth';
import { useViewportHeight } from 'hooks/use-viewport-height';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineShoppingCart, HiOutlineTag, HiOutlineTruck, HiOutlineUser } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageKeys, URL } from 'utils/constants';

type Tab = {
  key: string;
  title: string;
  icon: JSX.Element;
  hidden?: boolean;
  disabled?: boolean;
};

export const MobilePageLayout = ({ sections }: LayoutProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const viewportHeight = useViewportHeight();

  const { isGuest } = useAuth();
  const guestOrderURL = localStorage.getItem(LocalStorageKeys.guest_order_url);

  const ordersPath = useMemo(() => {
    return isGuest && guestOrderURL ? guestOrderURL : URL.ORDERS;
  }, [guestOrderURL]);

  const tabs: Tab[] = [
    {
      key: ordersPath,
      title: t('orders'),
      icon: <HiOutlineTruck />,
    },
    {
      key: URL.PRODUCTS,
      title: t('products'),
      icon: <HiOutlineTag size={23} />,
      hidden: isGuest,
    },
    {
      key: URL.MARKETING,
      title: t('marketing'),
      icon: <HiOutlineShoppingCart size={23} />,
      hidden: isGuest,
    },
    {
      key: URL.PROFILE,
      title: t('profile'),
      icon: <HiOutlineUser />,
    },
  ];

  const onTabItemChange = (key: string) => {
    const isDisabled = !!tabs.find((t) => t.disabled);

    if (isDisabled) return;
    navigate(key);
  };

  return (
    <main
      className='grid grid-rows-[auto_1fr_auto] overflow-hidden'
      style={{ minHeight: viewportHeight, maxHeight: viewportHeight }}>
      <header>
        <UnlockFullAccess />
        <Section sections={sections} name='header' />
      </header>

      <section id='scrollableSection' className='grid grid-rows-[1fr] overflow-auto'>
        <Section sections={sections} name='content' />
      </section>

      <footer>
        <Section sections={sections} name='footer'>
          <TabBar
            safeArea
            activeKey={pathname}
            onChange={onTabItemChange}
            className='border-t-1 border-t-gray-300 py-3xs pb-md'>
            {tabs.map((item) => {
              if (item.hidden) return null;
              return (
                <TabBar.Item
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                  className={cn(pathname === item.key ? 'text-secondary-500' : 'text-default-500', 'font-semibold')}
                />
              );
            })}
          </TabBar>
        </Section>
      </footer>
    </main>
  );
};
