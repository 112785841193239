import { usePageParams } from 'hooks/use-page-params';
import _toString from 'lodash/toString';
import { useEffect, useState } from 'react';
import { LocalStorageKeys } from 'utils/constants';
import { useAuth } from './use-auth';

export const useSetAccessTokenFromSearchParams = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { getPageParams, setPageParams } = usePageParams();
  const params = getPageParams();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const hasNewToken = new Promise<string>((resolve, reject) => {
      const accessToken = params?.['access_token'];

      if (accessToken) resolve(_toString(accessToken));

      if (!accessToken && isLoggedIn) {
        reject({ type: 'info', message: 'Support Login: access_token already stored.' });
      }

      if (!accessToken && !isLoggedIn) {
        reject({ type: 'warn', message: 'Support Login: access_token param is not provided.' });
      }
    });

    hasNewToken
      .then((accessToken) => {
        localStorage.setItem(LocalStorageKeys.access_token, accessToken);
        localStorage.setItem(LocalStorageKeys.is_support_login, 'true');

        localStorage.removeItem(LocalStorageKeys.is_guest_token);

        delete params?.['access_token'];
        setPageParams(params);

        window.location.reload();
      })
      .catch((reason) => {
        if (reason?.type === 'info' && process.env.NODE_ENV === 'development') {
          console.log(reason?.message);
        }

        if (reason?.type === 'warn' && process.env.NODE_ENV === 'development') {
          console.warn(reason?.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
  };
};
