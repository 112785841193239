import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetOrderDetailsByIdApi } from './use-get-order-details-by-id-api';

const endpoint = 'oms/v1/suppliers/portal/orders/reject';

type TData = Record<string, any>;
type TError = AxiosError;

interface BodyType {
  ids: number[];
  reason: string;
}

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useRejectOrderApi(options?: Options): ReturnType {
  const { orderId } = useParams<{ orderId: string }>();
  const { refetch: refetchGetOrderDetailsById } = useGetOrderDetailsByIdApi(orderId as string, { enabled: false });
  const { t } = useTranslation();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.patch(endpoint, body);

      // Refetch the order details
      refetchGetOrderDetailsById();

      message.success(t('order-have-been-rejected'));

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
