import { useCurrentUser } from 'hooks/use-current-user';
import { useIsRTL } from 'hooks/use-is-rtl';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGetCurrency = () => {
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const isRTL = useIsRTL();

  const currency = useMemo(() => {
    if (!user?.country?.currency) return t('aed');
    if (isRTL) return user?.country?.currency?.ar;
    return user?.country?.currency?.en;
  }, [user]);

  return {
    currency,
  };
};
