type KeyValuePairs = { [key: string]: any };

/**
 * Compares two objects and returns a new object containing only
 * the keys and values from the secondary object that also exist
 * in the primary object.
 *
 * @param {KeyValuePairs} primary - The primary object to be compared against.
 * @param {KeyValuePairs} secondary - The secondary object to compare.
 *
 * @returns {KeyValuePairs} A new object with key-value pairs from the secondary object
 *                          that also exist in the primary object.
 *
 * Note: This function does not perform deep comparison. If values are nested objects,
 *       it does not check keys within them.
 */
export function compareObjects(primary: KeyValuePairs, secondary: KeyValuePairs): KeyValuePairs {
  return Object.keys(secondary).reduce((result: KeyValuePairs, key: string) => {
    if (key in primary) {
      result[key] = secondary[key];
    }
    return result;
  }, {});
}
