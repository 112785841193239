import { formatDateTime } from '@elkaso-app/web-design';
import { cn } from '@nextui-org/react';
import { orderStatusMap, orderStatusStyleProps } from 'pages/orders/utils/order-status';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderStatusCardProps extends HTMLProps<HTMLDivElement> {
  status: string;
  orderCode: string;
  createdAt: string;
}

export const OrderStatusCard = ({ status, orderCode, createdAt, className, ...rest }: OrderStatusCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('flex justify-between items-center p-md gap-xs rounded-md', className)}
      style={{ backgroundColor: orderStatusStyleProps[status]?.bgColor }}
      {...rest}>
      <div className='flex flex-col gap-xs items-start me-xs'>
        <p className='text-sm font-bold'>
          {t('order')} #{orderCode}
        </p>
        <p className='text-xs'>
          {t('creation-date')}: {formatDateTime(createdAt, 'D-M-YYYY [at] h:mm A')}
        </p>
      </div>

      <p className='text-sm font-bold' style={{ color: orderStatusStyleProps[status]?.color }}>
        {t(orderStatusMap?.[status]?.name)}
      </p>
    </div>
  );
};
