import { useSendOTPApi } from 'apis/auth/use-send-otp-api';
import { useNavigate } from 'react-router-dom';
import { SessionStorageKeys, URL } from 'utils/constants';
import { gaLogEvent } from 'utils/ga-log-event';
import { SessionHandler } from 'utils/session-handler';
import { LoginType } from '../index';

export const useSendOtp = () => {
  const navigate = useNavigate();
  const sessionHandler = new SessionHandler(SessionStorageKeys.otp_expiration_time);

  const { isLoading, mutate: sendOTP } = useSendOTPApi();

  const onSendOtp = (loginType: LoginType, loginValue: string) => {
    const variables = { body: { [loginType]: loginValue } };

    sendOTP(variables, {
      onSuccess(data) {
        sessionHandler.setSession(data?.secondsRemaining);
        navigate(URL.VERIFY_CODE + `?loginType=${loginType}&loginValue=${loginValue}`);

        gaLogEvent('action_send_otp', {
          login_credentials: loginValue,
        });
      },
      onError(error) {
        // CASE [1]: Trying to send another OTP while the remaining time still counting.
        if (error.response?.status === 403) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          navigate(URL.VERIFY_CODE + `?loginType=${loginType}&loginValue=${loginValue}`);
          return;
        }

        // CASE [2]: Locked for one hour due to many attempts incorrect OTPs.
        if (error.response?.status === 429) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          return;
        }

        // CASE [4]: Any other error.
        if (error.response) {
          sessionHandler.setSession(error?.response?.data?.secondsRemaining);
          return;
        }
      },
    });
  };

  return {
    isLoading,
    onSendOtp,
  };
};
