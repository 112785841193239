import { yupResolver } from '@hookform/resolvers/yup';
import { MobileInput } from 'components/mobile-input';
import { HTMLProps } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'theme/components';
import * as yup from 'yup';
import { TermsAndConditions } from './components/terms-n-conditions';
import { useGetDefaultLoginValue } from './hooks/use-get-default-login-value';
import { useSignInCheck } from './hooks/use-sign-in-check';

type FormValues = {
  phoneNumber: string;
};

export const LoginPhoneNumberForm = (props: HTMLProps<HTMLFormElement>) => {
  const { isLoading: isLoadingSignInCheck, onSignInCheck } = useSignInCheck();
  const { phoneNumber } = useGetDefaultLoginValue();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .required(t('please_enter_phone_number') as string)
      .matches(/^\+?\d{1,3}-?\d{3,}$/, t('please-enter-valid-phone-number') as string),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const value = data?.phoneNumber;
    onSignInCheck('phoneNumber', value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate {...props}>
      <Controller
        control={control}
        name='phoneNumber'
        render={({ field: { name, value, onChange, onBlur } }) => (
          <MobileInput
            size='lg'
            variant='bordered'
            label={
              <div className='flex items-center gap-[40px] rtl:gap-[55px] text-xs mb-2'>
                <p>{t('country_code')}</p>
                <p>{t('phone_number')}</p>
              </div>
            }
            placeholder='ex. 523348862'
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            errorMessage={errors.phoneNumber?.message}
            isInvalid={!!errors.phoneNumber}
          />
        )}
      />

      <TermsAndConditions className='mt-lg' />
      <Button type='submit' color='primary' fullWidth size='lg' className='mt-1' isLoading={isLoadingSignInCheck}>
        {t('continue')}
      </Button>
    </form>
  );
};
