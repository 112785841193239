import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { UseQueryOptions, UseQueryResult } from 'react-query';

const endpoint = 'oms/v1/aggregated-sheet';
export const getAggregateOrdersEndpointIdentifier = endpoint;

type TData = AxiosResponse<any, any>;
type TError = AxiosError;
type TQueryKey = [string];
type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

export function useGetAggregateOrdersApi(restaurantId: string, deliveryDate: string, options?: Options): ReturnType {
  const queryFn = async () => {
    try {
      const response = await http.get(endpoint, {
        responseType: 'arraybuffer',
        params: {
          sheetType: 'perBrand',
          restaurantId,
          deliveryDate,
        },
      });
      if (response?.status == 200) fileDownload(response.data, `${deliveryDate}.xlsx`);

      return response;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper([endpoint], queryFn, {
    enabled: false,
    ...options,
  });
}
