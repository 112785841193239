import { useIsRTL } from 'hooks/use-is-rtl';
import { LANGUAGES, LocalStorageKeys } from 'utils/constants';

export const useChangeLanguage = () => {
  const { ar, en } = LANGUAGES;
  const isRTL = useIsRTL();

  const onToggleLang = () => {
    if (!isRTL) {
      localStorage.setItem(LocalStorageKeys.language, ar.code);
    }

    if (isRTL) {
      localStorage.setItem(LocalStorageKeys.language, en.code);
    }

    window.location.reload();
  };

  const onArLang = () => {
    if (isRTL) return;
    localStorage.setItem(LocalStorageKeys.language, ar.code);
    window.location.reload();
  };

  const onEnLang = () => {
    if (!isRTL) return;
    localStorage.setItem(LocalStorageKeys.language, en.code);
    window.location.reload();
  };

  return { onToggleLang, onArLang, onEnLang };
};
