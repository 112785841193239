import { Table, type TableProps } from 'antd';
import { TextCell } from 'components/text-cell';
import { useGetCurrency } from 'hooks/use-get-currency';
import { t } from 'i18next';
import { useMemo } from 'react';
import { formatPrice } from 'utils/format-price';
import { Order } from '../types';

type ViewOrderItemsProps = {
  items: Order['items'];
};

export const ViewOrderItems = ({ items }: ViewOrderItemsProps) => {
  const { currency } = useGetCurrency();

  const columns: TableProps<Item>['columns'] = [
    {
      title: t('item-code'),
      render: (_, record) => <TextCell value={record.code} />,
    },
    {
      title: t('item-name'),
      render: (_, record) => <TextCell value={record.name} />,
    },
    {
      title: t('unit'),
      render: (_, record) => <TextCell value={record.unit} />,
    },
    {
      title: t('unit-price'),
      render: (_, record) => <TextCell value={record.price} />,
    },
    {
      title: t('qty'),
      render: (_, record) => <TextCell value={record.quantity} />,
    },
    {
      title: t('total-price'),
      render: (_, record) => <TextCell value={record.total} />,
    },
  ];

  interface Item extends Omit<Order['items'][0], 'price' | 'total'> {
    price: string;
    total: string;
  }

  const _items = useMemo<Item[]>(
    () =>
      items?.map((item) => {
        return {
          key: item.id,
          ...item,
          price: formatPrice(item?.price ?? 0),
          total: formatPrice(item?.total ?? 0, currency),
        };
      }),
    [items]
  );

  return (
    <Table
      columns={columns}
      dataSource={_items}
      pagination={false}
      size='small'
      className='border-secondary-500 border-s-3'
    />
  );
};
