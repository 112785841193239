import type { TableColumnsType } from 'antd';
import { Empty, Image, Table } from 'antd';
import { useGetProductsApi } from 'apis/products/use-get-products-api';
import { TextCell } from 'components/text-cell';
import dayjs from 'dayjs';
import { useGetCurrency } from 'hooks/use-get-currency';
import { usePageTableSettings } from 'hooks/use-page-table-settings';
import { Layout, Section } from 'layout';
import { ProductsActionsCell } from 'pages/products/components/products-actions-cell';
import { ProductsStatusCell } from 'pages/products/components/products-status-cell';
import { UpdateProductsModal } from 'pages/products/components/update-products-modal';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/format-price';
import { Product } from '../../type';

export const DesktopProductsListScreen = () => {
  const { t } = useTranslation();
  const { currency } = useGetCurrency();

  const { data: productsData, isLoading, isFetching } = useGetProductsApi();

  const { pagination, scroll, loading } = usePageTableSettings({
    data: productsData,
    total: productsData?.total,
    isLoading: isLoading,
    isFetching: isFetching,
  });

  const columns: TableColumnsType<Product> = [
    {
      title: t('image'),
      dataIndex: 'imageUrl',
      render: (val) => (
        <Image
          width={46}
          height={46}
          src={val}
          preview={!!val}
          className='rounded-md'
          fallback='https://placehold.co/600?text=img&font=roboto'
        />
      ),
    },
    {
      title: t('name-en'),
      dataIndex: 'nameEn',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('name-ar'),
      dataIndex: 'nameAr',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('brand-en'),
      dataIndex: 'brandEn',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('brand-ar'),
      dataIndex: 'brandAr',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('unit'),
      dataIndex: ['unit', 'name'],
      render: (val) => <TextCell value={t(val)} minWidth={60} />,
    },
    {
      title: t('price'),
      render: (_, record) => <TextCell value={formatPrice(record.defaultPrice, currency)} minWidth={80} />,
    },
    {
      title: t('sku'),
      dataIndex: 'code',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('specs'),
      dataIndex: 'specs',
      render: (val) => <TextCell value={val} />,
    },
    {
      title: t('status'),
      render: (_, record) => <ProductsStatusCell product={record} />,
    },
    {
      render: (_, record) => <ProductsActionsCell product={record} />,
    },
  ];

  const items = useMemo<Product[]>(
    () =>
      productsData?.data?.map((item: Product) => {
        return {
          key: item.id,
          ...item,
          createdAt: dayjs(item?.createdAt).format('DD-MM-YYYY | h:mm A'),
        };
      }),
    [productsData]
  );

  return (
    <Layout type='sidebar_page_layout' pageTitle='products'>
      <Section name='content'>
        <div className='page'>
          <div className='page-header'>
            <div className='flex items-start justify-between w-full flex-wrap gap-sm'>
              <div className='page-title-container'>
                <p className='page-title'>{t('products-list')}</p>
                <p className='page-subtitle'>{t('products-list-sup-title')}</p>
              </div>

              <div className='page-header-buttons-container'>
                <UpdateProductsModal hasProducts={productsData?.total} />
              </div>
            </div>
          </div>

          <div className='page-body' id='sp_page_body'>
            <Table
              columns={columns}
              dataSource={items}
              size='middle'
              scroll={scroll}
              loading={loading}
              pagination={pagination}
              locale={{
                emptyText: !isLoading ? (
                  <Empty className='py-[150px]' description={t('no_products_description')} />
                ) : (
                  <div className='py-[150px]' />
                ),
              }}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
};
