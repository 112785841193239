import { analytics } from 'configs/firebase';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { useCurrentUser } from 'hooks/use-current-user';
import { useEffect } from 'react';
import { useAuth } from './use-auth';

export const useInitiateFirebaseUser = () => {
  const { user } = useCurrentUser();
  const { isGuest } = useAuth();

  useEffect(() => {
    if (!user || !analytics) return;

    const userObj = {
      name: user?.name,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      supplierName: user?.supplier?.name,
      isGuest: isGuest,
    };

    setUserId(analytics, user?.id);
    setUserProperties(analytics, userObj);
  }, [user]);
};
