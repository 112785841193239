import { cn } from '@nextui-org/react';
import { HTMLProps } from 'react';

interface InfoItemProps extends HTMLProps<HTMLDivElement> {
  label: string;
  info: string | number;
}

export const InfoItem = ({ label, info, className, ...rest }: InfoItemProps) => {
  return (
    <div className={cn('flex justify-between items-center', className)} {...rest}>
      <p>{label}</p>
      <p>{info}</p>
    </div>
  );
};
