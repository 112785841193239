import { cn } from '@nextui-org/react';
import { HTMLProps } from 'react';

// Title
const Title = ({ children, className, ...rest }: HTMLProps<HTMLHeadingElement>) => {
  return (
    <h1 className={cn('text-3xl font-bold text-blue-500 uppercase tracking-tighter', className)} {...rest}>
      {children}
    </h1>
  );
};

OnboardingForm.Title = Title;

// Sub-title
const Subtitle = ({ children, className, ...rest }: HTMLProps<HTMLParagraphElement>) => {
  return (
    <p className={cn('mt-xs text-gray-600 whitespace-pre-line', className)} {...rest}>
      {children}
    </p>
  );
};

OnboardingForm.Subtitle = Subtitle;

// Container
export function OnboardingForm({ children, className, ...rest }: HTMLProps<HTMLDivElement>) {
  return (
    <div className={cn('min-w-full', className)} {...rest}>
      {children}
    </div>
  );
}
