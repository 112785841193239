import { SearchOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import type { DatePickerProps } from 'antd';
import { Alert, AutoComplete, Button, DatePicker, Form, message, Modal } from 'antd';
import http from 'apis/config/http-service';
import { useGetAggregateOrdersApi } from 'apis/orders/use-get-aggregate-orders-api';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useDisclosure } from 'hooks/use-disclosure';
import _debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { useBreakpoint } from 'theme/hooks';
import { gaLogEvent } from 'utils/ga-log-event';
import * as yup from 'yup';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Dubai');

type TOption = {
  key: string;
  value: string;
};

type FormInputs = {
  restaurant: TOption | null;
  deliveryDate: DatePickerProps['value'];
};

const defaultValues: FormInputs = {
  restaurant: null,
  deliveryDate: null,
};

const schema = yup.object().shape({
  restaurant: yup.object().nullable().required('brand-name-is-required'),
  deliveryDate: yup.date().required('date-is-required'),
});

export const AggregateOrdersModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [statusCode, setStatusCode] = useState<number>();
  const { isMobile } = useBreakpoint();

  const { control, reset, handleSubmit, watch } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const restaurantId = watch('restaurant');
  const deliveryDate = watch('deliveryDate');

  const {
    isLoading,
    isFetching,
    refetch: getDownloadAggregateOrdersApi,
    data,
  } = useGetAggregateOrdersApi(restaurantId?.key ?? '', dayjs(deliveryDate as Dayjs).format());

  useEffect(() => {
    setStatusCode(data?.status);
  }, [data]);

  const onSubmit: SubmitHandler<FormInputs> = () => {
    gaLogEvent('action_export_aggregate_orders');
    getDownloadAggregateOrdersApi();
  };

  const _onOpen = () => {
    gaLogEvent('action_aggregate_popup');
    onOpen();
  };

  const _onClose = () => {
    onClose();
    setStatusCode(undefined);
    reset();
  };

  const [options, setOptions] = useState<TOption[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (searchText: string) => {
    setLoading(true);
    try {
      const { data } = await http.get('ums/v1/suppliers/restaurants', {
        params: { name: searchText, page: 1, limit: 100 },
      });

      const values: TOption[] = data?.data?.map((item: any) => {
        return { key: item?.id, value: item?.name };
      });

      setOptions(values);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(_debounce(fetchData, 500), []);

  const handleSearch = (value: string) => {
    if (value) {
      debouncedFetchData(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <>
      <Button icon={<HiOutlineDocumentDownload size='18' />} onClick={_onOpen} size='large' type='default'>
        {!isMobile && t('aggregate-orders')}
      </Button>

      <Modal
        open={isOpen}
        onCancel={_onClose}
        destroyOnClose
        title={
          <div className='modal-header'>
            <div className='modal-title'>{t('aggregate-orders-report')}</div>
            <div className='modal-subtitle'>{t('sub-aggregate-orders-report')}</div>
          </div>
        }
        footer={
          <div className='modal-footer'>
            <Button type='default' onClick={_onClose}>
              {t('cancel')}
            </Button>

            <Button
              type='primary'
              onClick={handleSubmit(onSubmit)}
              disabled={!restaurantId || !deliveryDate}
              loading={isLoading || isFetching}>
              {t('download-report')}
            </Button>
          </div>
        }>
        <div className='flex flex-col gap-md'>
          {statusCode === 200 && (
            <Alert
              type='success'
              message={t('order_aggregation_complete')}
              description={t('order_aggregation_complete_body')}
              showIcon
            />
          )}

          {statusCode === 204 && (
            <Alert type='warning' message={t('no_orders_found')} description={t('no_orders_found_body')} showIcon />
          )}

          <Form onFinish={handleSubmit(onSubmit)} layout='vertical' autoComplete='off' size='large'>
            <Controller
              control={control}
              name='restaurant'
              render={({ field: { onChange, value, name }, fieldState: { invalid, error } }) => (
                <Form.Item
                  label={t('brand-name')}
                  name={name}
                  valuePropName={value?.value}
                  validateStatus={invalid ? 'error' : ''}
                  help={t(error?.message as string)}>
                  <AutoComplete
                    value={value}
                    options={options}
                    onSearch={handleSearch}
                    onChange={(_, option) => onChange(option)}
                    placeholder={t('type-name-here')}
                    notFoundContent={loading ? 'Loading...' : 'No results found'}
                    suffixIcon={<SearchOutlined style={{ fontSize: 15 }} />}
                  />
                </Form.Item>
              )}
            />

            <Controller
              control={control}
              name='deliveryDate'
              render={({ field: { onChange, name, onBlur, value }, fieldState: { invalid, error } }) => (
                <Form.Item
                  label={t('delivery-date')}
                  name={name}
                  valuePropName={name}
                  validateStatus={invalid ? 'error' : ''}
                  help={t(error?.message as string)}>
                  <DatePicker onChange={onChange} onBlur={onBlur} value={value} style={{ width: '100%' }} />
                </Form.Item>
              )}
            />
          </Form>
        </div>
      </Modal>
    </>
  );
};
