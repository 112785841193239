import { BrandLogo } from '@elkaso-app/web-design';
import Image_01 from 'assets/img/1.png';
import { useViewportHeight } from 'hooks/use-viewport-height';
import { Section } from 'layout/index';
import { LayoutProps } from 'layout/types';
import { useBreakpoint } from 'theme/hooks';

export const GetStartedLayout = ({ sections }: LayoutProps) => {
  const { isTablet, isMobile } = useBreakpoint();
  const viewportHight = useViewportHeight();

  if (isMobile) {
    return (
      <main>
        <section style={{ minHeight: viewportHight }}>
          <div
            className='h-screen bg-cover bg-[37%] bg-no-repeat flex flex-col items-center justify-between pt-4xl pb-3xl'
            style={{
              backgroundImage: 'url(' + Image_01 + ')',
            }}>
            <div className='absolute bg-blue-500 opacity-80 top-0 right-0 bottom-0 left-0'></div>

            <div className='z-10'>
              <BrandLogo fill='white' w='168.83px' h='33px' />
            </div>

            <div className='w-full z-10'>
              <Section sections={sections} name='content' />
            </div>
          </div>
        </section>
      </main>
    );
  }

  if (isTablet) {
    return (
      <main>
        <section style={{ minHeight: viewportHight }}>
          <div className='flex h-screen'>
            <div
              className='flex justify-center items-center flex-1 bg-cover bg-center bg-no-repeat py-xl'
              style={{ backgroundImage: 'url(' + Image_01 + ')' }}>
              <div className='bg-white p-lg rounded-md w-[500px]'>
                <Section sections={sections} name='content' />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }

  return (
    <main>
      <section style={{ minHeight: viewportHight }}>
        <div className='flex h-screen'>
          <div className='flex justify-center items-center w-[35%] p-2xl'>
            <Section sections={sections} name='content' />
          </div>

          <div
            className='flex justify-center items-center w-[65%] bg-cover bg-center bg-no-repeat'
            style={{ backgroundImage: 'url(' + Image_01 + ')' }}
          />
        </div>
      </section>
    </main>
  );
};
