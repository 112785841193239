import { useLocation } from 'react-router-dom';
import { OrdersType } from '../../../redux/orders/orders-list-page-reducer';
import { URL } from '../../../utils/constants';

export const useGetActiveOrdersTabName = (): OrdersType => {
  const { pathname } = useLocation();

  if (pathname.includes(URL.ORDERS_CONFIRMED)) return 'confirmed';
  if (pathname.includes(URL.ORDERS_HISTORY)) return 'history';
  return 'new';
};
