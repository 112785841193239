import { useAuth } from 'hooks/use-auth';
import { Navigate, useLocation } from 'react-router-dom';
import { guestWhitelist, URL } from 'utils/constants';

interface IProtectedRoute {
  component: JSX.Element;
}

export const ProtectedRoute = ({ component }: IProtectedRoute) => {
  const { isLoggedIn, isGuest } = useAuth();
  const location = useLocation();
  const isWhitelistRoute = guestWhitelist.find((route) => location.pathname.startsWith(route));

  if (isLoggedIn && isGuest && isWhitelistRoute) {
    return component;
  }

  if (isLoggedIn && isGuest && !isWhitelistRoute) {
    return <Navigate to={URL.LOGIN} state={{ from: location }} replace />;
  }

  if (!isLoggedIn) {
    return <Navigate to='/' />;
  }

  return component;
};
