import { Icon, IconProps } from '@elkaso-app/web-design';

export const LinkedinIcon = (props: IconProps) => {
  return (
    <Icon width='20px' height='20px' viewBox='0 0 16 16' fill='white' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.97862e-07 1.33673C1.97862e-07 0.982207 0.140834 0.642205 0.391519 0.39152C0.642204 0.140835 0.982205 1.78205e-06 1.33673 1.78205e-06H14.6618C14.8375 -0.000285165 15.0115 0.0340839 15.1739 0.101141C15.3363 0.168198 15.4839 0.266627 15.6082 0.390792C15.7325 0.514956 15.8311 0.662419 15.8983 0.824736C15.9655 0.987054 16.0001 1.16104 16 1.33673V14.6618C16.0002 14.8375 15.9657 15.0116 15.8986 15.174C15.8315 15.3364 15.733 15.4839 15.6087 15.6082C15.4845 15.7325 15.337 15.8311 15.1746 15.8983C15.0123 15.9655 14.8383 16.0001 14.6625 16H1.33673C1.16112 16 0.987243 15.9654 0.825016 15.8982C0.662789 15.831 0.515397 15.7324 0.391261 15.6082C0.267125 15.484 0.168678 15.3366 0.101544 15.1743C0.0344096 15.012 -9.53422e-05 14.8381 1.97862e-07 14.6625V1.33673ZM6.33309 6.10037H8.49964V7.18836C8.81236 6.56291 9.61236 6 10.8145 6C13.1193 6 13.6655 7.24582 13.6655 9.53164V13.7658H11.3331V10.0524C11.3331 8.75055 11.0204 8.016 10.2262 8.016C9.12436 8.016 8.66618 8.808 8.66618 10.0524V13.7658H6.33309V6.10037ZM2.33309 13.6662H4.66618V6H2.33309V13.6655V13.6662ZM5 3.49964C5.0044 3.6994 4.96885 3.89803 4.89545 4.08387C4.82204 4.2697 4.71226 4.43901 4.57253 4.58184C4.43281 4.72468 4.26596 4.83816 4.08179 4.91564C3.89761 4.99312 3.69981 5.03303 3.5 5.03303C3.30019 5.03303 3.10239 4.99312 2.91821 4.91564C2.73404 4.83816 2.56719 4.72468 2.42747 4.58184C2.28774 4.43901 2.17796 4.2697 2.10455 4.08387C2.03115 3.89803 1.9956 3.6994 2 3.49964C2.00863 3.10753 2.17046 2.7344 2.45083 2.46014C2.73119 2.18588 3.1078 2.03231 3.5 2.03231C3.8922 2.03231 4.26881 2.18588 4.54917 2.46014C4.82954 2.7344 4.99137 3.10753 5 3.49964Z'
        fill='inherit'
      />
    </Icon>
  );
};
