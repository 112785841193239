import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Initial State
type SliceState = {
  unlockFullAccess: {
    isHidden: boolean;
  };
  pleaseLoginModal: {
    isOpen: boolean;
  };
};

const initialState: SliceState = {
  unlockFullAccess: {
    isHidden: false,
  },
  pleaseLoginModal: {
    isOpen: false,
  },
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setHiddenUnlockFullAccessAction(state, action: PayloadAction<boolean>) {
      state.unlockFullAccess = { ...state.unlockFullAccess, isHidden: action.payload };
    },
    openPleaseLoginModalAction(state) {
      state.pleaseLoginModal = { ...state.pleaseLoginModal, isOpen: true };
    },
    closePleaseLoginModalAction(state) {
      state.pleaseLoginModal = { ...state.pleaseLoginModal, isOpen: false };
    },
  },
});

export const { setHiddenUnlockFullAccessAction, openPleaseLoginModalAction, closePleaseLoginModalAction } =
  generalSlice.actions;
export default generalSlice.reducer;
