import { Button, Tooltip } from 'antd';
import { useDisclosure } from 'hooks/use-disclosure';
import { useTranslation } from 'react-i18next';
import { HiOutlinePencil } from 'react-icons/hi';
import { Product } from '../type';
import { EditProductModal } from './edit-product-modal';

interface ProductsActionsCellProps {
  product: Product;
}

export const ProductsActionsCell = ({ product }: ProductsActionsCellProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <div className='flex items-center justify-center'>
        <Tooltip title={t('edit')}>
          <Button size='middle' type='text' icon={<HiOutlinePencil />} onClick={onOpen} />
        </Tooltip>
      </div>

      <EditProductModal isOpen={isOpen} onClose={onClose} product={product} />
    </>
  );
};
