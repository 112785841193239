import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import { useGetOrdersApi } from 'apis/orders/use-get-orders-api';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useAppDispatch } from 'redux/hooks';
import { resetSelectedOrdersAction } from 'redux/orders/orders-list-page-reducer';

const endpoint = 'oms/v1/suppliers/portal/orders/approve';
export const approveOrdersEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type BodyType = {
  ids: number[];
  orderItems?: { id: number; confirmedQuantity: number }[];
};

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

export function useApproveOrdersApi(options?: Options): ReturnType {
  const { refetch: getOrdersApi } = useGetOrdersApi(['restaurant_placed'], { enabled: false });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.patch(endpoint, body);

      // Update orders list
      await getOrdersApi();

      // Reset selected order items from redux state
      dispatch(resetSelectedOrdersAction());

      message.success(t('orders_approved_successfully'));

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
