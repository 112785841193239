export const SpinnerIcon = () => {
  return (
    <svg height='100' width='100' viewBox='0 0 58 100' enableBackground='new 0 0 0 0' fill='#E53E51'>
      <circle fill='inherit' stroke='none' cx='6' cy='50' r='6'>
        <animateTransform
          attributeName='transform'
          dur='1s'
          type='translate'
          values='0 15 ; 0 -15; 0 15'
          repeatCount='indefinite'
          begin='0.1'
        />
      </circle>
      <circle fill='inherit' stroke='none' cx='30' cy='50' r='6'>
        <animateTransform
          attributeName='transform'
          dur='1s'
          type='translate'
          values='0 10 ; 0 -10; 0 10'
          repeatCount='indefinite'
          begin='0.2'
        />
      </circle>
      <circle fill='inherit' stroke='none' cx='54' cy='50' r='6'>
        <animateTransform
          attributeName='transform'
          dur='1s'
          type='translate'
          values='0 5 ; 0 -5; 0 5'
          repeatCount='indefinite'
          begin='0.3'
        />
      </circle>
    </svg>
  );
};
