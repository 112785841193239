type TOrderStatusMap = {
  [key: string]: { slug: string; name: string };
};

export const orderStatusMap: TOrderStatusMap = {
  approved: {
    slug: 'approved',
    name: 'confirmed',
  },
  modified: {
    slug: 'modified',
    name: 'modified-confirmed',
  },
  draft: {
    slug: 'draft',
    name: 'draft',
  },
  canceled: {
    slug: 'canceled',
    name: 'canceled',
  },
  restaurant_placed: {
    slug: 'restaurant_placed',
    name: 'new-order',
  },
  supplier_placed: {
    slug: 'supplier_placed',
    name: 'supplier-placed',
  },
  rejected: {
    slug: 'rejected',
    name: 'rejected',
  },
  received: {
    slug: 'received',
    name: 'received',
  },
  restaurant_placed_approval: {
    slug: 'restaurant_placed_approval',
    name: 'need-approval',
  },
  draft_expire: {
    slug: 'draft_expire',
    name: 'expired-draft',
  },
};

interface IOrderStatusStyleProps {
  [key: string]: {
    color: string;
    bgColor: string;
  };
}

export const orderStatusStyleProps: IOrderStatusStyleProps = {
  approved: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  modified: {
    color: '#51E03E',
    bgColor: 'rgba(81, 224, 62, 0.08)',
  },
  draft: {
    color: '#C2C2C2',
    bgColor: 'rgba(194, 194, 194, 0.08)',
  },
  canceled: {
    color: '#110000',
    bgColor: 'rgba(17, 0, 0, 0.05)',
  },
  restaurant_placed: {
    color: '#160C45',
    bgColor: 'rgba(22, 12, 69, 0.08)',
  },
  supplier_placed: {
    color: '#F0AD4E',
    bgColor: 'rgba(240, 173, 78, 0.08)',
  },
  rejected: {
    color: '#E03E52',
    bgColor: 'rgba(224, 62, 82, 0.08)',
  },
  received: {
    color: '#5BC0DE',
    bgColor: 'rgba(91, 192, 222, 0.08)',
  },
  restaurant_placed_approval: {
    color: '#F0AD4E',
    bgColor: 'rgba(240, 173, 78, 0.08)',
  },
  draft_expire: {
    color: '#C2C2C2',
    bgColor: 'rgba(194, 194, 194, 0.08)',
  },
};

interface OrderStatusChipColorProps {
  [key: string]: {
    color: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'sky';
  };
}

export const orderStatusChipColor: OrderStatusChipColorProps = {
  approved: {
    color: 'success',
  },
  modified: {
    color: 'secondary',
  },
  draft: {
    color: 'default',
  },
  canceled: {
    color: 'danger',
  },
  restaurant_placed: {
    color: 'secondary',
  },
  supplier_placed: {
    color: 'secondary',
  },
  rejected: {
    color: 'danger',
  },
  received: {
    color: 'sky',
  },
  restaurant_placed_approval: {
    color: 'secondary',
  },
  draft_expire: {
    color: 'default',
  },
};
