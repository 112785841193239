import { message } from 'antd';
import http from 'apis/config/http-service';
import { useQueryWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useAuth } from 'hooks/use-auth';
import { TParam } from 'hooks/use-page-params';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { getLimitParam } from 'utils/get-limit-param';
import { getPageParam } from 'utils/get-page-param';

const endpoint = 'ums/v1/supplier/catalog/products';
export const getProductsEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

type TQueryKey = [
  typeof endpoint,
  {
    page: TParam;
    limit: TParam;
  }
];

type Options = Omit<UseQueryOptions<unknown, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
type ReturnType = UseQueryResult<TData, TError>;

function constructEndpoint() {
  return `${endpoint}`;
}

export function useGetProductsApi(options?: Options, params?: Record<string, string>): ReturnType {
  const page = getPageParam();
  const limit = getLimitParam();
  const { isLoggedIn } = useAuth();
  const fullEndpoint = constructEndpoint();

  const queryFn = async () => {
    try {
      const { data } = await http.get(fullEndpoint, {
        params: {
          page,
          limit,
          ...params,
        },
      });

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      // Important to not show the toast in unauthorized case.
      if (error.response?.status === 401) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useQueryWrapper(
    [
      endpoint,
      {
        page,
        limit,
      },
    ],
    queryFn,
    {
      enabled: isLoggedIn,
      ...options,
    }
  );
}
