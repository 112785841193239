import { cn } from '@nextui-org/react';
import type { AvatarProps } from 'antd';
import { Avatar } from 'antd';

interface UserAvatarProps extends AvatarProps {
  name: string;
}

export const UserAvatar = ({ name, className, ...rest }: UserAvatarProps) => {
  return (
    <div className='rounded-full border-2 border-secondary-500 p-[2px]'>
      <Avatar className={cn('bg-secondary-500', className)} {...rest}>
        {name?.slice(0, 1)}
      </Avatar>
    </div>
  );
};
