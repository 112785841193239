import { message } from 'antd';
import http from 'apis/config/http-service';
import { useMutationWrapper } from 'apis/config/use-api-wrapper';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useParams } from 'react-router-dom';

const endpoint = 'oms/v1/suppliers/portal/orders/:id/invoice-images';
export const rejectOrderEndpointIdentifier = endpoint;

type TData = Record<string, any>;
type TError = AxiosError;

interface BodyType {
  invoiceImages: string[];
}

type TVariables = {
  body: BodyType;
};

type Options = Omit<UseMutationOptions<TData, TError, TVariables, unknown>, 'mutationFn'>;
type ReturnType = UseMutationResult<TData, TError, TVariables>;

function constructEndpoint(orderId: any) {
  return endpoint.replace(':id', orderId.toString());
}

export function useSetOrderInvoiceApi(options?: Options): ReturnType {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();

  const mutationFn = async ({ body }: TVariables) => {
    try {
      const { data } = await http.patch(constructEndpoint(orderId), body);

      message.success(t('order_invoices_updated'));

      return data;
    } catch (error: any) {
      if (!error.response.status || !error.response.data.message) throw error;

      message.error(error.response.data.message);

      throw error;
    }
  };

  return useMutationWrapper(mutationFn, { mutationKey: endpoint, ...options });
}
