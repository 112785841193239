import { Button, HStack, Text } from '@elkaso-app/web-design';
import { OnboardingForm } from 'components/onboarding-form';
import { Layout, Section } from 'layout';
import { Link, useNavigate } from 'react-router-dom';
import { URL } from 'utils/constants';

const RequestReceivedPage = () => {
  const navigate = useNavigate();

  return (
    <Layout type='auth_page_layout'>
      <Section name='content'>
        <OnboardingForm>
          <OnboardingForm.Title>WE&apos;RE ON IT!</OnboardingForm.Title>

          <OnboardingForm.Subtitle>
            {`Looks like you're ready to take your operations up a notch! Our team will be in touch with you soon.`}
          </OnboardingForm.Subtitle>

          <Button colorScheme='red' w='full' mt='xl' onClick={() => navigate(URL.HOME)}>
            Back to KASO Homepage
          </Button>

          <HStack spacing='xs' mt='xl' justify='center' textTransform='uppercase' fontWeight='bold'>
            <Text color='blue.500'>Already have an Account?</Text>

            <Link to={URL.LOGIN}>
              <Text color='red.500'>Login here</Text>
            </Link>
          </HStack>
        </OnboardingForm>
      </Section>
    </Layout>
  );
};

export default RequestReceivedPage;
