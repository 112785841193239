import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Form, message } from 'antd';
import http from 'apis/config/http-service';
import { useGetBranchApi } from 'apis/orders/use-get-branch-api';
import _debounce from 'lodash/debounce';
import { OrdersFilterSectionProps } from 'pages/orders/components/orders-filter-modal';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TOption = {
  key: string;
  value: string;
  type: string;
  language: string;
};

type FormInputs = {
  branch: TOption | undefined;
};

const defaultValues: FormInputs = {
  branch: undefined,
};

export const BranchNameFilter = ({ filter, setFilter }: OrdersFilterSectionProps) => {
  const { data: branchApiData } = useGetBranchApi();

  const { data } = branchApiData ?? {};
  const { t } = useTranslation();

  const { control, setValue, watch } = useForm<FormInputs>({
    defaultValues,
  });

  const updateValues = () => {
    if (data?.length !== 1) return;

    return setValue('branch', {
      key: data[0]?.id,
      value: data[0]?.name,
      type: data[0]?.type,
      language: data[0]?.language,
    });
  };

  useEffect(() => {
    updateValues();
  }, [data]);

  const branch = watch('branch');

  const updateFilter = (subEntityId: string, subEntityType: string, subEntityLang: string) => {
    setFilter((prev) => {
      return {
        ...prev,
        subEntityId,
        subEntityType,
        subEntityLang,
      };
    });
  };

  useEffect(() => {
    if (!branch?.key || !branch?.type || !branch?.language) {
      return updateFilter('', '', '');
    }

    updateFilter(branch?.key, branch?.type, branch?.language);
  }, [branch]);

  const [options, setOptions] = useState<TOption[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (searchText: string) => {
    setLoading(true);
    try {
      const { data } = await http.get('ums/v1/suppliers/restaurants/locations', {
        params: { name: searchText, restaurantId: filter?.restaurantId, page: 1, limit: 100 },
      });

      const values: TOption[] = data?.data?.map((item: any) => {
        return { key: item?.id, value: item?.name, type: item?.type, language: item?.language };
      });

      setOptions(values);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(_debounce(fetchData, 500), [filter]);

  const handleSearch = (value: string) => {
    if (value) {
      debouncedFetchData(value);
    } else {
      setOptions([]);
    }
  };

  return (
    <Controller
      control={control}
      name='branch'
      render={({ field: { onChange, name, value } }) => (
        <Form>
          <Form.Item name={name} valuePropName={value?.value}>
            <AutoComplete
              value={value}
              options={options}
              onSearch={handleSearch}
              onChange={(_, option) => onChange(option)}
              placeholder={t('type-name-here')}
              notFoundContent={loading ? 'Loading...' : 'No results found'}
              suffixIcon={<SearchOutlined style={{ fontSize: 15 }} />}
              disabled={!filter?.restaurantId || !filter?.restaurantLang}
              style={{ width: '100%' }}
              size='large'
            />
          </Form.Item>
        </Form>
      )}
    />
  );
};
